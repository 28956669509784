<template>
    <div class="q-answer">
        <ul v-if="answerObj">
            <li v-for="answer in answerOptions" :key="answer.option.id">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" v-model="selectOption" :value="answer"> {{answer.option.option}}
                </label>
            </li>
        </ul>
    </div>
</template>

<script>


    export default {
        props: ['answer', 'dependencyAnswer'],
        emits: ['changeValue'],

        data() {
            return {
                answerOptions: [],
                selectOption: null,
                answerObj: null,
                createdData: false
            }
        },


        components: {

        },
        created() {
            this.answerObj = this.answer;
            this.updateAnswerOptions();

        },



        methods: {


            changeSelectedValue()   {
                this.answerObj.options = [this.selectOption];
            },
            updateAnswerOptions() {
                this.answerOptions = this.answerObj.question.options
                    .filter(option => {
                        if (this.dependencyAnswer && option.dependentOption) {
                            return this.dependencyAnswer.options.find(ao => ao.option.id == option.dependentOption.id) != null;
                        }
                        return true;
                    })
                    .map(option => {
                        return {
                            option: option,
                            extendedDescription: null,
                            link: null
                        }
                    });
                if (this.answerObj.options != null && this.answerObj.options.length > 0) {
                    this.selectOption = this.answerOptions.find(a => a.option.id == this.answerObj.options[0].option.id)
                    this.selectOption.id = this.answerObj.options[0].id;

                }
            },
        },

        watch: {
            selectOption: {
                handler() {

                    this.changeSelectedValue();
                },
                deep: true
            },
            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            },
            dependencyAnswer: {
                handler() {
                    this.updateAnswerOptions();
                },
                deep: true
            }
        }
    }
</script>