<template>
    <pv-dialog
            header="Przypisz hostessy"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        >
        </ml-form>
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                plans: [],

            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                if (data.hostess.length > 0) {
                    let request = {
                        planTermIdList: this.plans.map(p => p.id),
                        userIdList: data.hostess.map(h => h.user.id)
                    }
                    await this.$store.dispatch('sv/assignGroup', request);

                }

                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
                this.plans = newValue;
                this.formData = {hostess: []};

                let users =  await this.$store.dispatch('user/findUsers');
                users.forEach(p => p.fullname = p.firstName + " " + p.lastName);

                let formFields = [

                    {type: 'EDIT_TABLE', name: 'hostess', label: 'Hostessy', maxRows: 5,
                        columns: [
                            {name: 'user', header: 'Hostessa', type: 'dropdown', style: 'width: auto', optionLabel: 'fullname', options: users},
                        ],
                        addLabel: 'Dodaj hostessę',
                        addMethod: () => { return {user: null}},
                        filterMethod: () => {return true;},
                    },
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            },

            convertDateToString(dateStr) {
                let date = new Date(dateStr);

                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }) + " " + date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            }


        }
    }
</script>