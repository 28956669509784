/* eslint-disable */


export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        findShops({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/query', data: query});
        },

        async findShop({}, id) {
            let query = {pageNo: 0, pageSize: 1, sortName: "id", sortDirection: "ASC", filter: {id: id}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/query', data: query});
            if (response.content.length > 0) {
                return response.content[0];
            }
            return null;
        },

        saveShop({}, data) {
            return this.dispatch('api/post', {path: '/api/shop', data: data});
        },


        deleteShop({}, id) {
            return this.dispatch('api/delete', {path: '/api/shop/' + id});
        },


        findShopActions({}, params) {
            return this.dispatch('api/post', {path: '/api/shop/action/bind/query/' + params.actionId, data: params.query});
        },


        findTraders({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/trader/query', data: query});
        },

        async findTrader({}, id) {
            let query = {pageNo: 0, pageSize: 1, sortName: "id", sortDirection: "ASC", filter: {id: id}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/trader/query', data: query});
            if (response.content.length > 0) {
                return response.content[0];
            }
            return null;
        },

        saveTrader({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/trader', data: data});
        },

        deleteTrader({}, id) {
            return this.dispatch('api/delete', {path: '/api/shop/trader/' + id});
        },


        findActions({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/action/query', data: query});
        },

        async findAction({}, id) {
            let query = {pageNo: 0, pageSize: 1, sortName: "id", sortDirection: "ASC", filter: {id: id}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/action/query', data: query});
            if (response.content.length > 0) {
                return response.content[0];
            }
            return null;
        },

        saveAction({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/action', data: data});
        },

        copyAction({}, actionId) {
            return this.dispatch('api/post', {path: '/api/shop/action/copy', data: actionId});
        },

        createPollForm({}, actionId) {
            return this.dispatch('api/get', {path: '/api/polls/form/action/create/' + actionId});
        },

        deleteAction({}, id) {
            return this.dispatch('api/delete', {path: '/api/shop/action/' + id});
        },

        async findActionsByName({}, name) {
            let query = {pageNo: 0, pageSize: 30, sortName: "id", sortDirection: "ASC", filter: {name: name}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/action/query', data: query});
            return response.content;
        },



        findPromotions({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/promotion/query', data: query});
        },

        async findPromotion({}, id) {
            let query = {pageNo: 0, pageSize: 1, sortName: "id", sortDirection: "ASC", filter: {id: id}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/promotion/query', data: query});
            if (response.content.length > 0) {
                return response.content[0];
            }
            return null;
        },

        savePromotion({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/promotion', data: data});
        },

        deletePromotion({}, id) {
            return this.dispatch('api/delete', {path: '/api/shop/promotion/' + id});
        },


        async findPromotionsByName({}, name) {
            let query = {pageNo: 0, pageSize: 30, sortName: "id", sortDirection: "ASC", filter: {name: name}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/promotion/query', data: query});
            return response.content;
        },




        findGifts({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/gift/query', data: query});
        },

        async findGift({}, id) {
            let query = {pageNo: 0, pageSize: 1, sortName: "id", sortDirection: "ASC", filter: {id: id}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/gift/query', data: query});
            if (response.content.length > 0) {
                return response.content[0];
            }
            return null;
        },

        saveGift({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/gift/', data: data});
        },


        deleteGift({}, id) {
            return this.dispatch('api/delete', {path: '/api/shop/gift/' + id});
        },


        async findGiftByName({}, name) {
            let query = {pageNo: 0, pageSize: 30, sortName: "id", sortDirection: "ASC", filter: {name: name}};
            let response =  await this.dispatch('api/post', {path: '/api/shop/gift/query', data: query});
            return response.content;
        },


        findPolls({}, query) {
            return this.dispatch('api/post', {path: '/api/polls/query', data: query});
        },

        async findPollsByName({}, name) {
            let query = {pageNo: 0, pageSize: 30, sortName: "id", sortDirection: "ASC", filter: {name: name}};
            let response =  await this.dispatch('api/post', {path: '/api/polls/query', data: query});
            return response.content;
        },


        findPoll({}, id) {
            return this.dispatch('api/get', {path: '/api/polls/' + id});
        },

        savePoll({}, data) {
            return this.dispatch('api/post', {path: '/api/polls', data: data});
        },

        deletePoll({}, id) {
            return this.dispatch('api/delete', {path: '/api/polls/' + id});
        },

        assignShops({}, params) {
            this.dispatch('api/post', {path: '/api/harmonogram/plan-shop/assign/' + params.actionId, data: params.data});
        },

        async assignAllShops({}, params) {
            await this.dispatch('api/post', {path: '/api/harmonogram/plan-shop/assignall/' + params.actionId, data: params.query});
        },

        deletePlan({}, id) {
            return this.dispatch('api/delete', {path: '/api/harmonogram/plan-shop/' + id});
        },

        findShopSv({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/sv/query', data: query});
        },

        assignShopSv({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/sv/save', data: data});
        },


        findComments({}, id) {
            return this.dispatch('api/get', {path: '/api/harmonogram/plan-shop/comments/' + id});
        },


        findCodes({}, query) {
            return this.dispatch('api/post', {path: '/api/shop/actioncode/query', data: query});
        },

        saveCode({}, data) {
            return this.dispatch('api/post', {path: '/api/shop/actioncode', data: data});
        },
    }
}