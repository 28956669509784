<template>
    <pv-dialog
            header="Termin akcji"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        >
            <template v-slot:beforeInputs>
                <div class="position-relative row mb-1">
                    <label class="form-label col-sm-2 col-form-label">Akcja</label>
                    <label class="form-label col-sm-10 col-form-label">{{formData.planShop.action.name}}</label>
                </div>
                <div class="position-relative row mb-1">
                    <label class="form-label col-sm-2 col-form-label">Sklep</label>
                    <label class="form-label col-sm-10 col-form-label">{{formData.planShop.shop.shortName}}, {{formData.planShop.shop.city}}, {{formData.planShop.shop.street}}, {{formData.planShop.shop.houseNumber}}</label>
                </div>
            </template>

        </ml-form>
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                targets: [],
                calculate: false
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                this.calculate = false;
                await this.rebuildForm(newValue);
                this.calculate = true;
            }
        },

        methods: {
            changeValue(value) {
                if (this.calculate && value.name == 'dateFrom') {
                    this.formData.dateTo =  new Date(value.value.getTime() + 14400000);
                }
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                let startTimeString = data.dateFrom.toLocaleTimeString('pl-Pl');
                let endTimeString = data.dateTo.toLocaleTimeString('pl-Pl');
                let timezoneOffset = data.date.getTimezoneOffset() * 60000;
                let selectDate = new Date(Date.parse(new Date(data.date.getTime() - timezoneOffset).toISOString().slice(0,10)));
                data.dateFrom =  new Date(Date.parse(selectDate.toISOString().slice(0,10) + "T" + startTimeString)).toISOString();
                data.dateTo = new Date(Date.parse(selectDate.toISOString().slice(0,10) + "T" + endTimeString)).toISOString();

                await this.$store.dispatch('sv/saveTerm', data);
                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };

                newValue.dateFrom = new Date(newValue.dateFrom);
                newValue.dateTo = new Date(newValue.dateTo);
                newValue.date = new Date(newValue.dateFrom);
                this.formData = newValue;

                let baPlans =  await this.$store.dispatch('sv/findBaTargets', newValue.planShop.planSv.id);
                baPlans.forEach(p => p.fullname = p.user.firstName + " " + p.user.lastName);
                this.formData.hostess.forEach(h => h.planBa.fullname = h.planBa.user.firstName + " " + h.planBa.user.lastName);

                let formFields = [
                    {type: 'CALENDAR', name: 'date', label: 'Termin', tooltip: '', info: '',
                        minDate: new Date(Date.parse(newValue.planShop.action.dateFrom)),
                        maxDate: new Date(Date.parse(newValue.planShop.action.dateTo)),
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'CALENDAR', name: 'dateFrom', label: 'Od godziny', tooltip: '', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane', timeOnly: true},
                    {type: 'CALENDAR', name: 'dateTo', label: 'Do godziny', tooltip: '', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',  timeOnly: true},
                    {type: 'EDIT_TABLE', name: 'hostess', label: 'Hostessy', maxRows: 10,
                        columns: [
                            {name: 'planBa', header: 'Hostessa', type: 'dropdown', style: 'width: auto', optionLabel: 'fullname', options: baPlans},
                        ],
                        addLabel: 'Dodaj hostessę',
                        addMethod: () => { return {id: null, planBa: null, status: 'PLANNED', deleted: false}},
                        filterMethod: (item) => !item.deleted,
                        removeMethod: (item) => { if(item.id) { item.deleted = true; return true} return null;}
                    },
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            },

            convertDateToString(dateStr) {
                let date = new Date(dateStr);

                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            }
        }
    }
</script>