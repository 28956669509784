<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <div class="col-sm-2">
            <label class="form-label col-form-label me-2">{{formInput.label}}</label>
        </div>
        <div class="col-sm-10">
            <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-sm p-button-primary" @click="add()"  />
        </div>
        <pv-datatable class="col-sm-12 p-datatable-sm" :value="value" responsiveLayout="scroll">
            <pv-column v-for="col in formInput.columns" :field="col.field" :header="col.header" :key="col.field"></pv-column>

            <pv-column v-if="!formInput.disabled" style="width: 120px">
                <template #body="{data}">
                    <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="editItem(data)" />
                    <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                </template>
            </pv-column>
        </pv-datatable>

        <div class="col-sm-2"></div>
        <div class="col-sm-10"><small class="form-text text-muted" v-if="formInput.info">{{formInput.info}}</small></div>

    </div>
</template>


<script>

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-button': Button,
            'pv-datatable': DataTable,
            'pv-column': Column,
        },

        methods: {
            add() {
                this.formInput.addMethod();
            },

            filterValues(values) {
                if (this.formInput.filterMethod !== undefined && typeof this.formInput.filterMethod === "function" ) {
                    return this.formInput.filterMethod(values);
                }
                return values;
            },

            editItem(item) {
                this.formInput.editMethod(item);
            },

            deleteItem(item) {
                this.formInput.removeMethod(item);
            }


        },
        data() {
            return {
                value: []
            }
        },
        created() {
            this.value = this.filterValues(this.formInput.value);
        },
        watch: {
            formInput: {
                handler() {
                    this.value = this.filterValues(this.formInput.value);
                },
                deep: true
            }
        }

    }
</script>