<template>
    <div class="q-answer">
        <pv-rating v-model="answerObj.value" :cancel="false" :stars="answerObj.question.maxValue"/>
        <p class="mt-2">Ocena: {{answerObj.value}}</p>
    </div>
</template>

<script>

    import Rating from 'primevue/rating';

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false
            }
        },


        components: {
            'pv-rating': Rating
        },
        created() {
            this.answerObj = this.answer;
        },

        watch: {

            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>