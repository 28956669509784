<template>
    <pv-dialog
            header="Przypisz supervisora"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>


<script>
    import {mapState} from 'vuex';
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        computed: {
            ...mapState({
                users: state => state.user.users
            }),
        },

        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                targets: [],
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                if (data.user) {
                    var request = data.shops.map(s => {
                        return {shop: s, user: data.user};
                    });
                    await this.$store.dispatch('shop/assignShopSv', request);
                }
                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                await this.$store.dispatch('user/findUsers');
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
                this.formData = {shops: newValue, user: null };

                let users = this.users.map(u => u);
                users.forEach(u => u.fullname = u.firstName + " " + u.lastName);
                let formFields = [
                    {type: 'DROPDOWN', name: 'user', label: 'Sypervisor',
                        tooltip: '', info: '', options: this.users, optionLabel: 'fullname'},
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            },


        }
    }
</script>