<template>
    <pv-dialog
            header="Pytanie o email"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '60vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue', 'dependentQuestions'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                dependentOptions: [],
                formStruct: {fields: []},
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
                this.dependentOptions = this.formData.dependentQuestion  && this.formData.dependentQuestion.id ? this.formData.dependentQuestion.options : [];
                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .options = this.dependentOptions;
                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .visibility = this.dependentOptions.length > 0;
            },

            sendValue(data) {
                this.$emit('saveForm', {...data});
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };


                let formFields = [
                    {type: 'TEXT', name: 'title', label: 'Pytanie', placeholder: 'Wpisz pytanie do ankiety',
                        tooltip: 'Wpisz pytanie', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 100, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                    {type: 'TEXTAREA', name: 'description', label: 'Opis',
                        tooltip: 'Wpisz dodatkowe informacje do pytania', info: '',
                        maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},
                    {type: 'CHECKBOX', name: 'required', label: 'Obligatoryjność',
                        tooltip: 'Czy pole jest wymagane', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                    },
                    {type: 'DROPDOWN', name: 'dependentQuestion', label: 'Pytanie zależne od',
                        visibility: this.dependentQuestions.length > 0,
                        tooltip: '', info: '', options: this.dependentQuestions, optionLabel: 'title'},
                    {type: 'MULTISELECT', name: 'dependentOptions', label: 'Opcje zależne',
                        visibility: this.dependentOptions.length > 0,
                        tooltip: '', info: '', options: this.dependentOptions, optionLabel: 'option'},
                    {type: 'NUMBER', name: 'maxLength', label: 'Maksymalna ilość znaków', placeholder: 'Wpisz liczbę znaków',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        tooltip: 'Wpisz liczbę znaków', info: '', locale: 'pl-PL', maxFractionDigits: 0,
                        customValidate: (input) => input.value <= 1000,
                        customMessage: 'Liczba nie może być wieksza niż 1000'}
                ];






                this.formData = newValue;
                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>