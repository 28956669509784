<template>
    <div class="q-answer">
        <pv-textarea
                class="form-control"
                style="max-width: 400px;"
                :autoResize="true"
                rows="5"
                cols="30"
                v-model="answerObj.description"/>
    </div>
</template>

<script>

    import TextArea from 'primevue/textarea';

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false
            }
        },


        components: {
            'pv-textarea': TextArea,
        },
        created() {
            this.answerObj = this.answer;
        },

        watch: {

            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>