<template>
    <pv-dialog
            header="Mechanizm do akcji"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '60vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue', 'visibleGift'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },

            sendValue(data) {
                this.$emit('saveForm', {...data});
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };

                let resp =  await this.$store.dispatch('shop/findGifts', {filter: {},pageNo: 0, pageSize: 300, sortDirection:  'ASC', sortName: 'name'});
                let gifts = resp.content;

                let formFields = [
                    {type: 'AUTOCOMPLETE', name: 'promotion', label: 'Mechanizm',
                        tooltip: 'Wpisz mechanizm', info: 'Wpisz min 3 znaki mechanizmu',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        delay: 300, minLength: 3, field: 'name', autocomplete: 'shop/findPromotionsByName', forceSelection: true}
                ];

                if (this.visibleGift) {
                    formFields.push(
                        {type: 'EDIT_TABLE', name: 'actionGifts', label: 'Opcje odpowiedzi', maxRows: 2,
                            columns: [
                                {name: 'gift', header: 'Gadżet', type: 'dropdown', style: 'width: auto', optionLabel: 'name', options: gifts},
                                {name: 'assigned', header: 'Liczba do wydania', style: 'width: auto',
                                    type: 'number', locale: 'pl-PL', maxFractionDigits: 0, minFractionDigits: 0},
                            ],
                            addLabel: 'Dodaj gadzet',
                            addMethod: () => { return {id: null, gift: null, assigned: 0}},
                            filterMethod: () => {return true;},
                        },
                    )
                }







                this.formData = newValue;
                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>