<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label class="form-label col-sm-2 col-form-label" :for="formInput.name">{{formInput.label}}</label>
        <div class="col-sm-10">
            <pv-multiselect
                    :id="formInput.name"
                    class="form-control"
                    :class="{'is-invalid': formInput.error, 'is-valid': formInput.validated}"
                    :disabled="formInput.disabled"
                    :placeholder="formInput.placeholder"
                    v-model="value"
                    :options="formInput.options"
                    :optionLabel="formInput.optionLabel"
                    :optionValue="formInput.optionValue"
                    display="chip"
            />
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>

<script>

    import MultiSelect from 'primevue/multiselect';
    import Tooltip from 'primevue/tooltip';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-multiselect': MultiSelect,
        },
        directives: {
            'tooltip': Tooltip
        },
        data() {
            return {
                value: null
            }
        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                },
                deep: true
            }

        }
    }
</script>