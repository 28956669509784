<template>
    <div>
        <p>{{question.number}}. {{question.title}}</p>
        <ul>
            <li v-for="(item, index) in filterOptions()" :key="index">
                <input type="radio" disabled/> {{item.promotion.promotion.name}}
            </li>
        </ul>
        <p v-if="question.dependentQuestion" class="question-info">
            Pytanie zależne od pytania: {{question.dependentQuestion.number}}. {{question.dependentQuestion.title}}
        </p>
    </div>
</template>


<script>

    export default {
        props: ['question'],
        emits: [],
        components: {},

        methods: {
            filterOptions() {
                return this.question.options.filter(o => !o.deleted);
            }
        }
    }
</script>