<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">


            <pv-table
                    ref="dt"
                    :value="shops"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >

                <pv-column field="id" header="Id" style="width: 70px">
                </pv-column>

                <pv-column field="nip" header="NIP" style="width: 140px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="name" header="Nazwa" style="width: 200px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="street" header="Ulica" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="postcode" header="Kod pocztowy" style="width: 90px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="city" header="Miasto" style="width: 150px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-search" class="p-button-rounded p-button-primary me-2" @click="show(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Lista Sklepów....ppp"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
        },




        data() {
            return {
                loading: false,
                shops: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'nip': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'street': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'postcode': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'city': {value: '', matchMode: FilterMatchMode.CONTAINS},

                },
                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {


            show(item) {
                this.$router.replace({name: 'shopEdit', params: {id: item.id}});
            },



            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        nip: this.lazyParams.filters.nip.value,
                        name: this.lazyParams.filters.name.value,
                        street: this.lazyParams.filters.street.value,
                        postcode: this.lazyParams.filters.postcode.value,
                        city: this.lazyParams.filters.city.value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder < 0 ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'id' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('shop/findShops', query);
                this.totalRecords = response.totalElements;
                this.shops = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },


        },

        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>