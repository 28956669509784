<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card app-form">
        <div class="card-body">
            <div class="position-relative row mb-3">
                <label class="form-label col-md-2 col-form-label">Wybierz dzień</label>
                <div class="col-md-10">
                    <pv-calendar class="form-control"  v-model="selectDay" dateFormat="dd.mm.yy" />
                </div>
            </div>
            <h5>Lista Akcji</h5>
            <ul class="list-group action-list">
                <li class="list-group-item" v-for="term in events" :key="term.id">
                    <div class="row">
                        <div class="col-sm-12 col-lg-8">
                            <h5 class="list-group-item-heading mb-0">{{term.planShop.action.name}} <span class="status">{{getStatusName(term)}}</span></h5>
                            <p class="list-group-item-text time mb-0">{{convertTimeToString(term.dateFrom)}} - {{convertTimeToString(term.dateTo)}}</p>
                            <p class="list-group-item-text mb-0">{{term.planShop.shop.shortName}}</p>
                            <p class="list-group-item-text mb-0">{{term.planShop.shop.city}}, {{term.planShop.shop.street}} {{term.planShop.shop.houseNumber}}</p>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="list-buttons">
                                <button v-if="getStatus(term) === 'PLANNED'" @click="start(term)" class="mb-2 me-2 btn btn-primary btn-lg btn-icon"><i class="fa fa-flag btn-icon-wrapper"></i>Rozpoczęcie pracy</button>
                                <button v-if="getStatus(term) === 'WORKING'" @click="comment(term)" class="mb-2 me-2 btn btn-primary btn-lg btn-icon"><i class="pe-7s-comment btn-icon-wrapper"></i>Dodaj komentarz</button>
                                <button v-if="getStatus(term) === 'WORKING'" :disabled="isStockEmpty(term)" @click="poll(term)" class="mb-2 me-2 btn btn-primary btn-lg btn-icon"><i class="pe-7s-note2 btn-icon-wrapper"></i>Ankieta</button>
                                <button v-if="getStatus(term) === 'WORKING'" @click="end(term)"  class="mb-2 me-2 btn btn-primary btn-lg btn-icon"><i class="fa fa-flag-checkered btn-icon-wrapper"></i>Zakończenie pracy</button>
                            </div>
                        </div>
                        <p v-if="getStatus(term) === 'WORKING' && isStockEmpty(term)"  class="list-group-item-text mb-0 text-center" style="color: #880000">Brak zapasu produktu promocyjnego, skontaktuj się ze swoim supervisorem</p>
                    </div>
                </li>
            </ul>

        </div>
        <StartEvent :visibleDialog="visibleDialog" :dataValue="dialogItem" @closeForm="visibleDialog = false" @saveForm="refreshList()" />
        <CommentForm :visibleDialog="visibleComment" :dataValue="commentForm" @closeForm="visibleComment = false" @saveForm="refreshList()" />
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Calendar from 'primevue/calendar';
    import ConfirmDialog from 'primevue/confirmdialog';
    import StartEvent from './dialog/StartEvent.vue'
    import CommentForm from './dialog/CommentForm.vue'

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Prezentacje", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Dashboard"}
            ];
            return {
                breadcrumpsList,
            }
        },


        components: {
            Breadcrumbs,
            'pv-calendar': Calendar,
            'pv-confirm-dialog': ConfirmDialog,
            StartEvent,
            CommentForm
        },

        computed: {
            ...mapState({
                userData: state => state.auth.userData
            }),
        },


        data() {
            return {
                visibleDialog: false,
                visibleComment: false,
                dialogItem: null,
                commentForm: null,
                selectDay: new Date(),
                events: []
            }
        },

        watch: {
            async selectDay() {
                this.refreshList();
            }
        },

        methods: {
            async refreshList() {
                let query = {
                    startActive: this.selectDay,
                    endActive: new Date(this.selectDay.getTime() + 86400000)
                };
                let events = await this.$store.dispatch('sv/findTermsByShop', query);
                this.events = events.filter(t => t.hostess.find(h => h.planBa.user.email === this.userData.email));
            },


            findBaTerm(event) {
                return event.hostess.find(h => h.planBa.user.email === this.userData.email)
            },

            getStatus(event) {
                return this.findBaTerm(event).status;
            },

            isStockEmpty(event) {
                return this.findBaTerm(event).stock == null || this.findBaTerm(event).stock == 0;
            },

            getStatusName(event) {
                let status = this.findBaTerm(event).status;
                if (status == 'PLANNED') {
                    return "planowana";
                }
                if (status == 'WORKING') {
                    return "w trakcie";
                }
                return "zakończona";
            },

            async start(term) {
                this.$confirm.require({
                    message: 'Czy chcesz rozpocząć pracę?',
                    header: 'Rozpoczęcie pracy',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.dialogItem = {planId: term.id, baTerm: this.findBaTerm(term)};
                        this.visibleDialog = true;
                    }
                });
            },

            async comment(term) {
                this.commentForm = {planShop: null, planBaTerm: this.findBaTerm(term), comment: null};
                this.visibleComment = true;
            },



            async end(term) {
                this.$confirm.require({
                    message: 'Czy chcesz zakończyć pracę?',
                    header: 'Zakończenie pracy',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => this.endEvent(term)
                });
            },

            async endEvent(term) {
                if (navigator.geolocation) {
                    let geoSettings = {
                        enableHighAccuracy: true,
                        maximumAge: 10000,
                        timeout: 5000
                    };
                    navigator.geolocation.getCurrentPosition(
                        (position) => this.endEventLocal(position, term),
                        (error) => {alert(error.code + ": " + error.message); this.endEventLocal(null, term);},
                        geoSettings);
                } else {
                    this.$confirm.require({
                        message: 'Twoja przeglądarka nie obsługuje geolokalizacji! Czy chcesz kontynuować',
                        header: 'Zakończenie pracy',
                        icon: 'pi pi-info-circle',
                        acceptClass: 'p-button-danger',
                        accept: () => this.endEventLocal(null, term)
                    });
                }
            },


            async endEventLocal(position, term) {
                let baTerm = this.findBaTerm(term);
                baTerm.endWork = new Date();
                baTerm.endLat = position ? position.coords.latitude : null;
                baTerm.endLng = position ? position.coords.longitude : null;
                baTerm.status = "CLOSED";
                await this.$store.dispatch('ba/saveBaTerm',{planId: term.id, data: baTerm});
                this.refreshList();
            },

            poll(term) {
                let baTerm = this.findBaTerm(term);
                this.$router.replace({name: 'pollStart', params: {id: baTerm.id}});
            },

            convertTimeToString(dateStr) {
                let date = new Date(dateStr);
                return date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            },

        },


        async beforeMount() {
            let now = new Date();
            this.selectDay =  new Date(now.getFullYear(), now.getMonth(), now.getDate());

        }
    }
</script>