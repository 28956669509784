<template>
    <pv-dialog
            header="Sprzedaż"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        >
        </ml-form>
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                this.calculate = false;
                await this.rebuildForm(newValue);
                this.calculate = true;
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                await this.$store.dispatch('sv/saveTermSold', data);
                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };

                this.formData = newValue;

                let formFields = [
                    {type: 'NUMBER', name: 'sold', label: 'Sprzedaż', placeholder: 'Sprzedaż',
                        tooltip: 'Wpisz sprzedaż', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'soldWithoutPromotion', label: 'Sprzedaż bez mechanizmu', placeholder: 'Sprzedaż',
                        tooltip: 'Wpisz sprzedaż bez mechanizmu', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'contact', label: 'Liczba kontaktów', placeholder: 'Liczba kontaktów',
                        tooltip: 'Wpisz liczbę kontaktów', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'stick', label: 'Liczba wydanych sticków', placeholder: 'Liczba wydanych sticków',
                        tooltip: 'Wpisz liczbę wydanych sticków', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false }
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            },

        }
    }
</script>