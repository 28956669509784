<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <div class="col-sm-12">
            <hr>
        </div>
    </div>
</template>


<script>




    export default {
        props: ['formInput'],
        emits: ['changeValue']
    }
</script>