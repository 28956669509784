<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Gadżety", routerName: 'giftList', params: {}, query: {}},
                {label: "Edycja gadżetu"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },



        data() {
            return {
                formData: {
                    id: null,
                    name: "",
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'name', label: 'Nazwa prezentu', placeholder: 'Wpisz nazwę prezentu',
                            tooltip: 'Wpisz nazwę prezentu', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 150, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},

                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {



            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('shop/saveGift', data);
                this.$router.replace({name: 'giftList'});

            },
            cancelForm() {
                this.$router.replace({name: 'giftList'});
            }

        },

        async beforeMount() {
            let fData = {
                id: null,
                name: "",
            };
            if (this.$route.params.id > 0) {
                fData = await this.$store.dispatch('shop/findGift', this.$route.params.id);
            }
            this.formData = fData;
        }
    }

</script>