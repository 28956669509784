<template>
    <div class="q-answer">
        <pv-inputtext
                class="form-control"
                style="max-width: 400px;"
                v-model="answerObj.description"/>
    </div>
</template>

<script>

    import InputText from 'primevue/inputtext';

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false
            }
        },


        components: {
            'pv-inputtext': InputText,
        },

        created() {
            this.answerObj = this.answer;
        },

        watch: {

            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>