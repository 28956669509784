/* eslint-disable */
import http from 'superagent'

export default {
    namespaced: true,

    state: {
        ticketHeader: process.env.VUE_APP_API_HEADER_TOKEN,
        host: process.env.VUE_APP_API_HOST
    },
    mutations: {

    },

    actions: {


        findSvTargets({}, id) {
            return this.dispatch('api/get', {path: '/api/target/plan-svs/' + id});
        },

        findSvTarget({}, id) {
            return this.dispatch('api/get', {path: '/api/target/plan-svs/plan/' + id});
        },

        saveSvTarget({}, data) {
            return this.dispatch('api/post', {path: '/api/target/plan-svs', data: data});
        },

        async summaryTarget({}, id) {
            let plans =  await this.dispatch('api/get', {path: '/api/target/plan-svs/' + id});
            let action =  await this.dispatch('shop/findAction', id);
            let result = {
                plan: {contactTarget: action.contactTarget, soldTarget: action.soldTarget, secondTarget:
                action.secondTarget, thirdTarget: action.thirdTarget, promotionDays: action.promotionDays},
                distribute: {contactTarget: 0, soldTarget: 0, secondTarget: 0, thirdTarget: 0, promotionDays: 0},
                gifts: [],
            };
            action.promotions.forEach(p => {
                p.actionGifts.forEach(g => {
                    let gift = result.gifts.find(rg => rg.gift.id == g.gift.id);
                    if(gift) {
                        gift.plan += g.assigned;
                    } else {
                        result.gifts.push({gift: g.gift, plan: g.assigned, distribute: 0});
                    }


                })
            });
            plans.forEach(p => {
                result.distribute.contactTarget += p.contactTarget;
                result.distribute.soldTarget += p.soldTarget;
                result.distribute.secondTarget += p.secondTarget;
                result.distribute.thirdTarget += p.thirdTarget;
                result.distribute.promotionDays += p.promotionDays;
                p.planGifts.forEach(g => {
                    let gift = result.gifts.find(rg => rg.gift.id == g.gift.id);
                    if (gift) {
                        gift.distribute += g.assigned;
                    }
                })

            });

            return result;

        },


        findPlans({}, query) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-shop/query', data: query});
        },

        findPlan({}, id) {
            return this.dispatch('api/get', {path: '/api/harmonogram/plan-shop/' + id});
        },


        savePlan({}, data) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-shop', data: data});
        },


        async exportTerms({state}, query) {
            let resource = state.host + '/api/harmonogram/plan-term/export';

            return new Promise(async (resolve, reject) => {
                let token = await this.dispatch("api/getTicket");
                if (token) {
                    http
                        .post(resource)
                        .set('Content-Type', 'application/json')
                        .set(state.ticketHeader, 'Bearer ' + token)
                        .send(query)
                        .responseType('blob')
                        .end((err, res) => {
                            if (err) {
                                err.response = res;
                                reject(err)
                            } else {
                                resolve(res);
                            }
                        })
                } else {
                    reject(null);
                }
            });
        },

        async importTerms({state}, formData) {
            let resource = state.host + '/api/harmonogram/plan-term/import';
            let token = await this.dispatch("api/getTicket");

            var request = new XMLHttpRequest();
            request.open("POST", resource);
            request.setRequestHeader(state.ticketHeader, 'Bearer ' + token)
            request.send(formData);
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status === 200 && request.statusText === 'OK') {
                        console.log('successful');
                    } else {
                        console.log('failed');
                    }
                }
            }

        },


        async exportBaTerms({state}) {
            let resource = state.host + '/api/harmonogram/ba-term/export';

            return new Promise(async (resolve, reject) => {
                let token = await this.dispatch("api/getTicket");
                if (token) {
                    http
                        .post(resource)
                        .set('Content-Type', 'application/json')
                        .set(state.ticketHeader, 'Bearer ' + token)
                        .send()
                        .responseType('blob')
                        .end((err, res) => {
                            if (err) {
                                err.response = res;
                                reject(err)
                            } else {
                                resolve(res);
                            }
                        })
                } else {
                    reject(null);
                }
            });
        },



        async exportEmptyTerms({state}, actionId) {
            let resource = state.host + '/api/harmonogram/plan-term/action/export/' + actionId;

            return new Promise(async (resolve, reject) => {
                let token = await this.dispatch("api/getTicket");
                if (token) {
                    http
                        .get(resource)
                        .set('Content-Type', 'application/json')
                        .set(state.ticketHeader, 'Bearer ' + token)
                        .send()
                        .responseType('blob')
                        .end((err, res) => {
                            if (err) {
                                err.response = res;
                                reject(err)
                            } else {
                                resolve(res);
                            }
                        })
                } else {
                    reject(null);
                }
            });
        },


        async exportShops({state}) {
            let resource = state.host + '/api/shop/sv/export';

            return new Promise(async (resolve, reject) => {
                let token = await this.dispatch("api/getTicket");
                if (token) {
                    http
                        .post(resource)
                        .set('Content-Type', 'application/json')
                        .set(state.ticketHeader, 'Bearer ' + token)
                        .responseType('blob')
                        .end((err, res) => {
                            if (err) {
                                err.response = res;
                                reject(err)
                            } else {
                                resolve(res);
                            }
                        })
                } else {
                    reject(null);
                }
            });
        },

        async importShops({state}, formData) {
            let resource = state.host + '/api/shop/sv/import';
            let token = await this.dispatch("api/getTicket");

            return new Promise(async (resolve, reject) => {
                var request = new XMLHttpRequest();
                request.open("POST", resource);
                request.setRequestHeader(state.ticketHeader, 'Bearer ' + token)
                request.send(formData);
                request.onreadystatechange = function () {
                    if (request.readyState === 4) {
                        if (request.status === 200) {
                            console.log('successful');
                            resolve('successful');
                        } else {
                            console.log('failed');
                            reject('error');
                        }
                    }
                }
            });
        },




    }
}