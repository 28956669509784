const ANONYMOUS = {name: 'anonymous', level: 0};
const BA = {name: 'ba', level: 1};
const SV = {name: 'sv', level: 2};
const AGENCY = {name: 'agency', level: 3};
const ITPL = {name: 'itpl', level: 4};
const ADMIN = {name: 'admin', level: 5};


const LEVEL = 'level';
const DIRECT_ROLE = 'direct_role';

let UserRole = {
    ANONYMOUS, BA, SV, AGENCY, ITPL, ADMIN
};

let AccessType = {LEVEL, DIRECT_ROLE};

function findRole(role) {
    if (role.toLowerCase() === UserRole.ADMIN.name) {
        return UserRole.ADMIN;
    }
    if (role.toLowerCase() === UserRole.ITPL.name) {
        return UserRole.ITPL;
    }
    if (role.toLowerCase() === UserRole.AGENCY.name) {
        return UserRole.AGENCY;
    }
    if (role.toLowerCase() === UserRole.SV.name) {
        return UserRole.SV;
    }
    if (role.toLowerCase() === UserRole.BA.name) {
        return UserRole.BA;
    }
    return UserRole.ANONYMOUS;
}

function checkAccess(router, store) {
    router.beforeEach(async (to, from, next) => {
        let userRoles = await store.dispatch('auth/role');
        let accessType = AccessType.LEVEL;
        let roles = [UserRole.ANONYMOUS];
        let access = false;
        if (to.meta.access) {
            if (to.meta.access.roles) {
                roles = to.meta.access.roles;
            }
            if (to.meta.access.type) {
                accessType = to.meta.access.type;
            }
            roles.forEach(role => {
                if (accessType === AccessType.DIRECT_ROLE && userRoles.find(r => r.name == role.name)) {
                    access = true;
                }
                if (accessType === AccessType.LEVEL && userRoles.filter(r => r.level >= role.level).length > 0) {
                    access = true;
                }
            });
            if (access) {
                next();
            } else {
                next(process.env.VUE_APP_ACCESS_REDIRECT)
            }
        } else {
            next(process.env.VUE_APP_ACCESS_REDIRECT)
        }
    });
}

function checkAccessRole(userRoles, accessRoles) {
    let access = false;
    accessRoles.roles.forEach(role => {
        if (accessRoles.type === AccessType.DIRECT_ROLE && userRoles.find(r => r.name == role.name)) {
            access = true;
        }
        if (accessRoles.type === AccessType.LEVEL && userRoles.filter(r => r.level >= role.level).length > 0) {
            access = true;
        }
    });

    return access;
}

export {
    UserRole,
    AccessType,
    checkAccess,
    findRole,
    checkAccessRole
}