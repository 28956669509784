import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import MessageStore from '@miro-labs/vue3-message/MessageStore'
import ApiStore from '@miro-labs/vue3-api/ApiStore'
import MenuStore from '@miro-labs/vue3-architect-layout/layout/base/menu/MenuStore'
import AuthStore from './AuthStore'

import DictionaryStore from '../components/admin/dictionary/DictionaryStore'
import MailStore from '../components/admin/message/MessageStore'
import UserStore from '../components/admin/user/UserStore'
import ShopStore from '../components/shop/ShopStore'
import AgencyStore from '../components/agency/AgencyStore'
import SvStore from '../components/sv/SvStore'
import BaStore from '../components/ba/BaStore'

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
    plugins: [createPersistedState({
        key: 'easy-way-vuex',
        paths: ['menu', 'adminMap']
    })],
    struct: debug,
    modules: {
        api: ApiStore,
        message: MessageStore,
        menu: MenuStore,
        auth: AuthStore,
        dictionary: DictionaryStore,
        mail: MailStore,
        user: UserStore,
        shop: ShopStore,
        agency: AgencyStore,
        sv: SvStore,
        ba: BaStore
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {}
});

export default store;