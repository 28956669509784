/* eslint-disable */


export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {
        saveBaTerm({}, params) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-term/ba/' + params.planId, data: params.data});
        },

        createPollForm({}, id) {
            return this.dispatch('api/get', {path: '/api/polls/form/create/' +id});
        },

        getPollForm({}, id) {
            return this.dispatch('api/get', {path: '/api/polls/form/result/' +id});
        },

        savePollForm({},data) {
            return this.dispatch('api/post', {path: '/api/polls/form/v2', data: data});
        },

        upload({},data) {
            return this.dispatch('api/post', {path: '/api/delfs', data: data});
        },

        findActions({}) {
            return this.dispatch('api/get', {path: '/api/target/plan-bas'});
        },

        addComments({}, comment) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-term/ba/comments', data: comment});
        },

        findComments({}, id) {
            return this.dispatch('api/get', {path: '/api/harmonogram/plan-term/ba/comments/' + id});
        },

        findCode({}, id) {
            return this.dispatch('api/get', {path: '/api/shop/actioncode/poll/' + id});
        }
    }
}