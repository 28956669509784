<template>
    <div>
        <p>{{question.number}}. {{question.title}}</p>
        <p><Rating v-model="value" :cancel="false" :disabled="true" :stars="(this.question.maxValue - this.question.minValue) +1"/></p>
        <p v-if="question.dependentQuestion" class="question-info">
            Pytanie zależne od pytania: {{question.dependentQuestion.number}}. {{question.dependentQuestion.title}}
        </p>
    </div>
</template>


<script>

    import Rating from 'primevue/rating';

    export default {
        props: ['question'],
        emits: [],
        components: {
            Rating
        },
        data() {
            return {
                value: 0
            }

        },

        methods: {
            filterOptions() {
                return this.question.options.filter(o => !o.deleted);
            }
        }
    }
</script>