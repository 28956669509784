<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card app-form">
        <div class="card-body" :style="{
            'background-color': '#'+getSettningValue('background'),
            'font-size': getSettningValue('fontSize') + 'px',
            color: '#'+getSettningValue('fontColor'),
            'font-family': getSettningValue('fontName')}" >
            
            <h4 style="margin-top: 5rem; text-align: center">{{form.poll.pollDto.endPage.title}}</h4>
            <p style="margin-top: 1rem; text-align: center; font-size: 1.1rem">{{form.poll.pollDto.endPage.description}}</p>

            <div v-if="code" class="discount-code">
                <label>Kod rabatowy: </label>
                <span>{{code.code}}</span>
            </div>

            <div style="display: flex; justify-content: center; margin-top: 2rem;">
                <button style="width: 130px" class="btn btn-outline-primary  me-2" @click="close($event)" >Zamknij</button>
            </div>

        </div>

    </div>
</template>

<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Prezentacje", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Dashboard", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Ankieta"}
            ];
            return {
                breadcrumpsList,
            }
        },


        components: {
            Breadcrumbs,

        },



        data() {
            return {
                baTermId: null,
                form: {poll: {pollDto: {endPage: {title: null, description: null}}}},
                code: null
            }
        },



        methods: {

            getSettningValue(name) {
                if (this.form.poll.pollDto.settings) {
                    let sett = this.form.poll.pollDto.settings.find(s => s.name == name);
                    return sett ? sett.value : '';
                }
                return '';
            },


            close(event) {
                event.preventDefault();
                this.$router.replace({name: 'baDashboard'});
            }


        },


        async beforeMount() {
            this.baTermId = this.$route.params.id;
            this.form = await this.$store.dispatch('ba/getPollForm',this.$route.params.id);
            if (this.form.pollResult.code) {
                this.code = await this.$store.dispatch('ba/findCode', this.form.pollResult.code.id);
            }
        }

    }
</script>