<template>
    <pv-dialog
            header="Rozpoczęcie pracy"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <Camera :visibleCamera="visibleCamera" @changeValue="addLink" />
        <div style="max-width: 400px;" v-if="term.startPhoto">
            <img :src="term.startPhoto" style="max-width: 100%" />
            <button class="btn btn-primary" @click="openCamera()" >Zmień</button>
        </div>
        <p v-if="this.term.startLat">Lokalizacja ustawiona</p>
        <div class="position-relative row mb-3">
            <label class="form-label col-sm-2 col-form-label" style="display: block;width: 100%;">Zapas produktu promocyjnego</label>
            <div class="col-sm-10">
                <pv-input-number
                        style="width: 100%"
                        mode="decimal"
                        :minFractionDigits="0"
                        :maxFractionDigits="0"
                        :locale="'pl'"
                        v-model="term.stock"/>
            </div>
        </div>

        <button class="btn btn-primary pull-right" @click="saveForm($event)" >Rozpocznij</button>




    </pv-dialog>
</template>



<script>
    import Dialog from 'primevue/dialog';
    import InputNumber from 'primevue/inputnumber';
    import Camera from '../../../../camera/template/Camera.vue'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'pv-input-number': InputNumber,
            Camera
        },
        data() {
            return {
                visibleCamera: false,
                visible: false,
                formData: null,
                formStruct: {fields: []},
                targets: [],
                term: null,
                planId: null
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(term) {
                await this.refreshData(term);
            }
        },

        methods: {
            async saveForm(event) {
                event.preventDefault();
                if (this.term.stock == null) {
                    alert('Ustaw zapas produktu promocyjnego');
                    return;
                }

                this.term.status = "WORKING";
                await this.$store.dispatch('ba/saveBaTerm',{planId: this.planId, data: this.term});


                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            refreshData(term) {
                this.planId = term.planId;
                this.term = term.baTerm;
                this.openCamera();
                this.startEvent();
            },

            async startEvent() {
                if (navigator.geolocation) {
                    let geoSettings = {
                        enableHighAccuracy: true,
                        maximumAge: 10000,
                        timeout: 15000
                    };
                    navigator.geolocation.getCurrentPosition(
                        (position) => this.startEventLocal(position),
                        (error) => alert(error.code + ": " + error.message),
                        geoSettings );
                } else {
                    alert("Twoja przeglądarka nie obsługuje geolokalizacji!");
                }
            },

            async startEventLocal(position) {
                this.term.startLat = position ? position.coords.latitude : null;
                this.term.startLng = position ? position.coords.longitude : null;
            },

            addLink(resource) {
                if (resource) {
                    this.term.startPhoto = resource.url;
                }
                this.visibleCamera = false;
            },

            openCamera() {
                if (this.visibleCamera) {
                    this.visibleCamera = false;
                }
                this.visibleCamera = true;
            }
        }
    }
</script>