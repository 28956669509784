<template>
    <div>
        <ml-input-text v-if="inputData.type == 'text'"  :formInput="inputData"  @changeValue="inputChangeValue"/>
        <ml-input-email v-if="inputData.type == 'email'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-input-password v-if="inputData.type == 'password'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-input-number v-if="inputData.type == 'number'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-textarea v-if="inputData.type == 'textarea'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-checkbox v-if="inputData.type == 'checkbox'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-dropdown v-if="inputData.type == 'dropdown'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-multiselect v-if="inputData.type == 'multiselect'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-radio-button-inline v-if="inputData.type == 'radioButtonInline'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-autocomplete v-if="inputData.type == 'autocomplete'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-table v-if="inputData.type == 'table'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-edit-table v-if="inputData.type == 'editTable'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-action v-if="inputData.type == 'action'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-separator v-if="inputData.type == 'separator'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-label v-if="inputData.type == 'label'" :formInput="inputData" @changeValue="inputChangeValue"/>
        <ml-html-editor v-if="inputData.type == 'htmlEditor'"  :formInput="inputData"  @changeValue="inputChangeValue"/>
        <ml-calendar v-if="inputData.type == 'calendar'"  :formInput="inputData"  @changeValue="inputChangeValue"/>
        <ml-file-upload v-if="inputData.type == 'fileUpload'"  :formInput="inputData"  @changeValue="inputChangeValue"/>
    </div>
</template>

<script>



    import InputText from './inputs/InputText.vue'
    import InputEmail from './inputs/InputEmail.vue'
    import InputPassword from './inputs/InputPassword.vue'
    import InputNumber from './inputs/InputNumber.vue'
    import TextArea from './inputs/InputTextArea.vue'
    import CheckBox from './inputs/CheckBox.vue'
    import Dropdown from './inputs/Dropdown.vue'
    import Multiselect from './inputs/Multiselect.vue'
    import RadioButtonInline from './inputs/RadioButtonInline.vue'
    import AutoComplete from './inputs/AutoComplete.vue'
    import Table from './inputs/Table.vue'
    import EditTable from './inputs/EditTable.vue'
    import Action from './inputs/Action.vue'
    import Separator from './inputs/Separator.vue'
    import Label from './inputs/Label.vue'
    import HtmlEditor from './inputs/HtmlEditor.vue'
    import Calendar from './inputs/Calendar.vue'
    import FileUpload from './inputs/FileUpload.vue'

    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'ml-input-text': InputText,
            'ml-input-email': InputEmail,
            'ml-input-password': InputPassword,
            'ml-input-number': InputNumber,
            'ml-textarea': TextArea,
            'ml-checkbox': CheckBox,
            'ml-dropdown': Dropdown,
            'ml-multiselect': Multiselect,
            'ml-radio-button-inline': RadioButtonInline,
            'ml-autocomplete': AutoComplete,
            'ml-table': Table,
            'ml-edit-table': EditTable,
            'ml-action': Action,
            'ml-separator': Separator,
            'ml-label': Label,
            'ml-html-editor': HtmlEditor,
            'ml-calendar': Calendar,
            'ml-file-upload': FileUpload,

        },
        data() {
            return {
                inputData: {
                    type: 'none'
                }
            }
        },
        methods: {
            inputChangeValue(data) {
                this.$emit('changeValue', data);
            }
        },
        created() {
            this.inputData = this.formInput;
        },
        watch: {
            formInput: {
                handler() {
                    this.inputData = this.formInput;
                },
                deep: true
            }
        }
    }
</script>