<template>
    <div>
        <p>{{question.number}}. {{question.title}}</p>
        <div><img :src="question.externalLink" class="img-thumbnail rounded mx-auto d-block" style="max-width: 200px"></div>
        <p v-if="question.dependentQuestion" class="question-info">
            Pytanie zależne od pytania: {{question.dependentQuestion.number}}. {{question.dependentQuestion.title}}
        </p>
    </div>
</template>


<script>

    export default {
        props: ['question'],
        emits: [],
        components: {},

        methods: {
            filterOptions() {
                return this.question.options.filter(o => !o.deleted);
            }
        }
    }
</script>