import {UserRole, AccessType} from "../../components/AccessComponent";

import ActionList from "./action/template/ActionList.vue"
import PlanList from "./action/template/PlanList.vue"
import ShopCalendar from "./action/template/ShopCalendar.vue"
import TargetList from "./action/template/TargetList.vue"
import TargetForm from "./action/template/TargetForm.vue"

import UserList from "./user/template/UserList.vue"
import UserForm from "./user/template/UserForm.vue"
import BaCalendar from "./user/template/BaCalendar.vue"

import Calendar from "./schedule/template/Calendar.vue"
import TableList from "./schedule/template/Table.vue"

export default [
    {
        path: "/sv/action/list",
        name: "svActionList",
        component: ActionList,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/sv/action/plans/:id",
        name: "svActionPlans",
        component: PlanList,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/sv/action/plan/calendar/:id",
        name: "shopCalendar",
        component: ShopCalendar,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.LEVEL}
        },
    },


    {
        path: "/sv/action/target/:id",
        name: "svActionTargets",
        component: TargetList,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/sv/action/target/:planId/:id",
        name: "svActionTargetForm",
        component: TargetForm,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },


    {
        path: "/sv/user/list",
        name: "svUserList",
        component: UserList,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.LEVEL}
        },
    },


    {
        path: "/sv/user/add",
        name: "svUserAdd",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.LEVEL}
        },
    },

    {
        path: "/sv/user/edit/:id",
        name: "svUseEdit",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.LEVEL}
        },
    },

    {
        path: "/sv/user/calendar/:id",
        name: "svUserCalendar",
        component: BaCalendar,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.LEVEL}
        },
    },



    {
        path: "/sv/schedule/calendar",
        name: "svScheduleCalendar",
        component: Calendar,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/sv/schedule/table",
        name: "svScheduleTable",
        component: TableList,
        meta: {
            layout: 'base',
            menu: 'sv',
            access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}
        },
    },

]