<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-primary me-2" @click="create()"  />
                </template>

                <template #end>

                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="rows"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >


                <pv-column field="name" header="Nazwa gadżetu" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="addedToStock" header="Wydano agencji" style="width: 120px; text-align: center;" />
                <pv-column field="currentStock" header="Stan bieżący" style="width: 120px; text-align: center;" />
                <pv-column field="gave" header="Wydano klientom" style="width: 120px; text-align: center;" />
                <pv-column header="Wskaźnik" style="width: 120px; text-align: center;">
                    <template #body="slotProps">
                        {{calculatePercent(slotProps.data)}}
                    </template>
                </pv-column>


                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Gadżety"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
        },




        data() {
            return {
                loading: false,
                rows: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },
                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'giftAdd'});
            },

            edit(item) {
                this.$router.replace({name: 'giftEdit', params: {id: item.id}});
            },

            async deleteItem(item) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć rekord?',
                    header: 'Usuwaie prezentu',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('shop/deleteGift', item.id);
                        this.refreshTable();
                    },
                });
            },

            calculatePercent(data) {
                let ratio = data.addedToStock > 0 ? data.gave / data.addedToStock : 0;
                let percent = Math.round(ratio * 10000) / 100;
                return percent + "%";
            },


            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        name: this.lazyParams.filters.name.value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder < 0 ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'id' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('shop/findGifts', query);
                this.totalRecords = response.totalElements;
                this.rows = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },


        },

        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>