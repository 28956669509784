<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            >
                <template v-slot:afterInputs>
                    <div class="row mb-3">
                        <div class="col-sm-12"><button class="btn btn-primary" @click="addPromotion($event)" >Dodaj mechanizm</button></div>
                        <div class="col-sm-12">
                            <div class="table-responsive" >
                                <table class="mb-0 table table-striped edit-table">
                                    <thead>
                                        <tr>
                                            <th style="width: auto">Mechanizm</th>
                                            <th v-if="formData.benefitType == 'GIFT'" style="width: 300px">Gadżet</th>
                                            <th v-if="formData.benefitType == 'GIFT'" style="width: 300px">Gadżet</th>
                                            <th style="width: 170px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in formData.promotions" :key="index">
                                            <td>{{item.promotion.name}}</td>
                                            <td v-if="formData.benefitType == 'GIFT'">
                                                <div v-if="item.actionGifts.length > 0" style="display: flex">
                                                    <div style="margin-right: 10px">{{item.actionGifts[0].gift.name}}</div>
                                                    <div>{{item.actionGifts[0].assigned}}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="item.actionGifts.length > 1" style="display: flex">
                                                    <div style="margin-right: 10px">{{item.actionGifts[1].gift.name}}</div>
                                                    <div>{{item.actionGifts[1].assigned}}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <button v-if="formData.id && item.promotion.id" class="mb-2 me-2 btn-icon btn-icon-only btn btn-info" @click="codes($event, item)">
                                                    <i class="pi pi-tags btn-icon-wrapper"> </i>
                                                </button>
                                                <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-warning" @click="editPromotion($event, item)">
                                                    <i class="pi pi-pencil btn-icon-wrapper"> </i>
                                                </button>
                                                <button class="mb-2 me-2 btn-icon btn-icon-only btn btn-danger" @click="deletePromotion($event, item)">
                                                    <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </ml-form>
            <PromotionForm :visibleDialog="visibleDialog" :dataValue="promotionData" :visibleGift="true" @closeForm="visibleDialog = false" @saveForm="addPromotionToList" />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import PromotionForm from './dialog/ActionPromotionForm.vue'
    import {updateValue, removeProxy} from '../../../form/ml/form/class/InputsManager'

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Akcje", routerName: 'shopActionList', params: {}, query: {}},
                {label: "Edycja akcji"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            PromotionForm,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                dictionares: state => state.dictionary.dictionares
            }),
        },

        data() {
            return {
                visibleDialog: false,
                promotionData: null,
                formData: {
                    id: null,
                    name: "",
                    promotions: []
                },
                agencyList: [],
                formStruct: {fields: []},
                benefitTypes: [
                    {name: 'GIFT', title: 'Gadżet'},
                    {name: 'COUPON', title: 'Kod rabatowy'},
                ],
                actionTypes: [
                    {name: 'EVENT', title: 'Event'},
                    {name: 'INSTORE', title: 'Instore'},
                    {name: 'OUTSTORE', title: 'Outstore'}
                ]
            }

        },


        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },


            addPromotionToList(promotion) {
                this.visibleDialog = false;
                if (promotion.id == 0 || promotion.id == null || promotion.id == undefined) {
                    this.formData.promotions.push(promotion);
                } else {
                    let obj = this.formData.promotions.find(j => j.id == promotion.id);
                    let index = this.formData.promotions.indexOf(obj);
                    this.formData.promotions.splice(index,1);
                    if (promotion.id <0) {
                        promotion.id = null;
                    }
                    this.formData.promotions.splice(index, 0, promotion);
                }
            },

            addPromotion(event) {
                event.preventDefault();
                this.promotionData = {
                    promotion: null,
                    actionGifts: [],
                };
                this.visibleDialog = true;
            },


            editPromotion(event, item) {
                event.preventDefault();
                let data = removeProxy({...item});
                this.promotionData = data;
                if (item.id == 0 || item.id == null || item.id == undefined) {
                    this.promotionData.id = -1;
                }

                this.visibleDialog = true;
            },

            deletePromotion(event, promotion) {
                event.preventDefault();
                let obj = this.formData.promotions.find(j => j.id == promotion.id);
                let index = this.formData.promotions.indexOf(obj);
                this.formData.promotions.splice(index,1);
            },

            codes(event, item) {
                event.preventDefault();
                this.$router.replace({name: 'codeList', params: {actionId: this.formData.id, promotionId: item.promotion.id}});
            },


            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('shop/saveAction', data);
                this.$router.replace({name: 'shopActionList'});

            },
            cancelForm() {
                this.$router.replace({name: 'shopActionList'});
            }

        },

        async beforeMount() {
            this.agencyList = await this.$store.dispatch('dictionary/findDictionaryValuesByName', 'Agencja');
            let fData = {
                id: null,
                name: "",
            };
            if (this.$route.params.id > 0) {
                fData = await this.$store.dispatch('shop/findAction', this.$route.params.id);
                fData.dateFrom = new Date(fData.dateFrom);
                fData.dateTo = new Date(fData.dateTo);

            }
            this.formData = fData;
            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'name', label: 'Nazwa akcji', placeholder: 'Wpisz nazwę akcji',
                        tooltip: 'Wpisz nazwę akcji', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 150, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                    {type: 'DROPDOWN', name: 'agency', label: 'Agencja',
                        placeholder: 'Agencja',
                        tooltip: '', info: '', required: false,  requiredMessage: 'Pole jest wymagane',
                        options: this.agencyList, optionLabel: 'value'},
                    {type: 'CALENDAR', name: 'dateFrom', label: 'Data od', tooltip: 'Data ważności od', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'CALENDAR', name: 'dateTo', label: 'Data do', tooltip: 'Data ważności do', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'NUMBER', name: 'soldTarget', label: 'Cel sprzedażowy 1', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'secondTarget', label: 'Cel sprzedażowy 2', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'thirdTarget', label: 'Cel sprzedażowy 3', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'contactTarget', label: 'Ilość nawiązaych kontaktów', placeholder: 'Ilość nawiązaych kontaktów',
                        tooltip: 'Wpisz ilość nawiązanych kontaktów do zrealizaowania', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'promotionDays', label: 'Cel promo dni', placeholder: 'Cel promo dni',
                        tooltip: 'Wpisz Cel promo dni', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'DROPDOWN', name: 'actionType', label: 'Typ akcji',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        tooltip: '', info: '', options: this.actionTypes, optionValue: 'name', optionLabel: 'title'},
                    {type: 'DROPDOWN', name: 'benefitType', label: 'Rodzaj nagrody',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        tooltip: '', info: '', options: this.benefitTypes, optionValue: 'name', optionLabel: 'title'},



                ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
            }


        }
    }

</script>