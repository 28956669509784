/* eslint-disable */

class Validate {
    isNotEmpty(value) {
        return value != null && value.length > 0;
    }

    isNotNull(value) {
        return value !== null;
    }

    isNotEmptyString(value) {
        return value !== '';
    }


    isMinLength(value, minLength) {
        return value !== null && value.length >= minLength;
    }

    isMaxLength(value, maxLength) {
        return value !== null && value.length <= maxLength;
    }

    validateEmail(value) {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
    }

    validatePasswordPattern(value, pattern) {
        if (pattern) {
            if (pattern === 'strong') {
                //At least one lowercase
                //At least one uppercase
                //At least one numeric
                //Minimum 8 characters

                return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value);
            }
            if (pattern === 'medium') {
                //At least one lowercase
                //At least one uppercase or one number
                //Minimum 6 characters

                return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(value);
            }
            return new RegExp(pattern).test(value);
        }
        return true;
    }
}

class DefaultInput {
    constructor(input) {
        this.input = input;
        this.valid = new Validate();
    }

    updateStruct(struct) {

    }

    validate() {
        this.validateInput();
        this.customValidate();

    }


    validateInput() {
        this.input.error = false;
        this.input.validated = true;
    }

    customValidate() {
        if(!this.input.error) {
            if (typeof this.input.customValidate === 'function') {
                if (this.input.customValidate(this.input)) {
                    this.input.errorCustom = false;
                } else {
                    this.input.errorCustom = true;
                    this.input.error = true;
                    this.input.validated = false;
                }
            }
        } else {
            this.input.errorCustom = false;
        }
    }

}





export class CalendarManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.placeholder = struct.placeholder ? struct.placeholder : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.input.dateFormat = struct.dateFormat === undefined || struct.dateFormat === null  ? 'dd.mm.yy' : struct.dateFormat;
        this.input.minDate = struct.minDate === undefined || struct.minDate === null  ? null : struct.minDate;
        this.input.maxDate = struct.maxDate === undefined || struct.maxDate === null  ? null : struct.maxDate;

        this.input.customMessage = struct.customMessage ? struct.customMessage : 'error';
    }


    validateInput() {
        if (this.input.required && !this.valid.isNotNull(this.input.value)) {
            this.input.errorRequired = true;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class FileUploadManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.input.customMessage = struct.customMessage ? struct.customMessage : 'error';
    }


    validateInput() {
        if (this.input.required && !this.valid.isNotNull(this.input.value)) {
            this.input.errorRequired = true;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class InputTextManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.placeholder = struct.placeholder ? struct.placeholder : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.input.minLength = struct.minLength ? struct.minLength : 0;
        this.input.minLengthMessage = struct.minLengthMessage ? struct.minLengthMessage : 'Field must be at least ' +
            this.minLength+ ' characters long ';

        this.input.maxLength = struct.maxLength ? struct.maxLength : 255;
        this.input.maxLengthMessage = struct.maxLengthMessage ? struct.maxLengthMessage : 'The field can be max to ' +
            this.maxLength +' characters long';

        this.input.customMessage = struct.customMessage ? struct.customMessage : 'error';
    }


    validateInput() {
        if (this.input.required && !this.valid.isNotEmpty(this.input.value)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else if (!this.valid.isMinLength(this.input.value, this.input.minLength)) {
            this.input.errorRequired = false;
            this.input.errorMinLength = true;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else if (!this.valid.isMaxLength(this.input.value, this.input.maxLength)) {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = true;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}

export class InputEmailManager extends InputTextManager {

    updateStruct(struct) {
        super.updateStruct(struct);
        this.input.invalidEmailMessage = struct.invalidEmailMessage ? struct.invalidEmailMessage : 'Please enter a valid email address';
    }

    validateInput() {
        super.validateInput();
        if (!this.input.error) {
            if (!this.valid.validateEmail(this.input.value)) {
                this.input.errorInvalidEmail = true;
                this.input.error=true;
                this.input.validated=false;
            } else {
                this.input.errorInvalidEmail = false;
            }
        } else {
            this.input.errorInvalidEmail = false;
        }
    }
}

export class InputPasswordManager extends InputTextManager {
    updateStruct(struct) {
        super.updateStruct(struct);
        this.input.pattern = struct.pattern ? struct.pattern : null;
        this.input.patternMessage = struct.patternMessage ? struct.patternMessage : 'Password is too week';
    }

    validateInput() {
        super.validateInput();
        if (!this.input.error) {
            if (!this.valid.validatePasswordPattern(this.input.value, this.input.pattern)) {
                this.input.errorPattern = true;
                this.input.error=true;
                this.input.validated=false;
            } else {
                this.input.errorPattern = false;
            }
        } else {
            this.input.errorPattern = false;
        }
    }
}


export class InputNumberManager extends InputTextManager {
    updateStruct(struct) {
        super.updateStruct(struct);
        this.input.minFractionDigits = struct.minFractionDigits !== undefined ? struct.minFractionDigits : 0;
        this.input.maxFractionDigits = struct.maxFractionDigits !== undefined ? struct.maxFractionDigits : 2;
        this.input.locale = struct.locale !== undefined ? struct.locale : null;
        this.input.suffix = struct.suffix !== undefined ? struct.suffix : null;
        this.input.prefix = struct.prefix !== undefined ? struct.prefix : null;
        this.input.min = struct.min !== undefined ? struct.min : null;
        this.input.max = struct.max !== undefined ? struct.max : null;
        this.input.grouping = struct.grouping !== undefined ? struct.grouping : true;


    }

    validateInput() {
        if (this.input.required && !this.valid.isNotNull(this.input.value)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}

export class TextAreaManager extends InputTextManager {
    updateStruct(struct) {
        super.updateStruct(struct);
    }

    validateInput() {
        super.validateInput();
    }
}


export class CheckboxManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

    }


    validateInput() {
        if (this.input.required && !this.valid.isNotNull(this.input.value)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class DropdownManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.input.options = struct.options;
        this.input.optionLabel = struct.optionLabel;
        this.input.optionValue = struct.optionValue === undefined || struct.optionValue === null ? null : struct.optionValue;

    }


    validateInput() {
        if (this.input.required && (!this.valid.isNotNull(this.input.value) || !this.valid.isNotEmptyString(this.input.value))) {
            this.input.errorRequired = true;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class MultiselectManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
        this.input.options = struct.options;
        this.input.optionLabel = struct.optionLabel;
        this.input.optionValue = struct.optionValue === undefined || struct.optionValue === null ? null : struct.optionValue;

    }


    validateInput() {
        if (this.input.required && (!this.valid.isNotNull(this.input.value) || !this.valid.isNotEmptyString(this.input.value))) {
            this.input.errorRequired = true;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class RadioButtonInlineManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

    }


    validateInput() {
        if (this.input.required && (!this.valid.isNotNull(this.input.value) || !this.valid.isNotEmptyString(this.input.value))) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class AutoCompleteManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

    }


    validateInput() {
        if (this.input.required && (!this.valid.isNotNull(this.input.value) || this.input.value === undefined)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}

export class TableManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.input.columns = struct.columns ? struct.columns : [];
        this.input.filterMethod = struct.filterMethod !== undefined ? struct.filterMethod : null;
        this.input.editMethod = struct.editMethod ? struct.editMethod : () => null;
        this.input.removeMethod = struct.removeMethod ? struct.removeMethod : () => null;


    }


    validateInput() {
        if (this.input.required && (!this.valid.isNotNull(this.input.value) || this.input.value === undefined)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}

export class EditTableManager extends DefaultInput {

    updateStruct(struct) {
        this.info =  struct.info ? struct.info : '';
        this.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';

        this.input.maxRows = struct.maxRows ?  struct.maxRows : 0;
        this.input.columns = struct.columns ? struct.columns : [];
        this.input.addLabel = struct.addLabel ? struct.addLabel : 'Dodaj';
        this.input.addMethod = struct.addMethod ? struct.addMethod : () => {
            let obj = {};
            this.columns.forEach(c => obj[c.name] == null);
            return obj;
        };
        this.input.filterMethod = struct.filterMethod ? struct.filterMethod : () => {return true};
        this.input.removeMethod = struct.removeMethod ? struct.removeMethod : () => null;
    }


    validateInput() {
        try {
            if (this.input.required && this.input.value.length == 0) {
                this.input.errorRequired = true;
                this.input.error = true;
            } else {
                this.input.errorRequired = false;
                this.input.error = false;
            }
        } catch {
            this.input.errorRequired = true;
            this.input.error = true;
        }
        this.input.validated = !this.input.error;
    }
}



export class ActionManager extends DefaultInput {

    updateStruct(struct) {
        this.input.tooltip =  struct.tooltip ? struct.tooltip : '';
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.buttonIcon = struct.buttonIcon ? struct.buttonIcon : 'pi pi-plus';
        this.input.buttonText = struct.buttonText ? struct.buttonText : '';
        this.input.buttonClass = struct.buttonClass ? struct.buttonClass : 'p-button-primary';
        this.input.buttonPosition = struct.buttonPosition ? struct.buttonPosition : 'left';
        this.input.callAction = struct.callAction ? struct.callAction : () => null;
        this.input.params = struct.params !== undefined ? struct.params : null;
    }


    validateInput() {
        this.input.error = false;
        this.input.validated = true
    }
}

export class SeparatorManager extends DefaultInput {

    updateStruct(struct) {
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
    }


    validateInput() {
        this.input.error = false;
        this.input.validated = true
    }
}

export class LabelManager extends DefaultInput {

    updateStruct(struct) {
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
    }


    validateInput() {
        this.input.error = false;
        this.input.validated = true
    }
}

export class HtmlEditorManager extends DefaultInput {

    updateStruct(struct) {
        this.input.visibility = struct.visibility !== undefined ? struct.visibility : true;
        this.input.label = struct.label ? struct.label : '';
        this.input.disabled = struct.disabled ? struct.disabled : false;
        this.input.required = struct.required ? struct.required : false;
        this.input.requiredMessage = struct.requiredMessage ? struct.requiredMessage : 'The field ' + this.label +
            ' is required';
    }


    validateInput() {
        if (this.input.required && !this.valid.isNotEmpty(this.input.value)) {
            this.input.errorRequired = true;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = true;
        } else {
            this.input.errorRequired = false;
            this.input.errorMinLength = false;
            this.input.errorMaxLength = false;
            this.input.error = false;
        }
        this.input.validated = !this.input.error;
    }
}


export class Manager {
    constructor() {
        this.creating = true;
        this.inputs = [];
    }

    clearInputs() {
        this.inputs = [];
    }

    addInput(input) {
        this.inputs.push(input);
    }

    setValue(inputName, value) {
        let input = this.inputs.find(i => i.input.name == inputName);
        if (input != null) {
            if(input.input.value !== value) {
                input.input.value = value;
                if (!this.creating) {
                    input.validate();
                }
            }
        }
    }

    updateStruct(struct) {
        for(let field of struct.fields) {
            let input = this.inputs.find(i => i.input.name == field.name);
            if (input != null) {
                input.updateStruct(field);
            }
        }
    }

    clearValidate(data) {
        for(let input of this.inputs) {
            input.input.error = false;
            input.input.validated = false;
        }
    }


    findValueFromName(name, data) {
        let fields = name ? name.split('.') : null;
        return fields ? this.findValueFromNameByFields(data, fields) : null;
    }

    findValueFromNameByFields(data, fields) {
        let field = fields.shift();
        let value = data[field];
        if (fields.length == 0) {
            return value;
        }
        if (value !== undefined) {
            return this.findValueFromNameByFields(value, fields);
        }
        return null;
    }



    submit() {
        this.inputs.forEach(i => i.validate());
        return this.inputs.filter(i => i.input.visibility && i.input.error).length === 0;
    }

    data(dataForm) {
        let result = dataForm;
        let typeNotValue =['action', 'separator', 'label'];

        this.inputs.filter(i => i.input.visibility)
            .filter(i => typeNotValue.indexOf(i.input.type) < 0 ).forEach(i => {
                let fields = i.input.name.split('.');
                let currentObject = result;
                while(currentObject !== undefined && fields.length > 0) {
                    let field = fields.shift();
                    if (fields.length == 0) {
                        if (Array.isArray(i.input.value)) {
                            let arr = i.input.value.map(f => removeProxy(f));
                            currentObject[field] = arr;
                        } else if (i.input.value instanceof  Date) {
                            currentObject[field] = i.input.value;
                        } else if (typeof i.input.value == 'object') {
                            currentObject[field] = removeProxy(i.input.value);
                        } else {
                            currentObject[field] = i.input.value;
                        }
                    } else {
                        if (!currentObject[field]) {
                            currentObject[field] = {};
                        }
                        currentObject = currentObject[field];
                    }
                }
        });
        return removeProxy(result);
    }
}

export function convertArrayToObj(inputObject, property, key) {
    let arrayValues = inputObject[property];
    inputObject[property] = {};
    arrayValues.forEach(a => {
        if(key == null) {
            inputObject[property]['index_' + arrayValues.indexOf(a)] = a
        } else {
            let keyValue = findByKey(a, key);
            if (keyValue !== null) {
                inputObject[property][keyValue] = a;
            }
        }
    });
}


export function convertObjectToArray(inputObject, property) {
    let attribs = inputObject[property];
    inputObject[property] = [];
    Object.keys(attribs).forEach(d => {
        inputObject[property].push(removeProxy(attribs[d]));
    });
}

export function removeProxy(proxy) {
    if (proxy !== null && typeof proxy == 'object') {
        let result = {...proxy};
        Object.keys(result).forEach(field => {
            if (result[field] !== null && typeof result[field] == 'object') {
                if (Array.isArray(result[field])) {
                    let arr = result[field].map(f => removeProxy(f));
                    result[field] = arr;
                } else if (result[field] instanceof  Date) {
                    result[field] = result[field];
                } else {
                    result[field] = removeProxy(result[field]);
                }
            }
        });
        return result;
    }
    return proxy;
}


export function findByKey(inputObject, property) {
    let keys = property.split('.');
    let  currentObject =  inputObject;
    let value = null;
    while (currentObject && keys.length > 0) {
        let key = keys.shift();
        if (keys.length == 0) {
            value= currentObject[key];
        } else {
            currentObject = currentObject[key];
        }
    }
    return value;
}


export function updateValue(object, data) {
    if (data.value !== undefined) {

        let currentObject = object;
        let keys = data.name.split('.');
        while (currentObject && keys.length > 0) {
            let key = keys.shift();
            if (keys.length == 0) {
                currentObject[key] = data.value;
            } else {
                currentObject = currentObject[key];
            }
        }
    }
}


