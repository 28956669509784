<template>
    <pv-dialog header=""
               :modal="true" :breakpoints="{'960px': '95vw'}" :style="{width: '70vw', marginTop: '2vh'}"
               v-model:visible="visible" position="top">
        <img :src="link" class="img-thumbnail rounded d-block" style="width: 100%">
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';

    export default {
        props: ['visibleDialog', 'dataValue'],

        components: {
            'pv-dialog': Dialog,
        },
        data() {
            return {
                link: false,
                visible: false,
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            dataValue(data) {
                console.log(data);
                this.link = data;
            }
        },
    }
</script>