export function joinDateAndTime(date, time) {
    let timeString = time.toLocaleTimeString('pl-Pl');
    let timezoneOffset = date.getTimezoneOffset() * 60000;
    let currentDate = new Date(Date.parse(new Date(date.getTime() - timezoneOffset).toISOString().slice(0,10)));
    return new Date(Date.parse(currentDate.toISOString().slice(0,10) + "T" + timeString));
}

export function formatDate(value) {
    let date = new Date(value);
    return date.toLocaleDateString('pl-Pl', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
}

export function formatDateTime(value) {
    let date = new Date(value);
    return formatDate(value) + " " + date.toLocaleTimeString('pl-Pl',  { hour: "2-digit", minute: "2-digit" });
}