<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div class="filter-card">
                <div class="col-sm-12">
                    <div class="table-responsive" >
                        <table class="mb-0 table table-target">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style="text-align: center" colspan="5">Cele do wykonania</th>
                                    <th style="text-align: center" :colspan="summary.gifts ? summary.gifts.length : 1">Gadżety</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th style="text-align: center">Cel sprzedażowy 1</th>
                                    <th style="text-align: center">Cel sprzedażowy 2</th>
                                    <th style="text-align: center">Cel sprzedażowy 3</th>
                                    <th style="text-align: center">Cel kontaktowy</th>
                                    <th style="text-align: center">Cel promo dni</th>
                                    <th style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.gift.name}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cel SV</td>
                                    <td style="text-align: center">{{summary.plan.soldTarget}}</td>
                                    <td style="text-align: center">{{summary.plan.secondTarget}}</td>
                                    <td style="text-align: center">{{summary.plan.thirdTarget}}</td>
                                    <td style="text-align: center">{{summary.plan.contactTarget}}</td>
                                    <td style="text-align: center">{{summary.plan.promotionDays}}</td>
                                    <td style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.plan}}</td>
                                </tr>
                                <tr>
                                    <td>Rozdysponowano</td>
                                    <td style="text-align: center">{{summary.distribute.soldTarget}}</td>
                                    <td style="text-align: center">{{summary.distribute.secondTarget}}</td>
                                    <td style="text-align: center">{{summary.distribute.thirdTarget}}</td>
                                    <td style="text-align: center">{{summary.distribute.contactTarget}}</td>
                                    <td style="text-align: center">{{summary.distribute.promotionDays}}</td>
                                    <td style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.distribute}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <pv-table
                    ref="dt"
                    :value="targets"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"
                    v-model:filters="filters"
                    filterDisplay="row"

            >

                <pv-column field="user.email" header="Email" style="width: 300px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="user.firstName" header="Imię" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="user.lastName" header="Nazwisko" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="soldTarget" header="Cel sprzedażowy 1" style="width: 140px;  text-align: center;" />
                <pv-column field="secondTarget" header="Cel sprzedażowy 2" style="width: 140px;  text-align: center;" />
                <pv-column field="thirdTarget" header="Cel sprzedażowy 3" style="width: 140px;  text-align: center;" />
                <pv-column field="contactTarget" header="Cel kontaktowy" style="width: 120px;  text-align: center;" />
                <pv-column field="promotionDays" header="Cel kontaktowy" style="width: 120px;  text-align: center;" />


                <pv-column  v-for="(item, index) in summary.gifts" :key="index" :header="item.gift.name" style="width: 120px;  text-align: center;" >
                    <template #body="slotProps">
                        {{giftAssigned(slotProps.data, item)}}
                    </template>
                </pv-column>

                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>


    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Supervisor", routerName: 'svActionList', params: {}, query: {}},
                {label: "Akcje", routerName: 'svActionList', params: {}, query: {}},
                {label: "Cele"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,

        },



        data() {
            return {
                targets:[],
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'user.email': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'user.firstName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'user.lastName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },
                summary: {
                    plan: {},
                    distribute: {}
                }
            }
        },


        methods: {

            giftAssigned(plan, item) {
                let planGift = plan.planGifts.find(p => p.gift.id == item.gift.id);
                return planGift ? planGift.assigned : 0;
            },

            edit(item) {
                this.$router.replace({name: 'svActionTargetForm', params: {planId:  this.$route.params.id, id: item.id}});
            },





            async refreshTable() {

                this.summary = await this.$store.dispatch('sv/summarySvTarget', this.$route.params.id);

                this.targets = await this.$store.dispatch('sv/findBaTargets', this.$route.params.id);
                this.totalRecords = this.targets.length;
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>