<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar  class="mb-4">

                <template #end>
                    <input type="file" accept=".xlsx" ref="importFile" style="display: none" @change="importFile($event)">
                    <pv-button label="Import" icon="pi pi-download"  class="me-2 inline-block"  @click="$refs.importFile.click()" />
                    <pv-button label="Export" icon="pi pi-upload"  class="me-2 inline-block"  @click="exportFile()" />
                    <pv-button label="Monitoring" icon="pi pi-upload"  class=""  @click="monitoringFile()" />
                </template>
            </pv-toolbar>



            <pv-table
                    ref="dt"
                    :value="events"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20, 50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    v-model:selection="selectedRows"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >
                <pv-column selectionMode="multiple" style="width: 3rem; padding: 1rem !important" />
                <pv-column field="planShop.action.name" header="Akcja" style="width: auto" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.shortName" header="Nazwa sklepu" style="width: auto" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.city" header="Miasto" style="width: 100px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.street" header="Ulica" style="width: 200px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.postcode" header="Kod pocztowy" style="width: 60px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.planSv.user.lastName" header="Supervisor" style="width: 100px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                    <template #body="{data}">
                        <span v-if="data.planShop.planSv">{{data.planShop.planSv.user.firstName}} {{data.planShop.planSv.user.lastName}}</span>
                    </template>
                </pv-column>

                <pv-column header="Temin" style="width: 140px" field="dateFrom">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-calendar v-model="filterModel.value"
                                     class="p-column-filter"
                                     dateFormat="dd.mm.yy"
                                     @date-select="filterCallback()"/>
                    </template>
                    <template #body="{data}">
                        {{convertDateToString(data.dateFrom)}}<br/>{{convertDateToString(data.dateTo)}}
                    </template>
                </pv-column>
                <pv-column header="Hostessy" style="width: 150px" field="hostess">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>


                    <template #body="{data}">
                        <p v-for="(ba, index) in data.hostess" :key="index"><span v-if="ba.planBa && ba.deleted ==false">{{ba.planBa.user.firstName}} {{ba.planBa.user.lastName}}</span></p>
                    </template>
                </pv-column>
                <pv-column header="" style="width: 120px">
                    <template #body="{data}">
                        <pv-button  v-if="data.planShop.planSv" icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>

            </pv-table>
            <button v-if="selectedRows.length > 0" class="btn btn-primary btn-lg me-2" @click="removeGroup()" >Usuń</button>
            <EditTerm :visibleDialog="visibleEditDialog" :dataValue="dataTerm" @closeForm="visibleEditDialog = false" @saveForm="refreshTable()" />
        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import ConfirmDialog from 'primevue/confirmdialog';
    import InputText from 'primevue/inputtext'
    import Calendar from 'primevue/calendar';
    import {FilterMatchMode} from 'primevue/api';
    import EditTerm from './dialog/EditTerm.vue'
    import Button from 'primevue/button'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Harmonogram", routerName: 'agencyScheduleTable', params: {}, query: {}},
                {label: "Lista"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
            'pv-calendar': Calendar,
            EditTerm
        },




        data() {
            return {
                visibleEditDialog: false,
                visibleDialog: false,
                dataTerm: null,
                loading: false,
                events: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'planShop.action.name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.shortName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.city': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.postcode': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.planSv.user.lastName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.street': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'hostess': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'dateFrom': {value: '', matchMode: FilterMatchMode.CONTAINS},

                },
                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {
            convertDateToString(dateStr) {
                let date = new Date(dateStr);

                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }) + " " + date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            },




            async importFile(event) {
                var file = event.target.files[0];
                if (!file) {
                    return;
                }

                let files = this.$refs.importFile.files;
                let formData = new FormData();
                formData.append('file', files[0]);


                await this.$store.dispatch('agency/importTerms', formData);
            },

            async monitoringFile() {
                this.loading = true;
                let response = await this.$store.dispatch('agency/exportBaTerms');
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="monitoring.xlsx";
                link.click();


                this.loading = false;
            },



            async exportFile() {
                this.loading = true;
                let query = {
                    filter: {
                        actionName: this.lazyParams.filters['planShop.action.name'].value,
                        shopShortName: this.lazyParams.filters['planShop.shop.shortName'].value,
                        shopCity: this.lazyParams.filters['planShop.shop.city'].value,
                        shopPostcode: this.lazyParams.filters['planShop.shop.postcode'].value,
                        shopStreet: this.lazyParams.filters['planShop.shop.street'].value,
                        svName: this.lazyParams.filters['planShop.planSv.user.lastName'].value,
                        hostessName: this.lazyParams.filters['hostess'].value,
                        actionDate: this.lazyParams.filters['dateFrom'].value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: 10000,
                    sortDirection: this.lazyParams.sortOrder === null ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'dateFrom' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('agency/exportTerms', query);
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="harmonogram.xlsx";
                link.click();


                this.loading = false;
            },

            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        actionName: this.lazyParams.filters['planShop.action.name'].value,
                        shopShortName: this.lazyParams.filters['planShop.shop.shortName'].value,
                        shopCity: this.lazyParams.filters['planShop.shop.city'].value,
                        shopPostcode: this.lazyParams.filters['planShop.shop.postcode'].value,
                        shopStreet: this.lazyParams.filters['planShop.shop.street'].value,
                        svName: this.lazyParams.filters['planShop.planSv.user.lastName'].value,
                        hostessName: this.lazyParams.filters['hostess'].value,
                        actionDate: this.lazyParams.filters['dateFrom'].value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder === null ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'dateFrom' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('sv/findTermsByQuery', query);
                this.totalRecords = response.totalElements;
                this.events = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                console.log('filter');
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },

            shopCalendar(item) {
                this.$router.replace({name: 'shopCalendar', params: {id:  item.id}});
            },

            deleteItem(item) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć rekord?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('sv/deleteTerm', item.id);
                        this.refreshTable();
                    },
                });
            },
            removeGroup() {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć wybrane rekordy?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        this.selectedRows.forEach(async (item) => {
                            await this.$store.dispatch('sv/deleteTerm', item.id);
                            this.refreshTable();
                        })
                    },
                });
            },

            edit(item) {
                this.dataTerm = item;
                this.visibleEditDialog = true;
            }

        },


        watch: {
            filter: {
                handler() {
                    this.refreshTable();
                },
                deep: true
            }
        },


        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>