<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-primary me-2" @click="create()"  />
                </template>

                <template #end>
                    <pv-button label="Monitoring" icon="pi pi-upload"  class=""  @click="monitoringFile()" />
                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="rows"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >


                <pv-column field="name" header="Nazwa akcji" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="dateFrom" header="Od" style="width: 150px">
                    <template #body="{data}">
                        {{formatDate(data.dateFrom)}}
                    </template>
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="dateTo" header="Do" style="width: 150px">
                    <template #body="{data}">
                        {{formatDate(data.dateTo)}}
                    </template>
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="soldTarget" header="Cel sprzedażowy 1" style="width: 110px;  text-align: center;" />
                <pv-column field="secondTarget" header="Cel sprzedażowy 2" style="width: 110px;  text-align: center;" />
                <pv-column field="thirdTarget" header="Cel sprzedażowy 3" style="width: 110px;  text-align: center;" />
                <pv-column field="contactTarget" header="Cel kontaktowy" style="width: 100px;  text-align: center;" />
                <pv-column field="promotionDays" header="Cel promo dni" style="width: 100px;  text-align: center;" />
                <pv-column field="shopCount" header="Ilość sklepów" style="width: 100px;  text-align: center;" />


                <pv-column  style="width: 170px">
                    <template #body="{data}">
                        <pv-button icon="fa fa-store" class="p-button-rounded p-button-primary me-2 mb-2" @click="shopList(data)" />
                        <pv-button icon="pe-7s-note2" class="p-button-rounded p-button-primary me-2 mb-2" @click="poll(data)" />
                        <pv-button icon="pe-7s-look" class="p-button-rounded p-button-primary me-2 mb-2" @click="previewPoll(data)" />
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2 mb-2" @click="edit(data)" />
                        <pv-button icon="pe-7s-copy-file" class="p-button-rounded p-button-primary me-2 mb-2" @click="copy(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger me-2 mb-2" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Akcje"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
        },




        data() {
            return {
                loading: false,
                rows: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'promotion.name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'dateFrom': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'dateTo': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },

                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'shopActionAdd'});
            },

            edit(item) {
                this.$router.replace({name: 'shopActionEdit', params: {id: item.id}});
            },

            shopList(item) {
                this.$router.replace({name: 'shopActionBind', params: {id: item.id}});
            },

            poll(item) {
                this.$router.replace({name: 'pollEdit', params: {id: item.id}});
            },

            async copy(item) {
                await this.$store.dispatch('shop/copyAction', item.id);
                await this.refreshTable();
            },

            async previewPoll(item) {
                let result = await this.$store.dispatch('shop/createPollForm', item.id);
                this.$router.replace({name: 'pollFrom', params:{id: result.id}});
            },

            async deleteItem(item) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć rekord?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('shop/deleteAction', item.id);
                        this.refreshTable();
                    },
                });
            },


            async refreshTable() {
                console.log(this.lazyParams.sortOrder);
                this.loading = true;
                let query = {
                    filter: {
                        name: this.lazyParams.filters.name.value,
                        promotion: this.lazyParams.filters['promotion.name'].value,
                        dateFrom: this.lazyParams.filters.dateFrom.value,
                        dateTo: this.lazyParams.filters.dateTo.value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder === null ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'id' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('shop/findActions', query);
                this.totalRecords = response.totalElements;
                this.rows = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },
            formatDate(value) {
                let date = new Date(value);
                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
            },

            async monitoringFile() {
                this.loading = true;
                let response = await this.$store.dispatch('agency/exportBaTerms');
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="monitoring.xlsx";
                link.click();


                this.loading = false;
            },

        },

        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>