<template>
    <pv-dialog
            header="Komentarze"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <div v-if="comments && comments.length > 0"  class="mb-4" style="font-size: 0.9em">
            <div v-for="comment in comments" :key="comment.id" class="row mb-2">
                <label class="form-label col-sm-3 col-form-label">
                    {{formatDate(comment.added)}} <br/>
                    {{comment.planBaTerm.planBa.user.firstName}} {{comment.planBaTerm.planBa.user.lastName}}
                </label>
                <div class="col-sm-9"><div style="padding: 0.5rem 0.5rem; background: #eaeaea; border: 1px solid #cacaca; border-radius: 3px;">{{comment.comment}}</div> </div>
            </div>
        </div>
    </pv-dialog>
</template>



<script>
    import Dialog from 'primevue/dialog';
    import {formatDateTime} from '../../../../dateTime-tools'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm'],
        components: {
            'pv-dialog': Dialog,
        },
        data() {
            return {
                visible: false,
                comments: []
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            formatDate(value) {
                return formatDateTime(value);
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.comments = await this.$store.dispatch('shop/findComments', newValue.id);
                console.log(this.comments);

            }
        }
    }
</script>