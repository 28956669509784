/* eslint-disable */
import {UserRole, findRole} from "../components/AccessComponent";

export default {
    namespaced: true,

    state: {
        roles: null,
        userData: {
            email: '',
            firstName: '',
            lastName: '',
            agencyId: null
        }
    },

    mutations: {

    },

    actions: {
        role({state}) {
            if (state.roles === null ) {
                return this.dispatch('api/get', {path: '/api/auth/v2/user'}).then(async (user) => {
                    state.roles = user.roles.map(r => findRole(r.name));
                    state.userData.email = user.email;
                    state.userData.firstName = user.firstName;
                    state.userData.lastName = user.lastName;
                    state.userData.agencyId = user.agency ? user.agency.id : null;
                    return state.roles;
                }, () => {
                    state.roles = [UserRole.ANONYMOUS];
                    return state.roles
                });
            }
            return new Promise((resolve) => resolve(state.roles));
        },

        user({state}) {
            if (state.roles === null) {
                return this.dispatch('api/get', {path: '/api/auth/v2/user'}).then(async (user) => {
                    state.roles = user.roles.map(r => findRole(r.name));
                    state.userData.email = user.email;
                    state.userData.firstName = user.firstName;
                    state.userData.lastName = user.lastName;
                    state.userData.agencyId = user.agency ? user.agency.id : null;
                    return state.userData;
                }, () => null );
            }
            return new Promise((resolve) => resolve(state.userData));
        },



        signIn({state}, data) {
            return this.dispatch('api/publicPost', {path: '/api/auth/v2/signin', data: data}).then(async (result) => {
                await this.dispatch('api/saveTicket', result.token);
                state.roles = result.user.roles.map(r => findRole(r.name));
                state.userData.email = result.user.email;
                state.userData.firstName = result.user.firstName;
                state.userData.lastName = result.user.lastName;
                state.userData.agencyId = result.user.agency ? result.user.agency.id : null;
                return true;
            }, () => false );
        },

        recoverPassword({}, data) {
            return this.dispatch('api/publicPost', {path: '/api/auth/recover', data: data}).then(async (result) => {
                return result.status;
            }, () => false );
        },

        changePassword({}, data) {
            return this.dispatch('api/publicPost', {path: '/api/auth/change_password', data: data}).then(async (result) => {
                return result.status;
            }, () => false );
        },


        async signout({state}) {
            await this.dispatch('api/signout');
            state.roles = [UserRole.ANONYMOUS];
            state.userData = {
                email: '',
                firstName: '',
                lastName: ''
            };
            return true;
        },

        checkHash({},hash) {
            return this.dispatch('api/publicPost', {path: '/api/auth/check_hash', data: {hash: hash}}).then(async (result) => {
                return result.status;
            }, () => false );
        }

    }
};