<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Mechanizmy", routerName: 'shopPromotionList', params: {}, query: {}},
                {label: "Edycja mechanizmu"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                dictionares: state => state.dictionary.dictionares
            }),
        },

        data() {
            return {
                formData: {
                    id: null,
                    name: "",
                    description: ""
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'name', label: 'Nazwa', placeholder: 'Wpisz nazwę',
                            tooltip: 'Wpisz nazwę promocji', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 150, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                        {type: 'TEXTAREA', name: 'description', label: 'Opis', placeholder: 'Wpisz opis',
                            tooltip: 'Wpisz opis promocji', info: '', required: false,
                            maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},

                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {



            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('shop/savePromotion', data);
                this.$router.replace({name: 'shopPromotionList'});

            },
            cancelForm() {
                this.$router.replace({name: 'shopPromotionList'});
            }

        },

        async beforeMount() {
            let fData = {
                id: null,
                name: "",
            };
            if (this.$route.params.id > 0) {
                fData = await this.$store.dispatch('shop/findPromotion', this.$route.params.id);
            }
            this.formData = fData;
        }
    }

</script>