<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <pv-toolbar  class="mb-4">

                <template #end>
                    <pv-button label="Monitoring" icon="pi pi-upload"  class=""  @click="monitoringFile()" />
                </template>
            </pv-toolbar>


            <pv-table
                    ref="dt"
                    :value="events"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20, 50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    v-model:selection="selectedRows"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >
                <pv-column selectionMode="multiple" style="width: 3rem; padding: 1rem !important" />
                <pv-column field="planShop.action.name" header="Akcja" style="width: auto" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.shortName" header="Nazwa sklepu" style="width: auto" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.city" header="Miasto" style="width: 100px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.street" header="Ulica" style="width: 200px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="planShop.shop.postcode" header="Kod pocztowy" style="width: 60px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column header="Termin" style="width: 140px" field="dateFrom">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-calendar v-model="filterModel.value"
                                     class="p-column-filter"
                                     dateFormat="dd.mm.yy"
                                     @date-select="filterCallback()"/>
                    </template>
                    <template #body="{data}">
                        {{convertDateToString(data.dateFrom)}}<br/>{{convertDateToString(data.dateTo)}}
                    </template>
                </pv-column>
                <pv-column header="Hostessy" style="width: 150px" field="hostess">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>


                    <template #body="{data}">
                        <p v-for="(ba, index) in data.hostess" :key="index"><span v-if="ba.planBa && ba.deleted ==false">{{ba.planBa.user.firstName}} {{ba.planBa.user.lastName}}</span></p>
                    </template>
                </pv-column>
                <pv-column header="" style="width: 170px; text-align: right">
                    <template #body="{data}">
                        <pv-button v-if="data.planShop.action.actionType == 'EVENT'" icon="pi pi-th-large" class="p-button-rounded p-button-primary me-2" @click="target(data)" />
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>

            </pv-table>
            <button v-if="selectedRows.length > 0" class="btn btn-primary btn-lg me-2" @click="assign()" >Przypisz</button>
            <button v-if="selectedRows.length > 0" class="btn btn-primary btn-lg me-2" @click="removeGroup()" >Usuń</button>
            <AssignBaGroup :visibleDialog="visibleDialog" :dataValue="dataTerm" @closeForm="visibleDialog = false" @saveForm="refreshTable()" />
            <EditTerm :visibleDialog="visibleEditDialog" :dataValue="dataTerm" @closeForm="visibleEditDialog = false" @saveForm="refreshTable()" />
            <TargetTerm :visibleDialog="visibleTargetTermDialog" :dataValue="dataTerm" @closeForm="visibleTargetTermDialog = false" @saveForm="refreshTable()" />
        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Calendar from 'primevue/calendar';
    import ConfirmDialog from 'primevue/confirmdialog';
    import InputText from 'primevue/inputtext'
    import {FilterMatchMode} from 'primevue/api';
    import AssignBaGroup from './dialog/AssignBaGroup.vue'
    import EditTerm from './dialog/EditTerm.vue'
    import TargetTerm from './dialog/TargetTerm.vue'
    import Button from 'primevue/button'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Supervisor", routerName: 'svActionList', params: {}, query: {}},
                {label: "Harmonogram", routerName: 'svScheduleCalendar', params: {}, query: {}},
                {label: "Lista "}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
            'pv-calendar': Calendar,
             AssignBaGroup,
             EditTerm,
             TargetTerm
        },




        data() {
            return {
                visibleEditDialog: false,
                visibleDialog: false,
                visibleTargetTermDialog: false,
                dataTerm: null,
                loading: false,
                events: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'planShop.action.name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.shortName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.city': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.postcode': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'planShop.shop.street': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'hostess': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'dateFrom': {value: '', matchMode: FilterMatchMode.CONTAINS},

                },
                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {
            convertDateToString(dateStr) {
                let date = new Date(dateStr);

                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }) + " " + date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            },


            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        actionName: this.lazyParams.filters['planShop.action.name'].value,
                        shopShortName: this.lazyParams.filters['planShop.shop.shortName'].value,
                        shopCity: this.lazyParams.filters['planShop.shop.city'].value,
                        shopPostcode: this.lazyParams.filters['planShop.shop.postcode'].value,
                        shopStreet: this.lazyParams.filters['planShop.shop.street'].value,
                        hostessName: this.lazyParams.filters['hostess'].value,
                        actionDate: this.lazyParams.filters['dateFrom'].value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder === null ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'dateFrom' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('sv/findTermsByQuery', query);
                this.totalRecords = response.totalElements;
                this.events = response.content;

                this.loading = false;
            },

            assign() {
                this.dataTerm = this.selectedRows;
                this.visibleDialog = true;
            },

            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },

            shopCalendar(item) {
                this.$router.replace({name: 'shopCalendar', params: {id:  item.id}});
            },

            deleteItem(item) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć rekord?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('sv/deleteTerm', item.id);
                        this.refreshTable();
                    },
                });
            },
            removeGroup() {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć wybrane rekordy?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        this.selectedRows.forEach(async (item) => {
                            await this.$store.dispatch('sv/deleteTerm', item.id);
                            this.refreshTable();
                        })
                    },
                });
            },

            edit(item) {
                this.dataTerm = item;
                this.visibleEditDialog = true;
            },

            target(item) {
                this.dataTerm = item;
                this.visibleTargetTermDialog = true;
            },

            async monitoringFile() {
                this.loading = true;
                let response = await this.$store.dispatch('agency/exportBaTerms');
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="monitoring.xlsx";
                link.click();


                this.loading = false;
            },


        },


        watch: {
            filter: {
                handler() {
                    this.refreshTable();
                },
                deep: true
            }
        },


        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>