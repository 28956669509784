<template>
    <div>
        <p>{{question.number}}. {{question.title}}</p>
        <p>
            <textarea disabled  style="resize: none;"/>
        </p>
        <p v-if="question.dependentQuestion" class="question-info">
            Pytanie zależne od pytania: {{question.dependentQuestion.number}}. {{question.dependentQuestion.title}}
        </p>
    </div>
</template>


<script>

    export default {
        props: ['question'],
        emits: [],
        components: {},


    }
</script>