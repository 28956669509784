<template>
    <pv-dialog
            header="Akcja"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        >
            <template v-slot:beforeInputs>
                <div class="position-relative row mb-1">
                    <label class="form-label col-sm-2 col-form-label">Termin</label>
                    <label class="form-label col-sm-10 col-form-label">{{convertDateToString(formData.dateFrom)}} {{convertDateToString(formData.dateTo)}}</label>
                </div>
                <div class="position-relative row mb-1">
                    <label class="form-label col-sm-2 col-form-label">Akcja</label>
                    <label class="form-label col-sm-10 col-form-label">{{formData.planShop.action.name}}</label>
                </div>
                <div class="position-relative row mb-1">
                    <label class="form-label col-sm-2 col-form-label">Sklep</label>
                    <label class="form-label col-sm-10 col-form-label">{{formData.planShop.shop.shortName}}, {{formData.planShop.shop.city}}, {{formData.planShop.shop.street}}, {{formData.planShop.shop.houseNumber}}</label>
                </div>
            </template>

        </ml-form>
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                targets: [],
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {

                await this.$store.dispatch('sv/saveTerm', data);
                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
                this.formData = newValue;

                let baPlans =  await this.$store.dispatch('sv/findBaTargets', newValue.planShop.planSv.id);
                baPlans.forEach(p => p.fullname = p.user.firstName + " " + p.user.lastName);
                this.formData.hostess.forEach(h => h.planBa.fullname = h.planBa.user.firstName + " " + h.planBa.user.lastName);
                let formFields = [

                    {type: 'EDIT_TABLE', name: 'hostess', label: 'Hostessy', maxRows: 5,
                        columns: [
                            {name: 'planBa', header: 'Hostessa', type: 'dropdown', style: 'width: auto', optionLabel: 'fullname', options: baPlans},
                        ],
                        addLabel: 'Dodaj hostessę',
                        addMethod: () => { return {id: null, planBa: null, status: 'PLANNED', deleted: false}},
                        filterMethod: (item) => !item.deleted,
                        removeMethod: (item) => { if(item.id) { item.deleted = true; return true} return null;}
                    },
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            },

            convertDateToString(dateStr) {
                let date = new Date(dateStr);

                return date.toLocaleDateString('pl-Pl', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }) + " " + date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            }


        }
    }
</script>