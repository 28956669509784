<template>
    <form class="app-form">
        <slot name="beforeInputs"></slot>
        <div  v-for="(input,index) in inputs" :key="index">
            <form-input
                    v-model:formInput="inputs[index]"
                    @changeValue="inputChangeValue"/>
        </div>
        <slot name="afterInputs"></slot>
        <button v-if="struct.submitValue" class="btn btn-primary pull-right" @click="submit($event)" >{{struct.submitValue}}</button>
        <button v-if="struct.cancelValue" class="btn btn-outline-primary pull-right me-2" @click="cancel($event)" >{{struct.cancelValue}}</button>
    </form>
</template>

<script>
    import {
        InputText,
        InputEmail,
        InputPassword,
        InputNumber,
        TextArea,
        CheckBox,
        Dropdown,
        Multiselect,
        RadioButtonInline,
        AutoComplte,
        Table,
        EditTable,
        Action,
        Separator,
        Label,
        HtmlEditor,
        Calendar,
        FileUpload
    } from './class/Inputs'
    import {
        Manager,
        InputTextManager,
        InputEmailManager,
        InputPasswordManager,
        InputNumberManager,
        TextAreaManager,
        CheckboxManager,
        DropdownManager,
        MultiselectManager,
        RadioButtonInlineManager,
        AutoCompleteManager,
        TableManager,
        EditTableManager,
        ActionManager,
        SeparatorManager,
        LabelManager,
        HtmlEditorManager,
        CalendarManager,
        FileUploadManager
    } from './class/InputsManager'
    import Input from './Input.vue'


    export default {
        props: ['dataForm', 'struct'],
        emits: ['changeValue', 'submittedForm', 'canceledForm'],
        components: {
            'form-input': Input
        },

        data() {
            return {
                inputs: [],
                manager: new Manager()
            }
        },
        methods: {
            buildInputs() {
                this.inputs = [];
                this.manager.clearInputs();
                for (let field of this.struct.fields) {
                    let value = null;
                    let typeNotValue =['ACTION', 'SEPARATOR', 'LABEL'];

                    if (typeNotValue.indexOf(field.type) < 0) {
                        value = this.manager.findValueFromName(field.name, this.dataForm);
                    }
                    if (field.type == 'TEXT') {
                        let input = new InputText(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new InputTextManager(input));
                    } else if (field.type == 'EMAIL') {
                        let input = new InputEmail(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new InputEmailManager(input));
                    } else if (field.type == 'PASSWORD') {
                        let input = new InputPassword(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new InputPasswordManager(input));
                    } else if (field.type == 'NUMBER') {
                        let input = new InputNumber(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new InputNumberManager(input));
                    } else if (field.type == 'TEXTAREA') {
                        let input = new TextArea(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new TextAreaManager(input));
                    } else if (field.type == 'CHECKBOX') {
                        let input = new CheckBox(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new CheckboxManager(input));
                    } else if (field.type == 'DROPDOWN') {
                        let input = new Dropdown(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new DropdownManager(input));
                    } else if (field.type == 'MULTISELECT') {
                        let input = new Multiselect(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new MultiselectManager(input));
                    } else if (field.type == 'RADIO_BUTTON_INLINE') {
                        let input = new RadioButtonInline(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new RadioButtonInlineManager(input));
                    } else if (field.type == 'AUTOCOMPLETE') {
                        let input = new AutoComplte(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new AutoCompleteManager(input));
                    } else if (field.type == 'TABLE') {
                        let input = new Table(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new TableManager(input));
                    } else if (field.type == 'EDIT_TABLE') {
                        let input = new EditTable(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new EditTableManager(input));
                    } else if (field.type == 'ACTION') {
                        let input = new Action(field, null);
                        this.inputs.push(input);
                        this.manager.addInput(new ActionManager(input));
                    } else if (field.type == 'SEPARATOR') {
                        let input = new Separator(field, null);
                        this.inputs.push(input);
                        this.manager.addInput(new SeparatorManager(input));
                    } else if (field.type == 'LABEL') {
                        let input = new Label(field, null);
                        this.inputs.push(input);
                        this.manager.addInput(new LabelManager(input));
                    } else if (field.type == 'HTML_EDITOR') {
                        let input = new HtmlEditor(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new HtmlEditorManager(input));
                    } else if (field.type == 'CALENDAR') {
                        let input = new Calendar(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new CalendarManager(input));
                    } else if (field.type == 'FILE_UPLOAD') {
                        let input = new FileUpload(field, value);
                        this.inputs.push(input);
                        this.manager.addInput(new FileUploadManager(input));
                    }

                }
            },



            inputChangeValue(data) {
                this.manager.setValue(data.name, data.value);
                this.$emit('changeValue', data);
            },

            submit(event) {
                event.preventDefault();
                if (this.manager.submit()) {
                    this.$emit('submittedForm', this.manager.data(this.dataForm));
                }
            },

            cancel(event) {
                event.preventDefault();
                this.$emit('canceledForm');
            }

        },
        watch: {
            struct: {
                handler(newStruct) {
                    if (newStruct.rebuild) {
                        this.buildInputs();
                        newStruct.rebuild = false;
                    } else {
                        this.manager.updateStruct(newStruct);
                    }
                },
                deep: true
            },
            dataForm: {
                handler(newDataForm) {
                    let typeNotValue =['ACTION', 'SEPARATOR', 'LABEL'];
                    for(let input of this.inputs) {
                        if (typeNotValue.indexOf(input.type) < 0) {
                            input.value = this.manager.findValueFromName(input.name, newDataForm);
                        }
                    }
                },
                deep: true
            }
        },
        beforeMount() {
            this.buildInputs();
        },
        mounted() {
            this.manager.creating = false;
        }

    }
</script>