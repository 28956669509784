<template>
    <div class="q-answer">
        <Camera :visibleCamera="visibleCamera" @changeValue="addLink" />
        <ul v-if="answerObj">
            <li v-for="answer in answerOptions" :key="answer.option.id">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" v-model="selectOption" :value="answer"> {{answer.option.option}}
                </label>
                <div class="option-image" v-if="selectOption && answer.option.id == selectOption.option.id &&  answer.link">
                    <img :src="answer.link" />
                    <button class="btn btn-primary" @click="openCamera()" >Zmień</button>
                </div>
                <pv-textarea
                        v-if="selectOption && answer.option.id == selectOption.option.id && answer.option.extended"
                        class="form-control"
                        style="max-width: 400px;"
                        :autoResize="true"
                        rows="5"
                        cols="30"
                        v-model="answer.extendedDescription"/>
            </li>
        </ul>
    </div>
</template>

<script>

    import Camera from '../../../../camera/template/Camera.vue'
    import TextArea from 'primevue/textarea';

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerOptions: [],
                selectOption: null,
                visibleCamera: false,
                answerObj: null,
                createdData: false
            }
        },


        components: {
            Camera,
            'pv-textarea': TextArea,
        },
        created() {
            this.answerObj = this.answer;
            this.answerOptions = this.answerObj.question.options
                .map(option => {
                    return {
                        option: option,
                        extendedDescription: null,
                        link: null
                    }
                });
            if (this.answerObj.options != null && this.answerObj.options.length > 0) {
                this.selectOption = this.answerOptions.find(a => a.option.id == this.answerObj.options[0].option.id)
                this.selectOption.id = this.answerObj.options[0].id;
                this.selectOption.extendedDescription = this.answerObj.options[0].extendedDescription;
                this.selectOption.link = this.answerObj.options[0].link;
            }
        },

        methods: {
            addLink(resource) {
                if (resource) {
                    this.selectOption.link = resource.url;
                    this.changeSelectedValue();
                }
                this.visibleCamera = false;
            },

            changeSelectedValue()   {
                this.answerObj.options = [this.selectOption];
            },

            openCamera() {
                if (this.visibleCamera) {
                    this.visibleCamera = false;
                }
                this.visibleCamera = true;
            }
        },

        watch: {
            selectOption: {
                handler() {
                    if (this.selectOption) {
                        if (this.selectOption.option.photo && !this.selectOption.link) {
                            this.openCamera();
                        }
                    }
                    this.changeSelectedValue();
                },
                deep: true
            },
            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>