<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <div class="col-sm-12">
            <label class="form-label col-form-label me-2">{{formInput.label}}</label>
        </div>
        <div class="form-separator"></div>

        <div class="col-sm-12">
            <button v-if="!formInput.disabled && (formInput.maxRows == 0 || formInput.maxRows > filterValues().length)"  class="btn btn-secondary" @click="add($event)" >
                <i class="fa fa-plus btn-icon-wrapper"></i>
                {{formInput.addLabel}}
            </button>
        </div>
        <div class="col-sm-12">
            <div class="table-responsive" :class="{'is-invalid': formInput.error, 'is-valid': formInput.validated}">
                <table class="mb-0 table table-striped edit-table">
                    <thead>
                        <tr>
                            <th v-for="col in formInput.columns" :key="col.name" :style="col.style">{{col.header}}</th>
                            <th style="width: 100px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in filterValues()" :key="index">
                            <td v-for="col in formInput.columns" :key="col.name" :style="col.style">
                                <pv-input-text
                                        v-if="col.type == 'text'"
                                        class="form-control"
                                        :disabled="formInput.disabled"
                                        v-model="item[col.name]"/>
                                <pv-dropdown
                                        v-if="col.type == 'dropdown'"
                                        class="form-control"
                                        :disabled="formInput.disabled"
                                        v-model="item[col.name]"
                                        :options="col.options"
                                        :optionLabel="col.optionLabel"
                                        style="padding: 0"
                                />
                                <pv-input-number
                                        v-if="col.type == 'number'"
                                        style="width: 100%"
                                        :disabled="formInput.disabled"
                                        mode="decimal"
                                        :minFractionDigits="col.minFractionDigits"
                                        :maxFractionDigits="col.maxFractionDigits"
                                        :locale="col.locale"
                                        :suffix="col.suffix"
                                        :prefix="col.prefix"
                                        :min="col.min"
                                        :max="col.max"
                                        :useGrouping="col.grouping"
                                        v-model="item[col.name]"/>
                                <pv-checkbox
                                        v-if="col.type == 'checkbox'"
                                        class="col-form-label"
                                        :disabled="formInput.disabled"
                                        v-model="item[col.name]"
                                        :binary="true"/>
                            </td>
                            <td style="width: 100px">
                                <button v-if="!formInput.disabled" class="mb-2 mr-2 btn-icon btn-icon-only btn btn-danger" @click="deleteItem($event, item)">
                                    <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>



        <div class="col-sm-2"></div>
        <div class="col-sm-10"><small class="form-text text-muted" v-if="formInput.info">{{formInput.info}}</small></div>

    </div>
</template>


<script>

    import InputText from 'primevue/inputtext';
    import CheckBox from 'primevue/checkbox';
    import Dropdown from 'primevue/dropdown';
    import InputNumber from 'primevue/inputnumber';

    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-input-text': InputText,
            'pv-checkbox': CheckBox,
            'pv-dropdown': Dropdown,
            'pv-input-number': InputNumber,
        },
        data() {
            return {
                value: [],
                filtered: [],
            }
        },

        methods: {
            add(event) {
                event.preventDefault();
                this.value.push(this.formInput.addMethod());
            },

            filterValues() {
                return this.value.filter(this.formInput.filterMethod);
            },


            deleteItem(event, item) {
                event.preventDefault();
                if (this.formInput.removeMethod(item) == null) {
                    let index = this.value.indexOf(item);
                    this.value = this.value.splice(index, 1);
                }
            },




        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                    this.filtered = [];
                    for(var i=0; i<this.value.length; i++ ) {
                        this.filtered.push({value: null});
                    }
                },
                deep: true
            }
        }

    }
</script>