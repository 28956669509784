<template>
    <pv-dialog
            header="Aparat"
            :modal="true" :breakpoints="{'960px': '80vw', '767px': '100vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
            <div class="wrapper">
                <div class="video-container">
                    <video v-show="isCameraOpen" class="camera-video" ref="camera" style="width: 100%;" autoplay playsinline ></video>
                </div>
                <div class="buttons" style="position: relative;width: 100%;">
                    <button v-if="videoDevices.length > 0" class="mb-2 mr-2 btn-icon btn-icon-only btn btn-primary btn-lg" @click="takePhoto" style="margin: 0 auto; margin-bottom: 0; display: block;">
                        <i class="fa fa-camera-retro btn-icon-wrapper"> </i>
                    </button>
                    <button v-if="videoDevices.length > 1" class="mb-2 mr-2 btn-icon btn-icon-only btn btn-primary btn-lg pull-right" @click="switchCamera()" style="position: absolute; right: 0; top: 0;">
                        <i class="pe-7s-refresh-2 btn-icon-wrapper"> </i>
                    </button>
                </div>
                <canvas ref="canvas" width="1200" height="800" style="display: none"></canvas>
            </div>
    </pv-dialog>
</template>

<script>

    import Dialog from 'primevue/dialog';

    export default {
        name: 'Camera',
        props: ['visibleCamera'],
        emits: ['changeValue'],
        data() {
            return {
                visible: false,
                isCameraOpen: false,
                isPhotoTaken: false,
                videoDevices: [],
                selectedDeviceIndex: 0
            }
        },
        components: {
            'pv-dialog': Dialog,
        },

        methods: {
            switchCamera() {
                if (this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.stopCameraStream();
                }
                this.selectedDeviceIndex++;
                if (this.selectedDeviceIndex >= this.videoDevices.length) {
                    this.selectedDeviceIndex = 0;
                }
                this.toggleCamera();
            },


            createCameraElement () {
                if (this.videoDevices.length > 0) {
                    const constraints = {
                        audio: false,
                        video: {
                            deviceId: {exact: this.videoDevices[this.selectedDeviceIndex].deviceId}
                        }
                    };
                    navigator.mediaDevices
                        .getUserMedia(constraints)
                        .then(stream => {
                            this.$refs.camera.srcObject = stream
                        })
                        .catch(error => {
                            alert("May the browser didn't support or there is some errors. " + error)
                        })
                }
            },
            stopCameraStream () {
                if (this.$refs.camera) {
                    const tracks = this.$refs.camera.srcObject.getTracks()
                    tracks.forEach(track => {
                        track.stop()
                    })
                }
            },
            toggleCamera () {
                if (this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.stopCameraStream()
                } else {
                    this.isCameraOpen = true;
                    this.createCameraElement()
                }
            },
            async takePhoto () {
                //this.isPhotoTaken = !this.isPhotoTaken
                const canvas = this.$refs.canvas;
                let photoFromVideo = this.$refs.camera;
                canvas.getContext('2d').drawImage(photoFromVideo, 0, 0, canvas.width, canvas.height);
                let image_data_url = canvas.toDataURL('image/jpeg');
                let base64result = image_data_url.substr(image_data_url.indexOf(',') + 1);
                let response = await this.$store.dispatch('ba/upload', {data: base64result, extension: 'jpg', filename: 'photo.jpg'});
                this.toggleCamera ();
                this.visible = false;
                this.$emit('changeValue', response);
            },


            async cameraSelection() {
                let devices = await navigator.mediaDevices.enumerateDevices();
                this.videoDevices = devices.filter(device => device.kind === 'videoinput');
            }

        },

        watch: {
            visibleCamera() {
                this.visible = this.visibleCamera;
                console.log(this.visible, this.isCameraOpen);
                if (this.visible && !this.isCameraOpen) {
                    this.toggleCamera();
                } else if (!this.visible && this.isCameraOpen) {
                    this.toggleCamera();
                }

            }
        },

        mounted() {
            if (this.videoDevices.length) {
                this.visible = this.visibleCamera;
                if (this.visible && !this.isCameraOpen) {
                    this.toggleCamera();
                }
            }
        },

        async created() {
            await this.cameraSelection();
            this.visible = this.visibleCamera;
            if (this.visible && !this.isCameraOpen) {
                this.toggleCamera();
            }
        },
    }
</script>

<style>
    .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        background-color: white;
    }

    .video-container {
        width: 100%;
    }
</style>