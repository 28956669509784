<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div v-if="importing">
                <h5 class="font-bold">Trwa import danych ({{bar.current}} z {{bar.count}})</h5>
                <pv-progress-bar  :value="bar.percent" class="mb-2"/>
            </div>

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj wartość" icon="pi pi-plus" class="p-button-success me-2" @click="create()"  />
                </template>

                <template #end>

                </template>
            </pv-toolbar>
            <pv-table
                    ref="dt"
                    :value="dictionaryValues"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"



            >

                <pv-column field="value" header="Nazwa" style="width: auto">
                </pv-column>


                <pv-column field="active" header="Aktywny" dataType="boolean" style="width: 100px">
                    <template #body="{data}">
                        <i class="pi" :class="{'true-icon pi-check-circle': data.active, 'false-icon pi-times-circle': !data.active}"></i>
                    </template>
                </pv-column>
                <pv-column  style="width: 180px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button v-if="!data.active" icon="pi pi-check-circle" class="p-button-rounded p-button-success me-2" @click="active(data)" />
                        <pv-button v-if="data.active" icon="pi pi-times-circle" class="p-button-rounded p-button-info me-2" @click="inactive(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import ProgressBar from 'primevue/progressbar';
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'



    export default {
         components: {
            Breadcrumbs,
            'pv-progress-bar': ProgressBar,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,

        },



        data() {

            return {
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
                dictionaryValues: [],
                breadcrumpsList: null
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'dictionaryValueAdd', params: {dictionaryId: this.$route.params.id}});
            },

            edit(item) {
                this.$router.replace({name: 'dictionaryValueEdit', params: {dictionaryId: this.$route.params.id, id: item.id}});
            },

            async active(item) {
                await this.$store.dispatch('dictionary/statusDictionaryValue', {ids: [item.id], active: true});
                this.refreshTable();
            },

            async inactive(item) {
                await this.$store.dispatch('dictionary/statusDictionaryValue', {ids: [item.id], active: false});
                this.refreshTable();
            },

            async deleteItem(item) {
                await this.$store.dispatch('dictionary/deleteDictionaryValue', item.id);
                this.refreshTable();
            },

            async refreshTable() {
                this.dictionaryValues = await this.$store.dispatch('dictionary/findDictionaryValues', this.$route.params.id);
                this.loading = false;
            },




        },

        async beforeMount() {
            this.loading = true;

            let dictionares = await this.$store.dispatch('dictionary/findDictionares');
            let data = dictionares.find(d => d.id == this.$route.params.id);
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Słowniki", routerName: 'dictionaryList', params: {}, query: {}},
                {label: data ? data.name : ''}
            ];


            this.refreshTable();
        },

    }
</script>