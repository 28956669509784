<template>
    <div v-if="formInput.visibility && !formInput.disabled" class="position-relative row mb-3">
        <div v-if="formInput.label" class="col-sm-2">
            <label class="form-label col-form-label me-2">{{formInput.label}}</label>
        </div>
        <div :class="{'col-sm-10': formInput.label, 'col-sm-12': !formInput.label}">
            <pv-button :label="formInput.buttonText"
                       v-tooltip.top="formInput.tooltip"
                       :icon="formInput.buttonIcon"
                       class="p-button-sm"
                       :class="bclass"
                       @click="callAction()"  />
        </div>
    </div>
</template>


<script>

    import Button from 'primevue/button';
    import Tooltip from 'primevue/tooltip';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-button': Button,
        },
        computed: {
           bclass() {
             return this.formInput ? (this.formInput.buttonClass + (this.formInput.buttonPosition == 'right' ? " pull-right" : "")) : ''
           }
        },
        directives: {
            'tooltip': Tooltip
        },
        methods: {
            callAction() {
                this.formInput.callAction(this.formInput.params);
            }
        }


    }
</script>