<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeShop"
            />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import {updateValue} from '../../../form/ml/form/class/InputsManager'

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Lista Sklepów", routerName: 'shopList', params: {}, query: {}},
                {label: "Edycja sklepu"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                dictionares: state => state.dictionary.dictionares
            }),
        },

        data() {
            return {
                traders: [],

                formData: null,
                formStruct: {fields: []},


            }
        },


        methods: {

            async changeShop(data) {
                updateValue(this.formData, data);
            },

            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('shop/saveShop', data);
                this.$router.replace({name: 'shopList'});

            },
            cancelForm() {
                this.$router.replace({name: 'shopList'});
            }

        },

        async beforeMount() {

            let formFields = [
                {type: 'NUMBER', name: 'externalId', label: 'Id', placeholder: 'Id', disabled: true,
                    tooltip: 'Wpisz id', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                {type: 'NUMBER', name: 'rsm', label: 'RSM', placeholder: 'RSM', disabled: true,
                    tooltip: 'Wpisz RSM', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                {type: 'NUMBER', name: 'asm', label: 'ASM', placeholder: 'ASM', disabled: true,
                    tooltip: 'Wpisz ASM', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                {type: 'NUMBER', name: 'ptorg', label: 'PTORG', placeholder: 'PTORG', disabled: true,
                    tooltip: 'Wpisz PTORG', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                {type: 'TEXT', name: 'trader.name', label: 'Handlowiec', placeholder: 'Handlowiec', disabled: true,
                    tooltip: '', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                {type: 'TEXT', name: 'name', label: 'Nazwa', placeholder: 'Wpisz nazwę', disabled: true,
                    tooltip: 'Wpisz nazwę sklepu', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                {type: 'TEXT', name: 'shortName', label: 'Nazwa skrócona', placeholder: 'Wpisz nazwę skróconą', disabled: true,
                    tooltip: 'Wpisz skróconą nazwę sklepu', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 50, maxLengthMessage: 'Pole może mieć maksymalnie 50 znaków'},
                {type: 'TEXT', name: 'houseNumber', label: 'Numer budynku', placeholder: 'Wpisz nr budynku', disabled: true,
                    tooltip: 'Wpisz nr budynku', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                {type: 'TEXT', name: 'postcode', label: 'Kod pocztowy', placeholder: 'Wpisz kod pocztowy', disabled: true,
                    tooltip: 'Wpisz kod pocztowy', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 50, maxLengthMessage: 'Pole może mieć maksymalnie 50 znaków'},
                {type: 'TEXT', name: 'city', label: 'Miasto', placeholder: 'Wpisz miasto', disabled: true,
                    tooltip: 'Wpisz miasto', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                {type: 'TEXT', name: 'nip', label: 'NIP', placeholder: 'Wpisz NIP', disabled: true,
                    tooltip: 'Wpisz NIP', info: '',
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 20, maxLengthMessage: 'Pole może mieć maksymalnie 20 znaków'},
                {type: 'TEXT', name: 'phone', label: 'Telefon', placeholder: 'Wpisz telefon',
                    tooltip: 'Wpisz telefon', info: '', disabled: true,
                    required: true,  requiredMessage: 'Pole jest wymagane',
                    maxLength: 20, maxLengthMessage: 'Pole może mieć maksymalnie 20 znaków'},

            ];

            let fData = {
                id: null,
                externalId: null,
                rsm: null,
                asm: null,
                ptorg: null,
                trader: null,
                name: '',
                shortName: '',
                street: '',
                houseNumber: '',
                postcode: '',
                city: '',
                nip: '',
                phone: '',

            };
            if (this.$route.params.id > 0) {
                fData = await this.$store.dispatch('shop/findShop', this.$route.params.id);
                console.log(fData);

            }


            this.formData = fData;
            this.formStruct = {
                rebuild: true,
                fields: formFields,
                cancelValue: 'Zamknij',
                submitValue: ''
            };
        }
    }

</script>