import {UserRole, AccessType} from "./components/AccessComponent";

function createSettings(application) {
    application.config.globalProperties.$setting = {
        architectLayout: {
            topMenu: [
                {name: "ITPL", icon: "fa fa-industry", routerName: "shopList", menuId: 5000,
                    access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                {name: "Agencja", icon: "pi pi-globe", routerName: "agencyShopList", menuId: 4000,
                    access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE}},
                {name: "Supervisor", icon: "pi pi-id-card", routerName: "svActionList", menuId: 3000,
                    access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},
                {name: "Prezentacje", icon: "pe-7s-display2", routerName: "harmonogramPlanList", menuId: 8000,
                    access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}},
                {name: "Administracja", icon: "fa fa-cog", routerName: "dictionaryList", menuId: 1000,
                    access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
            ],
            menu: {
                itpl: [
                    {id: 5000, header: true, title: 'Sklepy', access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                    {id: 5001, header: false, title: 'Lista sklepów', icon: 'fa fa-store', active: true,
                        routerName: 'shopList', access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                    {id: 5003, header: false, title: 'Akcje', icon: 'fa fa-bell', active: true,
                        routerName: 'shopActionList', access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                    {id: 5004, header: false, title: 'Mechanizmy', icon: 'fa fa-percentage', active: true,
                        routerName: 'shopPromotionList', access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                    {id: 5005, header: false, title: 'Gadżety', icon: 'fa fa-gift', active: true,
                        routerName: 'giftList', access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}},
                ],


                agency: [
                    {id: 4000, header: true, title: 'Agencja', access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}},
                    {id: 4001, header: false, title: 'Sklepy', icon: 'fa fa-store', active: true,
                        routerName: 'agencyShopList', access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}},
                    {id: 4002, header: false, title: 'Akcje', icon: 'fa fa-bell', active: true,
                        routerName: 'agencyActionList', access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}},
                    {id: 4003, header: false, title: 'Harmonogram', icon: 'pi pi-calendar', active: true,
                        access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE},
                        children: [
                            {id: 4004, title: 'Tabela', active: true, routerName: 'agencyScheduleTable',
                                access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE}},
                            {id: 4005, title: 'Kalendarz', active: true, routerName: 'agencyScheduleCalendar',
                                access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE}}
                        ]
                    },
                    {id: 4004, header: false, title: 'Supervisor', icon: 'fa fa-users', active: true,
                        routerName: 'agencyUserList', access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}},
                ],
                sv: [
                    {id: 6000, header: true, title: 'Supervisor', access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},
                    {id: 6001, header: false, title: 'Akcje', icon: 'fa fa-bell', active: true,
                        routerName: 'svActionList', access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},
                    {id: 6002, header: false, title: 'Harmonogram', icon: 'pi pi-calendar', active: true,
                        access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE},
                        children: [
                            {id: 6003, title: 'Kalendarz', active: true, routerName: 'svScheduleCalendar',
                                access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},
                            {id: 6004, title: 'Tabela', active: true, routerName: 'svScheduleTable',
                                access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},                        ]
                    },
                    {id: 6005, header: false, title: 'Pracownicy', icon: 'fa fa-users', active: true,
                        routerName: 'svUserList', access: {roles: [UserRole.SV], type: AccessType.DIRECT_ROLE}},
                ],

                ba: [
                    {id: 8000, header: true, title: 'Prezentacje', access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}},
                    {id: 8001, header: false, title: 'Dashboard', icon: 'pe-7s-display2', active: true,
                        routerName: 'baDashboard', access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}},
                    {id: 8002, header: false, title: 'Akcje', icon: 'fa fa-bell', active: true,
                        routerName: 'baActionList', access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}},
                    {id: 8003, header: false, title: 'Harmonogram', icon: 'pi pi-calendar', active: true,
                        routerName: 'baCalendar', access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}},

                ],



                admin: [
                    {id: 1000, header: true, title: 'Administracja', access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                    {id: 1100, header: false, title: 'Użytkownicy', active: true, open: true, icon: 'fa fa-users',
                        access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL},
                        children:[
                            {id: 1101, title: 'Lista', active: true, routerName: 'usersList',
                                access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                        ]
                    },

                    {id: 1200, header: false, title: 'Słowniki', active: true, open: true, icon: 'fa fa-book',
                        access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL},
                        children:[
                            {id: 1201, title: 'Definicje', active: true, routerName: 'dictionaryList',
                                access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                        ]
                    },
                    {id: 1300, header: false, title: 'Maile', active: true, open: true, icon: 'fa fa-envelope',
                        access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL},
                        children:[

                            {id: 1301, title: 'Szablony', active: true, routerName: 'messageTemplateList',
                                access: {roles: [UserRole.ADMIN], type: AccessType.LEVEL}},
                        ]
                    },


                ]
            },
            redirectMainPage: 'signIn',
        },





        auth: {
            labels: {
                signInTitle: 'Logowanie',
                recoverPasswordTitle: 'Odzyskaj hasło',
                changePasswordTitle: 'Zmiana hasła',
                username: 'email',
                password: 'hasło',
                repeatPassword: 'powtórz hasło',
                email: 'email',
                submitTitle: 'Zaloguj się',
                recoverPasswordSubmit: 'Wyślij',
                changePasswordSubmit: 'Zmień',
                forgotPassword: 'Zapomniałeś hasła?',
                goToSignInPage: 'Zaloguj się',
                recoverInfo: 'Wpisz poniżej swój adres e-mail, a wyślemy Tobie instrukcje do zmiany hasła!'
            },
            logo: require('@/assets/images/logo-inverse.png'),
            logoRedirectName: 'signIn',
            redirectSignIn: 'redirectPage',
            invalidAuthTitle: 'Błąd autoryzacji',
            invalidAuthMessage: 'Nieprawidłowy login lub hasło',
            invalidEmailTitle: 'Nieprawidłowy e-mail',
            invalidEmailMessage: 'Podany adres email nie został znaleziony w naszym serwisie',
            recoverSuccess: 'Wiadomość wysłana',
            recoverSuccessMessage: 'Na podany adres email została wysłana do Ciebie instrukcja do zmiany hasła',

            validatePasswordTitle: 'Nieprawidłowe hasło',
            comparePasswordsMessage: 'Hasła muszą być takie same',
            patternPasswordMessage: 'Hasło jest zbyt słabe',
            patternPassword: 'medium',
            changePasswordError: 'Błąd zmiany hasła',
            changePasswordSuccess: 'Hasło zostało zmienione',
            changePasswordSuccessMessage: 'Możesz się teraz zalogować'
        }



    };
}

export {
    createSettings
}