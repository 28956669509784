<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <h3>Kaledarz dla sklepu
                <span v-if="plan">{{plan.shop.name}}
                    <span style="display: block;font-size: 0.7em;color: #888;">{{plan.shop.city}}, {{plan.shop.street}} {{plan.shop.houseNumber}}</span>
                </span>
            </h3>
            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
        <ShopTerm :visibleDialog="visibleDialog" :dataValue="dataTerm" @closeForm="visibleDialog = false" @saveForm="refreshForce()" />
    </div>
</template>


<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import FullCalendar from '@fullcalendar/vue3'
    import DayGridPlugin from '@fullcalendar/daygrid'
    import InteractionPlugin from '@fullcalendar/interaction'
    import ListPlugin from '@fullcalendar/list'
    import TimeGridPlugin from '@fullcalendar/timegrid'
    import plLocale from '@fullcalendar/core/locales/pl';
    import ShopTerm from "./dialog/ShopTerm.vue"
    import ConfirmDialog from 'primevue/confirmdialog';

    export default {

        setup() {
          const colors = ['#7B1FA2','#C2185B','#D32F2F','#1976D2','#00796B','#303F9F','#0097A7', '#6D4C41',
              '#512DA8', '#388E3C', '#0288D1', '#AFB42B', '#689F38', '#F57C00', '#616161', '#FBC02D', '#E64A19'];
            return {
                colors
            }

        },


        data() {
            return {
                visibleDialog: false,
                dataTerm: null,
                plan: null,
                breadcrumpsList: [],
                activeStart: null,
                activeEnd: null,
                calendarOptions: {
                    plugins: [ DayGridPlugin, InteractionPlugin, ListPlugin, TimeGridPlugin ],
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    },
                    locale: plLocale,
                    editable: true,
                    selectable: true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    weekends: true,
                    select: this.handleDateSelect,
                    eventClick: this.handleDateClick,
                    eventsSet: this.refresh,
                    events: []
                }
            }
        },


        components: {
            Breadcrumbs,
            FullCalendar,
            ShopTerm,
            'pv-confirm-dialog': ConfirmDialog,

        },

        methods: {
            handleDateSelect(selectInfo) {
                let end = selectInfo.allDay ? new Date(selectInfo.end.getTime()  - 25200000) : selectInfo.end;
                let start = selectInfo.allDay ? new Date(selectInfo.start.getTime()  + 25200000) : selectInfo.start;

                this.dataTerm = {
                    plan: this.plan,
                    activeStart: start,
                    activeEnd: end,
                };
                this.visibleDialog = true;

            },

            handleDateClick: function(clickInfo) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć akcje?',
                    header: 'Usuwaie akcji',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('sv/deleteTerm', clickInfo.event.id);
                        clickInfo.event.remove()
                    },
                });
            },

            async refresh() {
                let currentActiveStart = this.$refs.fullCalendar.calendar.view.activeStart;
                let currentActiveEnd = this.$refs.fullCalendar.calendar.view.activeEnd;
                if(this.plan && (this.activeStart == null ||this.activeEnd == null
                    || this.activeStart.getTime() != currentActiveStart.getTime()
                    || this.activeEnd.getTime() != currentActiveEnd.getTime())) {
                    this.activeStart = currentActiveStart;
                    this.activeEnd = currentActiveEnd;
                    let query = {
                        shopId: this.plan.shop.id,
                        startActive: this.activeStart,
                        endActive: this.activeEnd
                    };
                    let events = await this.$store.dispatch('sv/findTermsByShop', query);

                    let calendarApi = this.$refs.fullCalendar.getApi();
                    calendarApi.removeAllEvents();
                    console.log(events);
                    events.forEach(ev => {
                        calendarApi.addEvent({
                            id: ev.id,
                            title: ev.planShop.action.name,
                            start: ev.dateFrom,
                            end: ev.dateTo,
                            allDay: false,
                            color: this.selectColor(ev.planShop.action)
                        })
                    });

                }
            },

            async refreshForce() {
                let query = {
                    shopId: this.plan.shop.id,
                    startActive: this.activeStart,
                    endActive: this.activeEnd
                };
                let events = await this.$store.dispatch('sv/findTermsByShop', query);

                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.removeAllEvents();
                events.forEach(ev => {
                    calendarApi.addEvent({
                        id: ev.id,
                        title: ev.planShop.action.name,
                        start: ev.dateFrom,
                        end: ev.dateTo,
                        allDay: false,
                        color: this.selectColor(ev.planShop.action)
                    })
                });
            },

            selectColor(action) {
                let index = action.id % this.colors.length;
                return this.colors[index];
            }

        },



        async beforeMount() {
            this.plan = await this.$store.dispatch('agency/findPlan', this.$route.params.id);
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Akcje", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Lista sklepów", routerName: 'agencyActionPlans', params: {id: this.plan.action.id}, query: {}},
                {label: "Kalendarz"}];
            this.refresh()
        }


    }
</script>