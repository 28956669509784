<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar v-if="enableAction" class="mb-4">

                <template #start>
                    <pv-button label="Dodaj" icon="pi pi-plus" class="p-button-primary me-2" @click="create()"  />
                </template>

                <template #end>

                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="users"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"

                    v-model:filters="filters"
                    filterDisplay="row"

            >

                <pv-column field="email" header="Email" style="width: 300px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="firstName" header="Imię" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="lastName" header="Nazwisko" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>



                <pv-column  style="width: 170px">
                    <template #body="{data}">
                        <pv-button icon="fa fa-users" class="p-button-rounded p-button-primary me-2" @click="ba(data)" />
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                        <pv-button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Supervisor"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,

        },

        computed: {
            ...mapState({
                users: state => state.user.users
            }),
        },


        data() {
            return {
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'email': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'firstName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'lastName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },
            }
        },


        methods: {

            create() {
                this.$router.replace({name: 'agencyUserAdd'});
            },

            ba(item) {
                this.$router.replace({name: 'agencyBaList', params: {id: item.id}});
            },

            edit(item) {
                this.$router.replace({name: 'agencyUseEdit', params: {id: item.id}});
            },

            async deleteItem(item) {
                this.$confirm.require({
                    message: 'Czy chcesz usunąć rekord?',
                    header: 'Usuwaie supervisora',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async() => {
                        await this.$store.dispatch('user/deleteUser', item.id);
                        this.refreshTable();
                    },
                });
            },



            async refreshTable() {
                this.$store.dispatch('user/findUsers');
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>