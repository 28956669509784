<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div v-if="importing">
                <h5 class="font-bold">Trwa import kodów rabatowych ({{bar.current}} z {{bar.count}})</h5>
                <pv-progress-bar  :value="bar.percent" class="mb-2"/>
            </div>


            <pv-toolbar  class="mb-4">

                <template #end>
                    <input type="file" accept=".csv" ref="importFile" style="display: none" @change="importFile($event)">
                    <pv-button label="Import" icon="pi pi-download"  class="me-2 inline-block"  @click="$refs.importFile.click()" />
                </template>
            </pv-toolbar>

            <pv-table
                    ref="dt"
                    :value="codes"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20, 50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >
                <pv-column field="couponId" header="Id" style="width: 250px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="code" header="Kod" style="width: 250px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>


                <pv-column field="actionName" header="Nazwa akcji" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="messageId" header="Message id" style="width: 250px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column  header="Status" style="width: 250px">
                    <template #body="{data}">
                        <span v-if="!data.pollCode">gotowy do użycia</span>
                        <span v-if="data.pollCode && data.pollCode.status == 'LOCK' ">zablokowany</span>
                        <span v-if="data.pollCode && data.pollCode.status == 'EXECUTED' ">wykorzystany</span>
                    </template>
                </pv-column>


            </pv-table>
        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import {FilterMatchMode} from 'primevue/api';
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import Message from '@miro-labs/vue3-message/Message'
    import ProgressBar from 'primevue/progressbar';

    export default {

        components: {
            Breadcrumbs,
            'pv-toolbar': Toolbar,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-button': Button,
            'pv-progress-bar': ProgressBar,
        },




        data() {
            return {
                breadcrumpsList: [],
                actionId: null,
                promotionId: null,

                filters: {
                    'code': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'couponId': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'actionName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'messageId': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },

                loading: false,
                codes: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,

                lazyParams: {},
                selectedRows: [],
                bar: {
                    current: 0,
                    count: 0,
                    percent: 0
                }
            }
        },


        methods: {


            async importFile(event) {
                var file = event.target.files[0];
                if (!file) {
                    return;
                }
                var reader = new FileReader();
                reader.onload = (e) => this.importData(e.target.result);
                reader.readAsText(file);
            },

            async importData(data) {
                let lines =  data.split(/(?:\r\n|\n)+/);
                lines.shift();
                let all  = lines.length;
                this.bar = {current: 0,  count: all, percent: 0};
                this.importing = true;
                while(lines.length > 0) {
                    let row = lines.shift();
                    await this.importRow(row.split(';'));
                    let index = all -  lines.length;
                    this.bar.current = index;
                    this.bar.percent = Math.round((index / all) * 100);
                }
                this.$store.dispatch("message/show", [Message.INFO, '', 'Import', 'Kody zostały zaimportowane']);
                this.importing = false;
                this.refreshTable();
            },

            async importRow(data) {
                let code = {
                    action: {id: this.actionId},
                    promotion: {id: this.promotionId},
                    code: data[0],
                    actionName: data[1],
                    messageId: data[2],
                    couponState: data[3],
                    couponId: data[4],
                    changeAt: new Date(data[5])
                };
                await this.$store.dispatch('shop/saveCode', code);
            },


            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        actionId: this.actionId,
                        promotionId: this.promotionId,
                        code: this.lazyParams.filters.code.value,
                        couponId: this.lazyParams.filters.couponId.value,
                        actionName: this.lazyParams.filters.actionName.value,
                        messageId: this.lazyParams.filters.messageId.value
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder === null ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'id' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('shop/findCodes', query);
                this.totalRecords = response.totalElements;
                this.codes = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },


        },


        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

        async beforeMount() {
            this.actionId = this.$route.params.actionId;
            this.promotionId = this.$route.params.promotionId;
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Akcje", routerName: 'shopActionList', params: {}, query: {}},
                {label: "Edycja akcji", routerName: 'shopActionEdit', params: {id: this.actionId}, query: {}},
                {label: "Kody rabatowqe"}
            ];
        }

    }
</script>