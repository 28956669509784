<template>
    <pv-dialog
            header="Termin akcji"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                targets: [],
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                if (value.name == 'timeStart') {
                    this.formData.timeEnd =  new Date(value.value.getTime() + 14400000);
                }
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                let startTimeString = data.timeStart.toLocaleTimeString('pl-Pl');
                let endTimeString = data.timeEnd.toLocaleTimeString('pl-Pl');
                let timezoneOffset = data.dateStart.getTimezoneOffset() * 60000;
                let currentDate = new Date(Date.parse(new Date(data.dateStart.getTime() - timezoneOffset).toISOString().slice(0,10)));
                let dateEnd = new Date(Date.parse(new Date(data.dateEnd.getTime() - timezoneOffset).toISOString().slice(0,10)));

                let terms = [];
                while(currentDate.getTime() <= dateEnd.getTime()) {
                    terms.push({
                        planShop: data.plan,
                        dateFrom: new Date(Date.parse(currentDate.toISOString().slice(0,10) + "T" + startTimeString)).toISOString(),
                        dateTo: new Date(Date.parse(currentDate.toISOString().slice(0,10) + "T" + endTimeString)).toISOString()
                    });
                    currentDate = new Date(currentDate.getTime() + 86400000);
                }
                if (terms.length) {
                    await this.$store.dispatch('sv/saveTermList', terms);
                }


                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
                this.formData = {
                    plan: newValue.plan,
                    dateStart: newValue.activeStart,
                    timeStart: newValue.activeStart,
                    dateEnd: newValue.activeEnd,
                    timeEnd: newValue.activeEnd,
                };

                let minDate = new Date(Date.parse(newValue.plan.action.dateFrom));
                let maxDate = new Date(Date.parse(newValue.plan.action.dateTo));

                if (minDate.getTime() > this.formData.dateStart.getTime()) {
                    this.formData.dateStart = minDate;
                }
                if (maxDate.getTime() < this.formData.dateStart.getTime()) {
                    this.formData.dateStart = minDate;
                }

                if (maxDate.getTime() < this.formData.dateEnd.getTime()) {
                    this.formData.dateEnd = maxDate;
                }
                if (minDate.getTime() > this.formData.dateEnd.getTime()) {
                    this.formData.dateEnd = maxDate;
                }

                let formFields = [
                    {type: 'LABEL', label: 'Akcja: ' + newValue.plan.action.name},
                    {type: 'CALENDAR', name: 'dateStart', label: 'Data od', tooltip: '', info: '',
                        minDate: new Date(Date.parse(newValue.plan.action.dateFrom)),
                        maxDate: new Date(Date.parse(newValue.plan.action.dateTo)),
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'CALENDAR', name: 'dateEnd', label: 'Data do', tooltip: '', info: '',
                        minDate: new Date(Date.parse(newValue.plan.action.dateFrom)),
                        maxDate: new Date(Date.parse(newValue.plan.action.dateTo)),
                        required: true,  requiredMessage: 'Pole jest wymagane'},
                    {type: 'CALENDAR', name: 'timeStart', label: 'Od godziny', tooltip: '', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane', timeOnly: true},
                    {type: 'CALENDAR', name: 'timeEnd', label: 'Do godziny', tooltip: '', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',  timeOnly: true},
                ];




                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>