<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <pv-toolbar  class="mb-4">

                <template #end>
                    <input type="file" accept=".xlsx" ref="importFile" style="display: none" @change="importFile($event)">
                    <pv-button label="Import" icon="pi pi-download"  class="me-2 inline-block"  @click="$refs.importFile.click()" />
                    <pv-button label="Export" icon="pi pi-upload"  class=""  @click="exportFile()" />
                </template>
            </pv-toolbar>


            <div class="filter-card">
                <div class="filter-item p-fluid">
                    <h5>Id</h5>
                    <pv-chips v-model="filter.listId" separator="," :max="50"/>
                </div>
                <div class="filter-item p-fluid">
                    <h5>Nazwa skrócona</h5>
                    <pv-input-text v-model="filter.shortName" />
                </div>

                <div class="filter-item p-fluid">
                    <h5>Miasto</h5>
                    <pv-input-text v-model="filter.city" />
                </div>
                <div class="filter-item p-fluid">
                    <h5>Ulica</h5>
                    <pv-input-text v-model="filter.street" />
                </div>
                <div class="filter-item p-fluid">
                    <h5>Nip</h5>
                    <pv-input-text v-model="filter.nip" />
                </div>
                <div class="filter-item p-fluid">
                    <h5>Kategoria aktywacyjna</h5>
                    <pv-input-text v-model="filter.categoryActivation" />
                </div>
                <div class="filter-item p-fluid">
                    <h5>Sieć</h5>
                    <pv-input-text v-model="filter.chainStore" />
                </div>
                <div class="filter-item p-fluid">
                    <h5>Kanał</h5>
                    <pv-input-text v-model="filter.channel" />
                </div>

            </div>



            <pv-table
                    ref="dt"
                    :value="shops"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="50"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:selection="selectedRows"
                    filterDisplay="false"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >
                <pv-column selectionMode="multiple" headerStyle="width: 3rem; padding: 1rem !important" />
                <pv-column field="shop.externalId" header="Id" style="width: 70px" />
                <pv-column field="shop.name" header="Nazwa" style="width: auto" />
                <pv-column field="shop.shortName" header="Nazwa skrócona" style="width: 150px" />
                <pv-column field="shop.nip" header="NIP" style="width: 80px" />
                <pv-column field="shop.city" header="Miasto" style="width: 100px" />
                <pv-column field="shop.street" header="Ulica" style="width: 200px" />
                <pv-column field="shop.chainStore.value" header="Sieć" style="width: 120px" />
                <pv-column field="shop.categoryActivation.value" header="Kategoria aktywacyjna" style="width: 120px" />
                <pv-column field="shop.channel.value" header="Kanał" style="width: 120px" />
                <pv-column header="Supervisor" style="width: 150px">
                    <template #body="{data}">
                        <span v-if="data.user">{{data.user.firstName}} {{data.user.lastName}}</span>
                    </template>
                </pv-column>
                <pv-column  style="width: 80px">
                    <template #body="{data}">
                        <pv-button v-if="data.user != null " icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteItem(data)" />
                    </template>
                </pv-column>


            </pv-table>
            <button v-if="selectedRows.length > 0" class="btn btn-primary btn-lg" @click="assign()" >Przypisz supervisora</button>

            <AssignSv :visibleDialog="visibleDialog" :dataValue="dataAssign" @closeForm="visibleDialog = false" @saveForm="refreshTable()" />
        </div>
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import ConfirmDialog from 'primevue/confirmdialog';
    import AssignSv from './dialog/AssignSv.vue'
    import Message from '@miro-labs/vue3-message/Message'

    import Chips from 'primevue/chips';


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Lista Sklepów"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            AssignSv,
            'pv-toolbar': Toolbar,
            'pv-chips': Chips,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
        },

        computed: {
            ...mapState({
                userData: state => state.auth.userData
            }),
        },


        data() {
            return {
                visibleDialog: false,
                dataAssign: [],
                filter: {
                    listId: null,
                    name: null,
                    shortName: null,
                    nip: null,
                    city: null,
                    street: null,
                    chainStore: null,
                    trader: null,
                    categoryActivation: null,
                    channel: null
                },




                loading: false,
                shops: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,

                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {


            async importFile(event) {
                this.loading = true;
                var file = event.target.files[0];
                if (!file) {
                    return;
                }

                let files = this.$refs.importFile.files;
                let formData = new FormData();
                formData.append('file', files[0]);


                await this.$store.dispatch('agency/importShops', formData);
                this.$store.dispatch("message/show", [Message.INFO, '', 'Import', 'Dane zostały zaimportowane']);
                this.loading = false;
            },




            async exportFile() {
                this.loading = true;
                let response = await this.$store.dispatch('agency/exportShops');
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="shops.xlsx";
                link.click();


                this.loading = false;
            },




            async refreshTable() {
                let qFilter = {...this.filter};
                qFilter.listId = this.filter.listId ? this.filter.listId.map(id => id.trim()) : [];
                qFilter.agencyId = this.userData.agencyId;
                this.loading = true;
                let query = {
                    filter: qFilter,
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: 'ASC',
                    sortName: 'id'
                };
                let response = await this.$store.dispatch('shop/findShopSv', query);
                this.totalRecords = response.totalElements;
                this.shops = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },

            async assign() {
                let shops = this.selectedRows.map(r => r.shop);
                this.dataAssign = shops;
                this.visibleDialog = true;
            },

            async deleteItem(item) {
                item.user = null;
                await this.$store.dispatch('shop/assignShopSv', [item]);
                this.refreshTable();
            }

        },


        watch: {
            filter: {
                handler() {
                    this.refreshTable();
                },
                deep: true
            }
        },


        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>