<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">


            <pv-table
                    ref="dt"
                    :value="rows"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"
                    v-model:filters="filters"
                    filterDisplay="row"


            >


                <pv-column field="planSv.action.name" header="Nazwa akcji" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="soldTarget" header="Cel sprzedażowy 1" style="width: 150px;  text-align: center;" />
                <pv-column field="secondTarget" header="Cel sprzedażowy 2" style="width: 150px;  text-align: center;" />
                <pv-column field="thirdTarget" header="Cel sprzedażowy 3" style="width: 150px;  text-align: center;" />
                <pv-column field="contactTarget" header="Cel kontaktowy" style="width: 150px;  text-align: center;" />
                <pv-column field="promotionDays" header="Cel promo dni" style="width: 150px;  text-align: center;" />
                <pv-column  header="Gadżety" style="width: 250px;  text-align: center;" >
                    <template #body="{data}">
                        <p v-for="plan in data.planGifts" :key="plan.id">{{plan.gift.name}}: {{plan.assigned}}</p>
                    </template>
                </pv-column>



            </pv-table>

        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Prezentacje", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Akcje"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
        },




        data() {
            return {
                loading: false,
                rows: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'planSv.action.name': {value: '', matchMode: FilterMatchMode.CONTAINS}
                },

                lazyParams: {},
                selectedRows: [],
            }
        },


        methods: {




            async refreshTable() {
                this.rows = await this.$store.dispatch('ba/findActions');
                this.totalRecords = this.rows.length;

                this.loading = false;
            },



        },

        mounted() {
            this.loading = true;

            this.refreshTable();
        },

    }
</script>