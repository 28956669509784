import {UserRole, AccessType} from "../../components/AccessComponent";

import Dashboard from "./dashborad/template/Dashboard.vue"
import PollStart from "./dashborad/template/PollStart.vue"
import PollFinish from "./dashborad/template/PollFinish.vue"
import Poll from "./dashborad/template/Poll.vue"

import ActionList from "./action/template/ActionList.vue"
import Calendar from "./calendar/template/Calendar.vue"


export default [
    {
        path: "/ba/dashboard",
        name: "baDashboard",
        component: Dashboard,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/ba/poll/start/:id",
        name: "pollStart",
        component: PollStart,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/ba/poll/:id",
        name: "pollFrom",
        component: Poll,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.LEVEL}
        },
    },

    {
        path: "/ba/poll/finish/:id",
        name: "pollFinish",
        component: PollFinish,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/ba/action/list",
        name: "baActionList",
        component: ActionList,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}
        },
    },

    {
        path: "/ba/calendar",
        name: "baCalendar",
        component: Calendar,
        meta: {
            layout: 'base',
            menu: 'ba',
            access: {roles: [UserRole.BA], type: AccessType.DIRECT_ROLE}
        },
    },
]