<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Słowniki", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Edycja"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                dictionares: state => state.dictionary.dictionares
            }),
        },

        data() {
            return {
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'name', label: 'Nazwa', placeholder: 'Wpisz nazwę',
                            tooltip: 'Wpisz nazwę słownika', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 100, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                        {type: 'TEXTAREA', name: 'description', label: 'Opis', placeholder: 'Wpisz opis',
                            tooltip: 'Wpisz opis słownika', info: '', required: false,
                            maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},
                        {type: 'CHECKBOX', name: 'active', label: 'Aktywny',
                            tooltip: 'Czy słownik jest aktywny?', info: '', required: false},


                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {

            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('dictionary/saveDictionary', data);
                await this.$store.dispatch('dictionary/findDictionares');
                this.$router.replace({name: 'dictionaryList'});

            },
            cancelForm() {
                this.$router.replace({name: 'dictionaryList'});
            }

        },

        async beforeMount() {
            await this.$store.dispatch('dictionary/findDictionares');
            if (this.$route.params.id > 0) {
                let data = this.dictionares.find(d => d.id == this.$route.params.id);
                if (data) {
                    this.formData = data;
                }
            }
        }
    }

</script>