<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {


        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {
            return {
                breadcrumpsList: null,
                dictionary: null,
                active: false,
                formData: {
                    value: '',
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'value', label: 'Nazwa', placeholder: 'Wpisz nazwę',
                            tooltip: 'Wpisz wartość słownika', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 100, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {

            async sendValue(data) {
                data.dictionary = {...this.dictionary};
                data.active = this.active;
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('dictionary/saveDictionaryValue', data);
                this.$router.replace({name: 'dictionaryValueList',  params: {id: this.dictionary.id}});
            },
            cancelForm() {
                this.$router.replace({name: 'dictionaryValueList',  params: {id: this.dictionary.id}});
            }

        },

        async beforeMount() {
            let dictionares = await this.$store.dispatch('dictionary/findDictionares');
            this.dictionary = dictionares.find(d => d.id == this.$route.params.dictionaryId);
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Słowniki", routerName: 'dictionaryList', params: {}, query: {}},
                {label: this.dictionary.name, routerName: 'dictionaryValueList', params: {id: this.dictionary.id}, query: {}},
                {label: 'Edycja'}
            ];


            if (this.$route.params.id > 0) {
                let values = await this.$store.dispatch('dictionary/findDictionaryValues', this.$route.params.dictionaryId);
                let data = values.find(d => d.id == this.$route.params.id);
                if (data) {
                    this.formData = data;
                    this.active = data.active;
                }
            }
        }
    }

</script>