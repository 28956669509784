<template>
    <div></div>
</template>

<script>

    export default {
        async beforeMount() {
            let roles = await this.$store.dispatch('auth/role');
            if (roles.find(r => r.level >= 4)) {
                this.$router.replace({name: 'shopList'});
                return;
            }
            if (roles.find(r => r.level >= 3)) {
                this.$router.replace({name: 'agencyActionList'});
                return;
            }
            if (roles.find(r => r.level >= 2)) {
                this.$router.replace({name: 'svActionList'});
                return;
            }
            if (roles.find(r => r.level >= 1)) {
                this.$router.replace({name: 'baDashboard'});
                return;
            }


        }
    }
</script>