/* eslint-disable */


export default {
    namespaced: true,

    state: {
    },

    mutations: {

    },

    actions: {


        findActions({}) {
            return this.dispatch('api/get', {path: '/api/target/plan-svs'});
        },

        findBaTarget({}, id) {
            return this.dispatch('api/get', {path: '/api/target/plan-bas/plan/' + id});
        },

        saveBaTarget({}, data) {
            return this.dispatch('api/post', {path: '/api/target/plan-bas', data: data});
        },


        findBaTargets({}, id) {
            return this.dispatch('api/get', {path: '/api/target/plan-bas/' + id});
        },

        async summarySvTarget({}, id) {
            let plans =  await this.dispatch('api/get', {path: '/api/target/plan-bas/' + id});
            let planSv =  await this.dispatch('api/get', {path: '/api/target/plan-svs/plan/' + id});
            let result = {
                plan: {contactTarget: planSv.contactTarget, soldTarget: planSv.soldTarget,
                    secondTarget: planSv.secondTarget, thirdTarget: planSv.thirdTarget,
                    promotionDays: planSv.promotionDays},
                distribute: {contactTarget: 0, soldTarget: 0, secondTarget: 0, thirdTarget: 0, promotionDays: 0},
                gifts: [],
            };
            planSv.planGifts.forEach(g => {
                    let gift = result.gifts.find(rg => rg.gift.id == g.gift.id);
                    if(gift) {
                        gift.plan += g.assigned;
                    } else {
                        result.gifts.push({gift: g.gift, plan: g.assigned, distribute: 0});
                    }
            });
            plans.forEach(p => {
                result.distribute.contactTarget += p.contactTarget;
                result.distribute.soldTarget += p.soldTarget;
                result.distribute.secondTarget += p.secondTarget;
                result.distribute.thirdTarget += p.thirdTarget;
                result.distribute.promotionDays += p.promotionDays;
                p.planGifts.forEach(g => {
                    let gift = result.gifts.find(rg => rg.gift.id == g.gift.id);
                    if (gift) {
                        gift.distribute += g.assigned;
                    }
                })

            });

            return result;

        },


        async findTermsByShop({}, params) {
            let query = {pageNo: 0, pageSize: 600, sortName: "dateFrom", sortDirection: "ASC", filter: {
                ...params
            }};
            let response =  await this.dispatch('api/post', {path: '/api/harmonogram/plan-term/query', data: query});
            return response.content;
        },


        async findTermsByQuery({}, query) {
            return  await this.dispatch('api/post', {path: '/api/harmonogram/plan-term/query', data: query});
        },

        saveTermList({}, list) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-term/all', data: list});
        },


        saveTerm({}, data) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-term', data: data});
        },

        saveTermSold({}, data) {
            this.dispatch('api/put', {path: '/api/harmonogram/plan-term-sold', data: data});
        },

        deleteTerm({}, id) {
            return this.dispatch('api/delete', {path: '/api/harmonogram/plan-term/' + id});
        },

        assignGroup({}, data) {
            return this.dispatch('api/post', {path: '/api/harmonogram/plan-term/assign', data: data});
        },

    }
}