<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {


        components: {
            Breadcrumbs,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                fields: state => state.dictionary.fields
            }),
        },

        data() {
            return {
                breadcrumpsList: null,
                currentField: null,
                formData: null,
                formStruct: {fields: []},



            }
        },


        methods: {

            async sendValue(data) {
                let dictionares = await this.$store.dispatch('dictionary/findDictionares');
                let request = [];
                Object.keys(data).forEach(d => {
                    let id = d.replace("dict_", '');
                    let dictionary = dictionares.find(dic => dic.id == id);
                    if(dictionary) {
                        request.push({
                            dictionary: {...dictionary},
                            field: this.currentField.type,
                            active: data[d]
                        })
                    }
                });
                await this.$store.dispatch('dictionary/saveFields', request);
                this.$router.replace({name: 'dictionaryFieldList'});

            },
            cancelForm() {
                this.$router.replace({name: 'dictionaryFieldList'});
            }

        },

        async beforeMount() {
            await this.$store.dispatch('dictionary/findFields');
            this.currentField = this.fields.find(f => f.type == this.$route.params.type);
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Przypisanie słowników", routerName: 'dictionaryFieldList', params: {}, query: {}},
                {label: this.currentField.name}
            ];
            let assignData = await this.$store.dispatch('dictionary/findFieldsByType', this.$route.params.type);
            let formFields = [];
            let fData = {};
            assignData.forEach(data => {
                fData['dict_' + data.dictionary.id] = data.active;
                formFields.push({type: 'CHECKBOX', name: 'dict_' + data.dictionary.id, label: data.dictionary.name,
                        tooltip: '', info: '', required: false});
            });
            this.formData = fData;
            this.formStruct = {
                rebuild: true,
                fields: formFields,
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            };


        }
    }

</script>