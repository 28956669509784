import {UserRole, AccessType} from "../../components/AccessComponent";


import ShopList from "./shop/template/ShopList.vue"
import ActionList from "./action/tempalate/ActionList.vue"
import PlanList from "./action/tempalate/PlanList.vue"
import ShopCalendar from "./action/tempalate/ShopCalendar.vue"
import TargetList from "./action/tempalate/TargetList.vue"
import TargetForm from "./action/tempalate/TargetForm.vue"

import UserList from "./user/template/UserList.vue"
import UserForm from "./user/template/UserForm.vue"
import BaList from "./user/template/BaList.vue"

import TableList from "./schedule/template/Table.vue"
import Calendar from "./schedule/template/Calendar.vue"


export default [
    {
        path: "/agency/shop/list",
        name: "agencyShopList",
        component: ShopList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },
    {
        path: "/agency/action/list",
        name: "agencyActionList",
        component: ActionList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/action/plans/:id",
        name: "agencyActionPlans",
        component: PlanList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/action/plan/calendar/:id",
        name: "agencyShopCalendar",
        component: ShopCalendar,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/action/target/:id",
        name: "agencyActionTargets",
        component: TargetList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/action/target/:actionId/:id",
        name: "agencyActionTargetForm",
        component: TargetForm,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },


    {
        path: "/agency/user/list",
        name: "agencyUserList",
        component: UserList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },


    {
        path: "/agency/user/add",
        name: "agencyUserAdd",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/user/edit/:id",
        name: "agencyUseEdit",
        component: UserForm,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },



    {
        path: "/agency/user/ba/list/:id",
        name: "agencyBaList",
        component: BaList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.LEVEL}
        },
    },

    {
        path: "/agency/schedule/table",
        name: "agencyScheduleTable",
        component: TableList,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE}
        },
    },


    {
        path: "/agency/schedule/calendar",
        name: "agencyScheduleCalendar",
        component: Calendar,
        meta: {
            layout: 'base',
            menu: 'agency',
            access: {roles: [UserRole.AGENCY], type: AccessType.DIRECT_ROLE}
        },
    },
]