<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">


            <pv-table
                    ref="dt"
                    :value="fields"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"



            >

                <pv-column field="name" header="Nazwa" style="width: auto">
                </pv-column>



                <pv-column  style="width: 120px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-pencil" class="p-button-rounded p-button-warning me-2" @click="edit(data)" />
                    </template>
                </pv-column>
            </pv-table>

        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Button from 'primevue/button'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Ustawienia", routerName: 'dictionaryList', params: {}, query: {}},
                {label: "Przypisanie słowników"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,

        },

        computed: {
            ...mapState({
                fields: state => state.dictionary.fields
            }),
        },


        data() {
            return {
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
            }
        },


        methods: {



            edit(item) {
                this.$router.replace({name: 'dictionaryFieldEdit', params: {type: item.type}});
            },



            async refreshTable() {
                this.$store.dispatch('dictionary/findFields');
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>