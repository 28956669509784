<template>
    <div class="q-answer">
        <div><img @click="open" :src="answerObj.question.externalLink" class="img-thumbnail rounded d-block" style="max-width: 200px"></div>
    </div>
    <ImageDialog :visibleDialog="visibleDialog" :dataValue="dialogItem" @closeForm="visibleDialog = false"  />
</template>

<script>

    import ImageDialog from "../dialog/Image.vue"

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false,
                visibleDialog: false,
                dialogItem: ""
            }
        },


        components: {
            ImageDialog,
        },
        created() {
            this.answerObj = this.answer;
        },

        watch: {

            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        },

        methods: {
            open() {
                this.dialogItem = this.answerObj.question.externalLink;
                this.visibleDialog = true;
            }
        }
    }
</script>