<template>
    <pv-dialog
            header="Mechanizm"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '60vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        >
            <template v-slot:afterInputs>
                <div v-if="formData" class="row mb-3">
                    <div class="col-sm-12">
                        <div class="table-responsive" >
                            <table class="mb-0 table table-striped edit-table">
                                <thead>
                                <tr>
                                    <th style="width: auto">Opcje</th>
                                    <th v-if="formData.dependentQuestion && formData.dependentQuestion.id" style="width: 300px">Zależność</th>
                                </tr>

                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in formData.options" :key="index">
                                    <td>{{item.promotion.promotion.name}}</td>
                                    <td v-if="formData.dependentQuestion && formData.dependentQuestion.id" >
                                        <pv-dropdown
                                                :id="item.id"
                                                class="form-control"
                                                placeholder="Wybierz opcję"
                                                v-model="item.dependentOption"
                                                :options="formData.dependentOptions"
                                                optionLabel="option"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </ml-form>
    </pv-dialog>
</template>


<script>
    import Dropdown from 'primevue/dropdown';
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue', 'dependentQuestions'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form,
            'pv-dropdown': Dropdown,
        },
        data() {
            return {
                visible: false,
                formData: null,
                dependentOptions: [],
                formStruct: {fields: []},
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
                this.dependentOptions = this.formData.dependentQuestion && this.formData.dependentQuestion.id ? this.formData.dependentQuestion.options : [];
                if (this.dependentOptions.length == 0 && this.formData.dependentOptions != null && this.formData.dependentOptions.length > 0) {
                    this.formData.dependentOptions = [];
                }

                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .options = this.dependentOptions;
                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .visibility = this.dependentOptions.length > 0;
            },

            sendValue(data) {
                this.$emit('saveForm', {...data});
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };


                let action = await this.$store.dispatch('shop/findAction', this.$route.params.id);
                action.promotions.forEach(ap => {
                    let prom = newValue.options.find(o => o.promotion.id == ap.id);
                    if (!prom) {
                        newValue.options.push({
                            option: ap.promotion.name,
                            promotion: ap,
                            deleted: 0
                        })
                    }

                });




                let formFields = [
                    {type: 'TEXT', name: 'title', label: 'Pytanie', placeholder: 'Wpisz pytanie do ankiety',
                        tooltip: 'Wpisz pytanie', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 100, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                    {type: 'TEXTAREA', name: 'description', label: 'Opis',
                        tooltip: 'Wpisz dodatkowe informacje do pytania', info: '',
                        maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},
                    {type: 'DROPDOWN', name: 'dependentQuestion', label: 'Pytanie zależne od',
                        visibility: this.dependentQuestions.length > 0,
                        tooltip: '', info: '', options: this.dependentQuestions, optionLabel: 'title'},
                    {type: 'MULTISELECT', name: 'dependentOptions', label: 'Opcje zależne',
                        visibility: this.dependentOptions.length > 0,
                        tooltip: '', info: '', options: this.dependentOptions, optionLabel: 'option'},


                ];






                this.formData = newValue;
                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>