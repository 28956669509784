<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label class="form-label col-sm-2 col-form-label" >{{formInput.label}}</label>
        <div class="col-sm-10">

            <div class="radio-button-inline">
                <div v-for="(option, index) in formInput.options" :key="index" class="field-radiobutton">
                    <pv-radio-button :id="formInput.name + '_' +index" :name="formInput.name" :value="option" v-model="value" :disabled="formInput.disabled" />
                    <label :for="formInput.name + '_' +index">{{option[formInput.optionLabel]}}</label>
                </div>
            </div>
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>

<script>

    import RadioButton from 'primevue/radiobutton'
    import Tooltip from 'primevue/tooltip';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-radio-button': RadioButton,
        },
        directives: {
            'tooltip': Tooltip
        },
        data() {
            return {
                value: null
            }
        },
        created() {
            this.value = this.formInput.value === 0 ? '0' : this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value === 0 ? '0' : this.formInput.value;
                },
                deep: true
            }

        }
    }
</script>