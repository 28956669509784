<template>
    <div class="q-answer">
        <ul v-if="answerObj" style="max-width: 400px;">
            <li  v-for="answer in answerOptions" :key="answer.option.id" style="border: 1px solid #cacaca;margin-bottom: 2px; padding: 0.5rem">
                <button class="btn-icon btn-icon-only btn btn-primary me-2" :disabled="answer.orderValue <= 1" @click="up(answer)">
                    <i class="fa fa-angle-up btn-icon-wrapper"> </i>
                </button>
                <button class="btn-icon btn-icon-only btn btn-primary me-2" :disabled="answer.orderValue >= maxValue" @click="down(answer)">
                    <i class="fa fa-angle-down btn-icon-wrapper"> </i>
                </button>

                <label>{{answer.option.option}}</label>
            </li>
        </ul>
    </div>
</template>

<script>


    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerOptions: [],
                answerObj: null,
                createdData: false,
                maxValue: 0,
                selectOptions: []
            }
        },


        components: {
        },
        created() {
            this.answerObj = this.answer;
            this.answerOptions = this.answerObj.question.options
                .map(option => {
                    return {
                        option: option,
                        orderValue: this.answerObj.question.options.indexOf(option) +1,
                    }
                });


            if (this.answerObj.options != null) {
                this.answerObj.options.forEach(selOption => {
                    let opt = this.answerOptions.find(a => a.option.id == selOption.option.id)
                    opt.id = selOption.id;
                    opt.orderValue = selOption.orderValue;
                    this.selectOptions.push(opt);
                });
            }
            this.answerOptions.sort((a,b) => a.orderValue - b.orderValue);
            this.maxValue = this.answerOptions.length;
        },

        methods: {
            up(answer) {
                let change = this.answerOptions.find(a => a.orderValue == answer.orderValue -1);
                if (change) {
                    change.orderValue = answer.orderValue;
                    answer.orderValue = answer.orderValue -1;
                    this.answerOptions.sort((a,b) => a.orderValue - b.orderValue);
                    this.changeSelectedValue();
                }
            },
            down(answer) {
                let change = this.answerOptions.find(a => a.orderValue == answer.orderValue + 1);
                if (change) {
                    change.orderValue = answer.orderValue;
                    answer.orderValue = answer.orderValue +1;
                    this.answerOptions.sort((a,b) => a.orderValue - b.orderValue);
                    this.changeSelectedValue();
                }
            },

            changeSelectedValue()   {
                this.answerObj.options = [...this.answerOptions];
            },
        },

        watch: {
            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }

                },
                deep: true
            }
        }
    }
</script>