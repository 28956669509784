<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-table
                    ref="dt"
                    :value="users"
                    :lazy="false"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20,50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    :loading="loading"

                    v-model:filters="filters"
                    filterDisplay="row"

            >

                <pv-column field="email" header="Email" style="width: 300px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="firstName" header="Imię" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>

                <pv-column field="lastName" header="Nazwisko" style="width: auto">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>


            </pv-table>

        </div>
    </div>
</template>

<script>


    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import ConfirmDialog from 'primevue/confirmdialog';
    import {FilterMatchMode} from 'primevue/api';



    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Supervisor", routerName: 'agencyUserList', params: {}, query: {}},
                {label: "Ba"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,

        },



        data() {
            return {
                loading: false,
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'email': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'firstName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'lastName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                },
                users: []
            }
        },


        methods: {



            async refreshTable() {
                this.users = await this.$store.dispatch('user/findUsersByParentId', this.$route.params.id);
                this.loading = false;
            },




        },

        mounted() {
            this.loading = true;
            this.refreshTable();
        },

    }
</script>