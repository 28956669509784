<template>
    <component :is="layout">
        <router-view></router-view>
        <message/>
    </component>
</template>


<script>
    import Message from '@miro-labs/vue3-message/Message.vue'

    const base_layout = "base";

    export default {
        name: 'App',
        components: {Message},
        computed: {
            layout() {
                return (this.$route.meta.layout || base_layout) + '-layout';
            }
        }
    }
</script>

<style lang="scss">
    @import "assets/app";
</style>