<template>
    <div class="q-answer">
        <ul v-if="answerObj" style="font-size: 0.9em; color: #888;">
            <li  v-for="gift in gifts" :key="gift.id">
                <label class="form-check-label">
                    {{gift.gift.name}}
                </label>
            </li>
        </ul>

        <ul v-if="answerObj && gifts.length > 0">
            <li>
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="answerObj.gift" :value="true"> Gadżety zostały wydane
                </label>
            </li>
        </ul>
    </div>
</template>

<script>


    export default {
        props: ['answer', 'all'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false,
                gifts: []
            }
        },


        components: {
        },
        created() {
            this.answerObj = this.answer;
            this.refreshGifts();
        },

        methods: {
            refreshGifts() {
                let dep = this.answerObj.question.dependentQuestion;
                if (dep) {
                    let promtion = this.all.find(q => q.question.id == dep.id);
                    if (promtion && promtion.options.length > 0) {
                        let selPromotion = promtion.options[0];
                        this.gifts = selPromotion.option.promotion.actionGifts;
                    } else {
                        this.gifts = [];
                    }
                }

            }

        },


        watch: {
            all: {
                handler() {
                    this.refreshGifts();
                },
                deep: true
            },



            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>