<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
            />
        </div>
    </div>
</template>



<script>
    import {mapState} from 'vuex';
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Sklepy", routerName: 'shopList', params: {}, query: {}},
                {label: "Lista handlowców", routerName: 'shopTraderList', params: {}, query: {}},
                {label: "Edycja handlowca"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },

        computed: {
            ...mapState({
                dictionares: state => state.dictionary.dictionares
            }),
        },

        data() {
            return {
                formData: {
                    id: null,
                    name: "",
                },
                formStruct: {
                    fields: [
                        {type: 'TEXT', name: 'name', label: 'Imię i Nazwisko', placeholder: 'Wpisz imie i nazwisko',
                            tooltip: 'Wpisz nazwę imie i Nazwisko', info: '',
                            required: true,  requiredMessage: 'Pole jest wymagane',
                            maxLength: 150, maxLengthMessage: 'Pole może mieć maksymalnie 150 znaków'},

                    ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                }


            }
        },


        methods: {



            async sendValue(data) {
                if (this.$route.params.id > 0) {
                    data.id = this.$route.params.id
                }
                await this.$store.dispatch('shop/saveTrader', data);
                this.$router.replace({name: 'shopTraderList'});

            },
            cancelForm() {
                this.$router.replace({name: 'shopTraderList'});
            }

        },

        async beforeMount() {
            let fData = {
                id: null,
                name: "",
            };
            if (this.$route.params.id > 0) {
                fData = await this.$store.dispatch('shop/findTrader', this.$route.params.id);
            }
            this.formData = fData;
        }
    }

</script>