import {UserRole, AccessType} from "../../components/AccessComponent";

import ShopList from "./shop/template/ShopList.vue"
import ShopForm from "./shop/template/ShopForm.vue"
import ShopTraderList from "./trader/template/TraderList.vue"
import ShopTraderFrom from "./trader/template/TraderForm.vue"

import ActionList from "./action/template/ActionList.vue"
import ActionForm from "./action/template/ActionForm.vue"
import ShopActionList from "./action/template/ShopActionList.vue"
import CodeList from "./action/template/CodeList.vue"

import ShopPromotionList from "./promotion/template/PromotionList.vue"
import ShopPromotionForm from "./promotion/template/PromotionForm.vue"

import GiftList from "./gift/template/GiftList.vue"
import GiftForm from "./gift/template/GiftForm.vue"

import PollList from "./poll/template/PollList.vue"
import PollForm from "./poll/template/PollForm.vue"


export default [
    {
        path: "/shop/list",
        name: "shopList",
        component: ShopList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/edit/:id",
        name: "shopEdit",
        component: ShopForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/add",
        name: "shopAdd",
        component: ShopForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },


    {
        path: "/shop/trader/list",
        name: "shopTraderList",
        component: ShopTraderList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },


    {
        path: "/shop/trader/edit/:id",
        name: "shopTraderEdit",
        component: ShopTraderFrom,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/trader/add",
        name: "shopTraderAdd",
        component: ShopTraderFrom,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },


    {
        path: "/shop/action/list",
        name: "shopActionList",
        component: ActionList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },



    {
        path: "/shop/action/edit/:id",
        name: "shopActionEdit",
        component: ActionForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/action/add",
        name: "shopActionAdd",
        component: ActionForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/action/bind/:id",
        name: "shopActionBind",
        component: ShopActionList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/codes/:actionId/:promotionId",
        name: "codeList",
        component: CodeList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },


    {
        path: "/shop/promotion/list",
        name: "shopPromotionList",
        component: ShopPromotionList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/promotion/edit/:id",
        name: "shopPromotionEdit",
        component: ShopPromotionForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/promotion/add",
        name: "shopPromotionAdd",
        component: ShopPromotionForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },



    {
        path: "/shop/poll/list",
        name: "pollList",
        component: PollList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },


    {
        path: "/shop/poll/add",
        name: "pollAdd",
        component: PollForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },



    {
        path: "/shop/poll/edit/:id",
        name: "pollEdit",
        component: PollForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },





    {
        path: "/shop/gift/list",
        name: "giftList",
        component: GiftList,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/gift/edit/:id",
        name: "giftEdit",
        component: GiftForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },

    {
        path: "/shop/gift/add",
        name: "giftAdd",
        component: GiftForm,
        meta: {
            layout: 'base',
            menu: 'itpl',
            access: {roles: [UserRole.ITPL], type: AccessType.LEVEL}
        },
    },





]