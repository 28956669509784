<template>
    <div class="q-answer">
        <Camera :visibleCamera="visibleCamera" @changeValue="addLink" />
        <ul v-if="answerObj">
            <li  v-for="answer in answerOptions" :key="answer.option.id">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="selectOptions" :value="answer"> {{answer.option.option}}
                </label>
                <div class="option-image" v-if="isSelected(answer) &&  answer.link">
                    <img :src="answer.link" />
                    <button class="btn btn-primary" @click="openCamera(selectedOption(answer))" >Zmień</button>
                </div>
                <pv-textarea
                        v-if="isSelected(answer) && answer.option.extended"
                        class="form-control"
                        style="max-width: 400px;"
                        :autoResize="true"
                        rows="5"
                        cols="30"
                        v-model="answer.extendedDescription"/>
            </li>
        </ul>
    </div>
</template>

<script>

    import Camera from '../../../../camera/template/Camera.vue'
    import TextArea from 'primevue/textarea';

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerOptions: [],
                selectOptions: [],
                visibleCamera: false,
                answerObj: null,
                currentCamera: null,
                createdData: false
            }
        },


        components: {
            Camera,
            'pv-textarea': TextArea,
        },
        created() {
            this.answerObj = this.answer;
            this.answerOptions = this.answerObj.question.options.filter(o => !o.deleted)
                .map(option => {
                    return {
                        option: option,
                        extendedDescription: null,
                        link: null
                    }
                });


            if (this.answerObj.options != null) {
                this.answerObj.options.forEach(selOption => {
                    let opt = this.answerOptions.find(a => a.option.id == selOption.option.id)
                    opt.id = selOption.id;
                    opt.extendedDescription = selOption.extendedDescription;
                    opt.link = selOption.link;
                    this.selectOptions.push(opt);
                });
            }

        },

        methods: {
            isSelected(option) {
                return this.selectOptions.find(o => o.option.id == option.option.id) != null
            },
            selectedOption(option) {
                return this.selectOptions.find(o => o.option.id == option.option.id);
            },


            addLink(resource) {
                if (resource) {
                    this.currentCamera.link = resource.url;
                    this.changeSelectedValue();
                }
                this.visibleCamera = false;
            },

            changeSelectedValue()   {
                this.answerObj.options = [...this.selectOptions];
            },

            openCamera(selectOption) {
                this.currentCamera = selectOption;
                if (this.visibleCamera) {
                    this.visibleCamera = false;
                }
                this.visibleCamera = true;
            }
        },

        watch: {
            selectOptions: {
                handler() {
                    this.selectOptions.forEach(selectOption => {
                        if (selectOption.option.photo && !selectOption.link) {
                            this.openCamera(selectOption);
                        }

                    });

                    this.changeSelectedValue();
                },
                deep: true
            },
            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }

                },
                deep: true
            }
        }
    }
</script>