<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label class="form-label col-sm-2 col-form-label" :for="formInput.name">{{formInput.label}}</label>
        <div class="col-sm-10">
            <pv-password
                    :id="formInput.name"
                    style="width: 100%"
                    :inputClass="inputClass"
                    :class="{'is-invalid': formInput.error, 'is-valid': formInput.validated}"
                    :placeholder="formInput.placeholder"
                    :disabled="formInput.disabled"
                    :feedback="false"
                    v-tooltip.top="formInput.tooltip"
                    v-model="value"/>
            <div v-if="formInput.error" class="invalid-feedback">
                <span v-if="formInput.errorCustom">{{formInput.customMessage}}</span>
                <span v-if="formInput.errorRequired">{{formInput.requiredMessage}}</span>
                <span v-if="formInput.errorMinLength">{{formInput.minLengthMessage}}</span>
                <span v-if="formInput.errorMaxLength">{{formInput.maxLengthMessage}}</span>
                <span v-if="formInput.errorPattern">{{formInput.patternMessage}}</span>
            </div>
            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>

<script>

    import Password from 'primevue/password';
    import Tooltip from 'primevue/tooltip';


    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-password': Password
        },
        directives: {
            'tooltip': Tooltip
        },
        data() {
            return {
                value: ''
            }
        },
        computed: {
            inputClass() {
                let className = 'form-control';
                if (this.formInput.error) {
                    className += ' is-invalid';
                }
                if (this.formInput.validated) {
                    className += ' is-valid';
                }
                return className;
            }
        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                },
                deep: true
            }

        }
    }
</script>