<template>
    <pv-dialog
            header="Zdjęcie"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '60vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>


<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'

    export default {
        props: ['visibleDialog', 'dataValue', 'dependentQuestions'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                dependentOptions: [],
                formStruct: {fields: []},
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
                this.dependentOptions = this.formData.dependentQuestion && this.formData.dependentQuestion.id ? this.formData.dependentQuestion.options : [];
                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .options = this.dependentOptions;
                this.formStruct.fields
                    .find(f => f.type == 'MULTISELECT')
                    .visibility = this.dependentOptions.length > 0;
            },

            sendValue(data) {
                console.log(data);
                data.externalLink = data.externalLink.length > 0 ? data.externalLink[0].url : '';
                this.$emit('saveForm', {...data});
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };

                let url = process.env.VUE_APP_API_HOST + '/api/delfs/resources';
                let token = await this.$store.dispatch("api/getTicket");
                console.log(newValue.questionType);
                if (newValue.questionType == 'IMAGE') {
                    if (!newValue.externalLink) {
                        newValue.externalLink = [];
                    } else {
                        newValue.externalLink = [{url: newValue.externalLink}];
                    }
                }
                let formFields = [
                    {type: 'TEXT', name: 'title', label: 'Pytanie', placeholder: 'Wpisz pytanie do ankiety',
                        tooltip: 'Wpisz pytanie', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 100, maxLengthMessage: 'Pole może mieć maksymalnie 100 znaków'},
                    {type: 'TEXTAREA', name: 'description', label: 'Opis',
                        tooltip: 'Wpisz dodatkowe informacje do pytania', info: '',
                        maxLength: 800, maxLengthMessage: 'Pole może mieć maksymalnie 800 znaków'},
                    {type: 'DROPDOWN', name: 'dependentQuestion', label: 'Pytanie zależne od',
                        visibility: this.dependentQuestions.length > 0,
                        tooltip: '', info: '', options: this.dependentQuestions, optionLabel: 'title'},
                    {type: 'MULTISELECT', name: 'dependentOptions', label: 'Pytanie zależne od',
                        visibility: this.dependentOptions.length > 0,
                        tooltip: '', info: '', options: this.dependentOptions, optionLabel: 'option'},
                    {type: 'FILE_UPLOAD', name: 'externalLink', label: '', token: token,
                            url: url, multiple: false,
                            info: '', required: false, fileLimit: 1},
                ];






                this.formData = newValue;
                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>