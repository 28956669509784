<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
    </div>
</template>


<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import FullCalendar from '@fullcalendar/vue3'
    import DayGridPlugin from '@fullcalendar/daygrid'
    import InteractionPlugin from '@fullcalendar/interaction'
    import ListPlugin from '@fullcalendar/list'
    import TimeGridPlugin from '@fullcalendar/timegrid'
    import plLocale from '@fullcalendar/core/locales/pl';

    export default {

        setup() {
            const colors = ['#7B1FA2','#C2185B','#D32F2F','#1976D2','#00796B','#303F9F','#0097A7', '#6D4C41',
                '#512DA8', '#388E3C', '#0288D1', '#689F38', '#F57C00', '#E64A19'];
            return {
                colors
            }

        },


        data() {
            return {
                visibleDialog: false,
                dataTerm: null,
                breadcrumpsList: [],
                activeStart: null,
                activeEnd: null,
                calendarOptions: {
                    plugins: [ DayGridPlugin, InteractionPlugin, ListPlugin, TimeGridPlugin ],
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    },
                    locale: plLocale,
                    editable: false,
                    selectable: false,
                    selectMirror: false,
                    dayMaxEvents: true,
                    weekends: true,
                    eventsSet: this.refresh,
                    events: [],
                    eventContent: this.customEvent

                }
            }
        },


        components: {
            Breadcrumbs,
            FullCalendar

        },

        methods: {


            async refresh() {
                if (this.$refs.fullCalendar) {
                    let currentActiveStart = this.$refs.fullCalendar.calendar.view.activeStart;
                    let currentActiveEnd = this.$refs.fullCalendar.calendar.view.activeEnd;
                    if (this.activeStart == null || this.activeEnd == null
                        || this.activeStart.getTime() != currentActiveStart.getTime()
                        || this.activeEnd.getTime() != currentActiveEnd.getTime()) {
                        this.activeStart = currentActiveStart;
                        this.activeEnd = currentActiveEnd;
                        let query = {
                            hostessId: this.$route.params.id,
                            startActive: this.activeStart,
                            endActive: this.activeEnd
                        };
                        let events = await this.$store.dispatch('sv/findTermsByShop', query);

                        let calendarApi = this.$refs.fullCalendar.getApi();
                        calendarApi.removeAllEvents();
                        events.forEach(ev => {
                            calendarApi.addEvent({
                                id: ev.id,
                                title: ev.planShop.action.name,
                                term: ev,
                                start: ev.dateFrom,
                                end: ev.dateTo,
                                allDay: false,
                                color: this.selectColor(ev.planShop.action)
                            });


                        });

                    }
                }
            },

            async refreshForce() {
                let query = {
                    startActive: this.activeStart,
                    endActive: this.activeEnd
                };
                let events = await this.$store.dispatch('sv/findTermsByShop', query);

                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.removeAllEvents();
                events.forEach(ev => {
                    calendarApi.addEvent({
                        id: ev.id,
                        title: ev.planShop.action.name,
                        term: ev,
                        start: ev.dateFrom,
                        end: ev.dateTo,
                        allDay: false,
                        color: this.selectColor(ev.planShop.action)
                    });
                });
            },


            customEvent(arg) {
                let color = this.selectColor(arg.event.extendedProps.term.planShop.action);
                let start = this.convertTimeToString(arg.event.extendedProps.term.dateFrom);
                let end = this.convertTimeToString(arg.event.extendedProps.term.dateTo);

                let html = '<div class="calendar-custom-event ' + arg.view.type + '" style="background-color: ' + color + ';">';
                html += '<div class="event-time-block">';
                html += '<p class="event-time">' + start + ' - ' + end + '</p>';
                html += '</div>';

                html += '<div class="event-description">';
                html += '<p class="event-action">' + arg.event.extendedProps.term.planShop.action.name + '</p>';
                html += '<p class="event-shopName">' + arg.event.extendedProps.term.planShop.shop.shortName + '</p>';
                html += '<p class="event-shopStreet">' + arg.event.extendedProps.term.planShop.shop.street + ' ' + arg.event.extendedProps.term.planShop.shop.houseNumber +'</p>';
                html += '<p class="event-shopCity">' + arg.event.extendedProps.term.planShop.shop.postcode + ' ' + arg.event.extendedProps.term.planShop.shop.city +'</p>';
                html += '</div>';
                html += '</div>';


                return { html: html }

            },

            convertTimeToString(dateStr) {
                let date = new Date(dateStr);
                return date.toLocaleTimeString('pl-Pl', {hour: '2-digit', minute:'2-digit'});
            },


            selectColor(action) {
                let index = action.id % this.colors.length;
                return this.colors[index];
            }

        },



        async beforeMount() {
            this.breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Prezentacje", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Kalendarz"}];
            this.refresh()
        }


    }
</script>