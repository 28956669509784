<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <div class="col-sm-12">
            <label class="form-label col-form-label me-2">{{formInput.label}}</label>
        </div>
    </div>
</template>


<script>



    export default {
        props: ['formInput'],
        emits: ['changeValue']
    }
</script>