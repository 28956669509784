<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card app-form">
        <div v-if="form" class="card-body" :style="{
            'background-color': '#'+getSettningValue('background'),
            'font-size': getSettningValue('fontSize') + 'px',
            color: '#'+getSettningValue('fontColor'),
            'font-family': getSettningValue('fontName')}" >

            <h4 style="margin-top: 1rem; text-align: center">{{form.poll.pollDto.startPage.title}}</h4>
            <p v-if="user" style="margin-top: 1rem; text-align: center; font-size: 1.1rem">Twoje userId: {{user.id}}</p>
            <p style="margin-top: 1rem; text-align: center; font-size: 1.1rem">Strona {{page}}</p>
            <div v-for="question in questions" :key="question.question.id">
                <div v-if="checkDependency(question)" class="poll-answer">
                    <p class="title">{{question.question.number}}. {{question.question.title}}</p>
                    <p class="description" v-if="question.question.description">{{question.question.description}}</p>
                    <q-single v-if="question.question.questionType == 'SINGLE'" :answer="question" @changeValue="changeAnswer" />
                    <q-multiple v-if="question.question.questionType == 'MULTIPLE'" :answer="question" @changeValue="changeAnswer" />
                    <q-text v-if="question.question.questionType == 'TEXT'" :answer="question" @changeValue="changeAnswer" />
                    <q-email v-if="question.question.questionType == 'EMAIL'" :answer="question" @changeValue="changeAnswer" />
                    <q-number v-if="question.question.questionType == 'NUMBER'" :answer="question" @changeValue="changeAnswer" />
                    <q-star v-if="question.question.questionType == 'STAR'" :answer="question" @changeValue="changeAnswer" />
                    <q-order v-if="question.question.questionType == 'ORDER'" :answer="question" @changeValue="changeAnswer" />
                    <q-promotion v-if="question.question.questionType == 'PROMOTION'" :answer="question" :dependencyAnswer="getDependencyAnswer(question)" @changeValue="changeAnswer" />
                    <q-gift v-if="question.question.questionType == 'GIFT'" :answer="question" :all="questions" @changeValue="changeAnswer" />
                    <q-register v-if="question.question.questionType == 'REGISTER'" :answer="question" :shopLink="form.pollResult.shopTradeLink" @changeValue="changeAnswer" />
                    <q-image v-if="question.question.questionType == 'IMAGE'" :answer="question" @changeValue="changeAnswer" />
                    <p v-if="isError(question)" class="invalid-feedback">{{error(question)}}</p>
                </div>

            </div>


            <div style="display: flex; justify-content: space-between; margin-top: 2rem;">
                <button style="width: 140px"
                        :disabled="page <= 1"
                        class="btn btn-primary btn-icon me-2" @click="prev($event)" >
                    <i class="fa fa-angle-left btn-icon-wrapper" style="font-size: 1.2em;margin-right: 10px;"></i>Poprzednia strona</button>
                <button v-if="lastPage > page" style="width: 140px" class="btn btn-primary" @click="next($event)" >
                    Następna strona<i class="fa fa-angle-right btn-icon-wrapper" style="font-size: 1.2em;margin-left: 10px;"></i></button>
                <button v-if="lastPage <= page && form.pollResult.planBaTerm" style="width: 140px" class="btn btn-primary" @click="finish($event)" >Zakończ ankiete</button>
            </div>

        </div>

    </div>
</template>

<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'

    import Single from './question/Single.vue'
    import Multiple from './question/Multiple.vue'
    import Text from './question/Text.vue'
    import Email from './question/Email.vue'
    import Number from './question/Number.vue'
    import Star from './question/Star.vue'
    import Order from './question/Order.vue'
    import Promotion from './question/Promotion.vue'
    import Gift from './question/Gift.vue'
    import Register from './question/Register.vue'
    import Image from './question/Image.vue'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Prezentacje", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Dashboard", routerName: 'baDashboard', params: {}, query: {}},
                {label: "Ankieta"}
            ];
            return {
                breadcrumpsList

            }
        },


        components: {
            Breadcrumbs,
            'q-single': Single,
            'q-multiple': Multiple,
            'q-text': Text,
            'q-number': Number,
            'q-star': Star,
            'q-order': Order,
            'q-promotion': Promotion,
            'q-gift': Gift,
            'q-register': Register,
            'q-image': Image,
            'q-email': Email
        },



        data() {
            return {
                form: {poll: {pollDto: {startPage: {title: null, description: null}}}, pollResult: {planBaTerm: null}},
                page: 1,
                lastPage: 0,
                questions: [],
                errors: [],
                user: null
            }
        },

        watch: {
            async selectDay() {
                this.refreshList();
            }
        },

        methods: {

            getSettningValue(name) {
                if (this.form.poll.pollDto.settings) {
                    let sett = this.form.poll.pollDto.settings.find(s => s.name == name);
                    return sett ? sett.value : '';
                }
                return '';
            },


            prev(event) {
                event.preventDefault();
                if (this.page > 1) {
                    this.page--;
                    this.refreshCurrentPage();
                }
            },

            async next(event) {
                event.preventDefault();
                if (this.validatePage() && this.page < this.lastPage) {
                    await this.$store.dispatch('ba/savePollForm', this.form.pollResult);
                    this.form = await this.$store.dispatch('ba/getPollForm',this.$route.params.id);
                    this.page++;
                    this.refreshCurrentPage();
                    while(this.questions.filter(q => this.checkDependency(q)).length == 0 && this.page < this.lastPage) {
                        this.page++;
                        this.refreshCurrentPage();
                    }

                }
            },

            async finish(event) {
                event.preventDefault();
                if (this.validatePage()) {
                    this.form.pollResult.sent = true;
                    await this.$store.dispatch('ba/savePollForm', this.form.pollResult);
                    this.$router.replace({name: 'pollFinish', params:{id: this.form.pollResult.id}});
                }
            },

            validatePage() {
                this.errors = [];
                let result = true;
                this.questions.filter(q => this.checkDependency(q))
                    .forEach(q => result = this.validateQuestion(q) && result);
                return result;
            },

            validateQuestion(answer) {
                if (answer.question.questionType == 'SINGLE') {
                    return this.validateSingle(answer);
                }
                if (answer.question.questionType == 'MULTIPLE') {
                    return this.validateMultiple(answer);
                }
                if (answer.question.questionType == 'TEXT') {
                    return this.validateText(answer);
                }
                if (answer.question.questionType == 'EMAIL') {
                    return this.validateEmail(answer);
                }
                if (answer.question.questionType == 'NUMBER') {
                    return this.validateNumber(answer);
                }
                if (answer.question.questionType == 'STAR') {
                    return this.validateStar(answer);
                }
                if (answer.question.questionType == 'GIFT') {
                    return this.validateGift(answer);
                }
                if (answer.question.questionType == 'REGISTER') {
                    return this.validateRegister(answer);
                }
                if (answer.question.questionType == 'ORDER') {
                    return this.validateOrder(answer);
                }
                return true;
            },

            isError(answer) {
                return this.errors.find(e => e.questionId == answer.question.id)  != null;
            },

            error(answer) {
                return this.errors.find(e => e.questionId == answer.question.id).message;
            },

            removeError(answer) {
                this.errors = this.errors.filter(e => e.questionId != answer.question.id);
            },

            setError(answer, errorMessage) {
                this.removeError(answer);
                this.errors.push({questionId: answer.question.id, message: errorMessage});
            },

            validateSingle(answer) {
                if (answer.question.required && answer.options.length == 0) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                if (answer.options.length > 0) {
                    if (answer.options[0].option.extended && !answer.options[0].extendedDescription) {
                        this.setError(answer, "Komentarz jest wymagany");
                        return false;
                    }
                    if (answer.options[0].option.photo && !answer.options[0].link) {
                        this.setError(answer, "Zdjęcie jest wymagane");
                        return false;
                    }
                    if (answer.options[0].option.extended && answer.options[0].extendedDescription.length > 600) {
                        this.setError(answer, "Komentarz nie może być dłuższy niż 600 znaków");
                        return false;
                    }
                }

                return true;
            },

            validateMultiple(answer) {
                if (answer.question.required && answer.options.length == 0) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                if (answer.question.maxOptions > 0 &&  answer.options.length > answer.question.maxOptions) {
                    this.setError(answer, "Mkasymalna ilośc odpowiedz: " + answer.question.maxOptions);
                    return false;
                }
                answer.options.forEach(option => {
                    if (option.option.extended && !option.extendedDescription) {
                        this.setError(answer, "Komentarz jest wymagany");
                        return false;
                    }
                    if (option.option.photo && !option.link) {
                        this.setError(answer, "Zdjęcie jest wymagane");
                        return false;
                    }
                    if (option.option.extended && option.extendedDescription.length > 600) {
                        this.setError(answer, "Komentarz nie może być dłuższy niż 600 znaków");
                        return false;
                    }
                });

                return true;
            },
            validateText(answer) {
                if (answer.question.required && !answer.description) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                if (answer.description) {
                    if (answer.question.maxLength > 0) {
                        if (answer.description.length > answer.question.maxLength) {
                            this.setError(answer, "Pole nie może być dłuższe niż " + answer.question.maxLength + " znaków");
                            return false;
                        }
                    } else {
                        if (answer.description.length > 800) {
                            this.setError(answer, "Pole nie może być dłuższe niż 800 znaków");
                            return false;
                        }
                    }
                }
                return true;
            },

            validateEmail(answer) {
                if (answer.question.required && !answer.description) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                if (answer.description) {
                    if (answer.question.maxLength > 0) {
                        if (answer.description.length > answer.question.maxLength) {
                            this.setError(answer, "Pole nie może być dłuższe niż " + answer.question.maxLength + " znaków");
                            return false;
                        }
                    } else {
                        if (answer.description.length > 800) {
                            this.setError(answer, "Pole nie może być dłuższe niż 800 znaków");
                            return false;
                        }
                    }

                    if (!(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(answer.description))) {
                        this.setError(answer, "Adres email ma nieprawidłowy format");
                        return false;
                    }

                }
                return true;
            },


            validateNumber(answer) {
                if (answer.question.required && answer.numberValue == null) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                return true;
            },
            validateStar(answer) {
                if (answer.question.required && answer.value == null) {
                    this.setError(answer, "Pytanie jest obowiązkowe");
                    return false;
                }
                return true;
            },
            validateGift() {
                return true;
            },
            validateRegister() {
                return true;
            },
            validateOrder() {
                return true;
            },

            refreshCurrentPage() {
                this.questions = this.form.pollResult.answers.filter(a => a.question.pollPage.number == this.page);
                this.questions.sort((a, b) => a.question.number - b.question.number);
            },

            buildQuestions() {
                this.form.poll.questions.forEach(q => {
                    let answer = this.form.pollResult.answers.find(a => a.question.id == q.id);
                    if (answer == null) {
                        this.form.pollResult.answers.push({
                            question: q,
                            options: [],
                            description: null,
                            numberValue: null,
                            value: null
                        })
                    }
                });
            },

            getDependencyAnswer(question) {
                if (question.question.dependentQuestion) {
                    return this.form.pollResult.answers.find(a => a.question.id ==question.question.dependentQuestion.id);
                }
                return null;
            },

            checkDependency(question) {
                if (question.question.dependentQuestion) {
                    let answerQ = this.form.pollResult.answers.find(a => a.question.id ==question.question.dependentQuestion.id);
                    return question.question.dependentOptions.find(dqo => answerQ.options.find(ao => ao.option.id == dqo.id) != null) != null;
                }
                return true;
            },

            changeAnswer(answer) {
                this.form.pollResult.answers = this.form.pollResult.answers
                    .filter(a => a.question.id != answer.question.id);
                this.form.pollResult.answers.push(answer);
                this.removeError(answer);
                this.validateQuestion(answer);
            }

        },


        async beforeMount() {
            this.user = await this.$store.dispatch('api/get', {path: '/api/auth/user'});

            this.form = await this.$store.dispatch('ba/getPollForm',this.$route.params.id);
            this.lastPage = Math.max(...this.form.poll.pollDto.pages.map(p => p.number));
            this.buildQuestions();
            this.refreshCurrentPage();


        }

    }
</script>