<template>
    <div class="q-answer">
        <p><a :href="link" target="_blank">Formularz rejestracji</a> </p>


    </div>
</template>

<script>


    export default {
        props: ['answer', 'shopLink'],
        emits: ['changeValue'],

        data() {
            return {
                answerObj: null,
                createdData: false,
                link: null
            }
        },


        components: {
        },
        created() {
            console.log(this.shopLink);
            this.answerObj = this.answer;
            this.link = this.shopLink ? this.shopLink : this.answerObj.question.externalLink;
        },

        watch: {

            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>