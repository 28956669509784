<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            />


        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import {updateValue} from '../../../form/ml/form/class/InputsManager'


    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Supervisor", routerName: 'svActionList', params: {}, query: {}},
                {label: "Pracownicy", routerName: 'svUserList', params: {}, query: {}},
                {label: "Edycja"}
            ]
            return {
                breadcrumpsList
            }
        },

        components: {
            Breadcrumbs,
            'ml-form': Form
        },


        data() {
            return {
                roles: [],
                agencyList: [],
                formData: {
                    name: '',
                    description: '',
                    active: false
                },
                formStruct: {fields: []},


            }
        },


        methods: {

            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                data.roles = [data.role];
                if (this.$route.params.id) {
                    await this.$store.dispatch('user/editUser', data);
                } else {
                    await this.$store.dispatch('user/createUser', data);
                }
                await this.$store.dispatch('user/findUsers');
                this.$router.replace({name: 'svUserList'});

            },
            cancelForm() {
                this.$router.replace({name: 'svUserList'});
            }

        },

        async beforeMount() {


            if (this.$route.params.id) {
                let data = await this.$store.dispatch('user/findUser', this.$route.params.id);
                this.formData = data;
            } else {
                this.formData = {
                    email: '',
                    firstName: '',
                    lastName: '',
                    role: null,
                    agency: null
                }
            }

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'TEXT', name: 'email', label: 'Email', placeholder: 'Wpisz email',
                        tooltip: 'Email', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                    {type: 'TEXT', name: 'firstName', label: 'Imię', placeholder: 'Wpisz imię',
                        tooltip: 'Imię', info: '',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},

                    {type: 'TEXT', name: 'lastName', label: 'Nazwisko', placeholder: 'Wpisz nazwisko',
                        tooltip: 'Nazwisko', info: '',
                        maxLength: 200, maxLengthMessage: 'Pole może mieć maksymalnie 200 znaków'},
                ],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
            };

        }
    }

</script>