<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <pv-confirm-dialog></pv-confirm-dialog>
    <div class="main-card mb-3 card">
        <div class="card-body">

            <pv-toolbar  class="mb-4">

                <template #end>
                    <pv-button label="Export" icon="pi pi-upload"  class=""  @click="exportFile()" />
                </template>
            </pv-toolbar>



            <pv-table
                    ref="dt"
                    :value="shops"
                    :lazy="true"
                    :totalRecords="totalRecords"
                    :paginator="true"
                    :rows="20"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[20, 50, 100]"
                    currentPageReportTemplate="{first} - {last} z {totalRecords}"
                    responsiveLayout="scroll"
                    v-model:filters="filters"
                    filterDisplay="row"
                    :loading="loading"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"

            >
                <pv-column field="shop.externalId" header="Id" style="width: 70px">
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.name" header="Nazwa" style="width: auto" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.shortName" header="Nazwa skrócona" style="width: 180px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.city" header="Miasto" style="width: 100px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.street" header="Ulica" style="width: 200px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.postcode" header="Kod pocztowy" style="width: 60px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column field="shop.chainStore.value" header="Sieć" style="width: 100px" >
                    <template #filter="{filterModel, filterCallback}">
                        <pv-input-text
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                @input="filterCallback()"
                                :placeholder="'Szukaj...'"/>
                    </template>
                </pv-column>
                <pv-column  header="Supervisor" style="width: 160px" >
                    <template #body="{data}">
                        <span v-if="data.planSv">{{data.planSv.user.firstName}} {{data.planSv.user.lastName}}</span>
                    </template>
                </pv-column>

                <pv-column header="Liczba terminów" field="assignTerm" style="width: 100px" />

                <pv-column  style="width: 170px">
                    <template #body="{data}">
                        <pv-button icon="pi pi-calendar" class="p-button-rounded p-button-primary me-2" @click="shopCalendar(data)" />
                        <pv-button icon="fa fa-user" class="p-button-rounded p-button-primary me-2" @click="assignSv(data)" />
                        <pv-button v-if="data.commentSize > 0" icon="pi pi-comment" class="p-button-rounded p-button-primary me-2" @click="comment(data)" />
                    </template>
                </pv-column>
            </pv-table>
            <AssignForm :visibleDialog="visibleDialog" :dataValue="planItem" @closeForm="visibleDialog = false" @saveForm="refreshTable()" />
            <CommentView :visibleDialog="visibleComment" :dataValue="commentInitData" @closeForm="visibleComment = false" />
        </div>
    </div>
</template>

<script>

    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Toolbar from 'primevue/toolbar'
    import DateTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import ConfirmDialog from 'primevue/confirmdialog';
    import InputText from 'primevue/inputtext'
    import {FilterMatchMode} from 'primevue/api';
    import AssignForm from "./dialog/AssignSv.vue"
    import CommentView from './dialog/CommentView.vue'

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Akcje", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Lista sklepów"}
            ];
            return {
                breadcrumpsList
            }
        },


        components: {
            Breadcrumbs,
            AssignForm,
            CommentView,
            'pv-toolbar': Toolbar,
            'pv-button': Button,
            'pv-table': DateTable,
            'pv-column': Column,
            'pv-input-text': InputText,
            'pv-confirm-dialog': ConfirmDialog,
        },




        data() {
            return {
                visibleDialog: false,
                planItem: null,
                loading: false,
                shops: [],
                totalRecords: 0,
                enableAction: true,
                importing: false,
                filters: {
                    'shop.externalId': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.name': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.shortName': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.city': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.postcode': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.street': {value: '', matchMode: FilterMatchMode.CONTAINS},
                    'shop.chainStore.value': {value: '', matchMode: FilterMatchMode.CONTAINS},

                },
                lazyParams: {},
                visibleComment: false,
                commentInitData: null,
            }
        },


        methods: {


            async exportFile() {
                this.loading = true;
                let response = await this.$store.dispatch('agency/exportEmptyTerms', this.$route.params.id);
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(response.body);
                link.download="harmonogram.xlsx";
                link.click();


                this.loading = false;
            },




            async refreshTable() {
                this.loading = true;
                let query = {
                    filter: {
                        actionId:  this.$route.params.id,
                        startWithId: this.lazyParams.filters['shop.externalId'].value,
                        name: this.lazyParams.filters['shop.name'].value,
                        shortName: this.lazyParams.filters['shop.shortName'].value,
                        city: this.lazyParams.filters['shop.city'].value,
                        postcode: this.lazyParams.filters['shop.postcode'].value,
                        street: this.lazyParams.filters['shop.street'].value,
                        chainStore: this.lazyParams.filters['shop.chainStore.value'].value,
                    },
                    pageNo: this.lazyParams.page,
                    pageSize: this.lazyParams.rows,
                    sortDirection: this.lazyParams.sortOrder < 0 ? 'DESC' : 'ASC',
                    sortName: this.lazyParams.sortField === null ? 'id' : this.lazyParams.sortField
                };
                let response = await this.$store.dispatch('agency/findPlans', query);
                this.totalRecords = response.totalElements;
                this.shops = response.content;

                this.loading = false;
            },


            onPage(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onSort(event) {
                this.lazyParams = event;
                this.refreshTable();
            },
            onFilter() {
                this.lazyParams.filters = this.filters;
                this.refreshTable();
            },

            assignSv(item) {
                this.planItem = item;
                this.visibleDialog = true;
            },

            shopCalendar(item) {
                this.$router.replace({name: 'agencyShopCalendar', params: {id:  item.id}});
            },

            comment(item) {
                this.commentInitData = item;
                this.visibleComment = true;
            }
        },


        watch: {
            filter: {
                handler() {
                    this.refreshTable();
                },
                deep: true
            }
        },


        mounted() {
            this.loading = true;
            this.lazyParams = {
                first: 0,
                rows: this.$refs.dt.rows,
                sortField: null,
                sortOrder: null,
                filters: this.filters
            };

            this.refreshTable();
        },

    }
</script>