<template>
    <pv-dialog
            header="Komentarz"
            :modal="true" :breakpoints="{'960px': '80vw'}" :style="{width: '40vw', marginTop: '12vh'}"
            v-model:visible="visible" position="top">
        <div v-if="oldComments && oldComments.length > 0"  class="mb-4" style="font-size: 0.9em">
            <h5>Poprzednie komentarze</h5>
            <div v-for="comment in oldComments" :key="comment.id" class="row mb-2">
                <label class="form-label col-sm-2 col-form-label">{{formatDate(comment.added)}}</label>
                <div class="col-sm-10"><div style="padding: 0.5rem 0.5rem; background: #eaeaea; border: 1px solid #cacaca; border-radius: 3px;">{{comment.comment}}</div> </div>
            </div>
        </div>

        <ml-form
                :dataForm="formData"
                :struct="formStruct"
                @submittedForm="sendValue"
                @canceledForm="cancelForm"
                @changeValue="changeValue"
        />
    </pv-dialog>
</template>



<script>
    import Dialog from 'primevue/dialog';
    import Form from '../../../../form/ml/form/From.vue'
    import {updateValue} from '../../../../form/ml/form/class/InputsManager'
    import {formatDateTime} from '../../../../dateTime-tools'

    export default {
        props: ['visibleDialog', 'dataValue'],
        emits: ['closeForm', 'saveForm'],
        components: {
            'pv-dialog': Dialog,
            'ml-form': Form
        },
        data() {
            return {
                visible: false,
                formData: null,
                formStruct: {fields: []},
                oldComments: []
            }
        },
        created() {
            this.visible = this.visibleDialog;
        },
        watch: {
            visible(newValue) {
                if (!newValue) {
                    this.$emit('closeForm');
                }
            },
            visibleDialog(newValue) {
                this.visible = newValue;
            },
            async dataValue(newValue) {
                await this.rebuildForm(newValue);
            }
        },

        methods: {
            formatDate(value) {
                return formatDateTime(value);
            },

            changeValue(value) {
                updateValue(this.formData, value);
            },

            async sendValue(data) {
                await this.$store.dispatch('ba/addComments', data);
                this.$emit('saveForm');
                this.visible = false;
            },

            cancelForm() {
                this.visible = false;
            },

            async rebuildForm(newValue) {
                this.formStruct = {
                    rebuild: true,
                    fields: [],
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };

                this.oldComments = await this.$store.dispatch('ba/findComments', newValue.planBaTerm.id);


                let formFields = [
                    {type: 'TEXTAREA', name: 'comment', label: 'Komentarz',
                        tooltip: 'Wpisz komentarz', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        maxLength: 2000, maxLengthMessage: 'Pole może mieć maksymalnie 2000 znaków'},
                ];






                this.formData = newValue;
                this.formStruct = {
                    rebuild: true,
                    fields: formFields,
                    cancelValue: 'Anuluj',
                    submitValue: 'Zapisz'
                };
            }
        }
    }
</script>