<template>
    <div v-if="formInput.visibility" class="position-relative row mb-3">
        <label class="form-label col-sm-2 col-form-label" :for="formInput.name">{{formInput.label}}</label>
        <div class="col-sm-10">

            <div class="custom-html-editor">
                <div class="ch-editor-toolbar">
                    <pv-button class="p-button-sm" :class="{'p-button-outlined': !edit}"
                               :disabled="formInput.disabled"
                               label="edytuj" @click="switchEdit"></pv-button>
                </div>
                <div class="ch-editor-body">

                    <div class="ch-editor-body-edit">
                        <iframe v-if="!edit"  :srcdoc="value"></iframe>

                        <pv-textarea
                                v-if="edit"
                                :id="formInput.name"
                                class="form-control"
                                :autoResize="true"
                                rows="5"
                                cols="30"
                                v-model="value"/>
                    </div>
                </div>
            </div>


            <small class="form-text text-muted" v-if="formInput.info">
                {{formInput.info}}
            </small>
        </div>
    </div>
</template>

<script>

    import Button from 'primevue/button';
    import TextArea from 'primevue/textarea';

    export default {
        props: ['formInput'],
        emits: ['changeValue'],
        components: {
            'pv-textarea': TextArea,
            'pv-button': Button
        },


        data() {
            return {
                edit: false,
                value: '',
            }
        },
        created() {
            this.value = this.formInput.value;
        },
        watch: {
            value(newValue) {
                if (this.formInput) {
                    this.$emit('changeValue', {name: this.formInput.name, value: newValue})
                }
            },
            formInput: {
                handler() {
                    this.value = this.formInput.value;
                },
                deep: true
            }
        },

        methods: {
            switchEdit() {
                this.edit = !this.edit;
            }
        }
    }
</script>