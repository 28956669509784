<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div class="filter-card">
                <div class="col-sm-12">
                    <div class="table-responsive" >
                        <table class="mb-0 table table-target">
                            <thead>
                            <tr>
                                <th></th>
                                <th style="text-align: center" colspan="5">Cele do wykonania</th>
                                <th style="text-align: center" :colspan="summary.gifts ? summary.gifts.length : 1">Gadżety</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th style="text-align: center">Cel sprzedażowy 1</th>
                                <th style="text-align: center">Cel sprzedażowy 2</th>
                                <th style="text-align: center">Cel sprzedażowy 3</th>
                                <th style="text-align: center">Cel kontaktowy</th>
                                <th style="text-align: center">Cel promo dni</th>
                                <th style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.gift.name}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Cel Agencji</td>
                                <td style="text-align: center">{{summary.plan.soldTarget}}</td>
                                <td style="text-align: center">{{summary.plan.secondTarget}}</td>
                                <td style="text-align: center">{{summary.plan.thirdTarget}}</td>
                                <td style="text-align: center">{{summary.plan.contactTarget}}</td>
                                <td style="text-align: center">{{summary.plan.promotionDays}}</td>
                                <td style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.plan}}</td>
                            </tr>
                            <tr>
                                <td>Rozdysponowano</td>
                                <td style="text-align: center">{{summary.distribute.soldTarget}}</td>
                                <td style="text-align: center">{{summary.distribute.secondTarget}}</td>
                                <td style="text-align: center">{{summary.distribute.thirdTarget}}</td>
                                <td style="text-align: center">{{summary.distribute.contactTarget}}</td>
                                <td style="text-align: center">{{summary.distribute.promotionDays}}</td>
                                <td style="text-align: center" v-for="(item, index) in summary.gifts" :key="index">{{item.distribute}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>





            <ml-form
                    :dataForm="formData"
                    :struct="formStruct"
                    @submittedForm="sendValue"
                    @canceledForm="cancelForm"
                    @changeValue="changeValue"
            >
                <template v-slot:afterInputs>
                    <div class="row mb-3">
                        <div class="col-sm-12"><label class="form-label col-sm-2 col-form-label">Gadżety</label></div>
                        <div class="col-sm-12">
                            <div class="table-responsive" >
                                <table class="mb-0 table table-striped edit-table">
                                    <thead>
                                    <tr>
                                        <th style="width: 300px">Gadżet</th>
                                        <th style="width: 150px">ilość</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in formData.planGifts" :key="index">
                                        <td>{{item.gift.name}}</td>
                                        <td>
                                            <pv-input-number
                                                    style="width: 100%"
                                                    mode="decimal"
                                                    :minFractionDigits="0"
                                                    :maxFractionDigits="0"
                                                    :locale="'pl'"
                                                    v-model="item.assigned"
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </ml-form>
        </div>
    </div>
</template>



<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Form from '../../../form/ml/form/From.vue'
    import {updateValue} from '../../../form/ml/form/class/InputsManager'
    import InputNumber from 'primevue/inputnumber';

    export default {

        components: {
            Breadcrumbs,
            'ml-form': Form,
            'pv-input-number': InputNumber,
        },



        data() {
            let list = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "Agencja", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Akcje", routerName: 'agencyActionList', params: {}, query: {}},
                {label: "Cele", routerName: 'agencyActionTargets', params: {id: this.$route.params.actionId}, query: {}},
                {label: "Edycja celu SV"}
            ]


            return {
                breadcrumpsList: list,
                visibleDialog: false,
                promotionData: null,
                formData: {
                    id: null,
                    name: "",
                    promotions: []
                },
                agencyList: [],
                formStruct: {fields: []},
                summary: {
                    plan: {},
                    distribute: {}
                },
                oldValue: null,
                originSummary: {
                    plan: {},
                    distribute: {},
                    gifts: []
                }
            }
        },

        watch: {
            formData: {
                handler() {
                    this.recalculateSummary();
                },
                deep: true
            }
        },


        methods: {
            changeValue(value) {
                updateValue(this.formData, value);
                this.recalculateSummary();
            },

            recalculateSummary() {
                this.summary.distribute.soldTarget = this.originSummary.distribute.soldTarget + (this.formData.soldTarget - this.oldValue.soldTarget);
                this.summary.distribute.secondTarget = this.originSummary.distribute.secondTarget + (this.formData.secondTarget - this.oldValue.secondTarget);
                this.summary.distribute.thirdTarget = this.originSummary.distribute.thirdTarget + (this.formData.thirdTarget - this.oldValue.thirdTarget);
                this.summary.distribute.contactTarget = this.originSummary.distribute.contactTarget + (this.formData.contactTarget - this.oldValue.contactTarget);
                this.summary.distribute.promotionDays = this.originSummary.distribute.promotionDays + (this.formData.promotionDays - this.oldValue.promotionDays);

                this.summary.gifts.forEach(g => {
                    let originGift = this.originSummary.gifts.find(or => or.gift.id == g.gift.id);
                    let oldGift = this.oldValue.planGifts.find(p => p.gift.id == g.gift.id);
                    let currentGift = this.formData.planGifts.find(p => p.gift.id == g.gift.id);
                    let assignCurrent = currentGift ? currentGift.assigned : 0;
                    let assignOld = oldGift ? oldGift.assigned : 0;
                    g.distribute = originGift.distribute + (assignCurrent - assignOld);




                });

            },


            async sendValue(data) {
                await this.$store.dispatch('agency/saveSvTarget', data);
                this.$router.replace({name: 'agencyActionTargets', params: {id: this.$route.params.actionId}});

            },
            cancelForm() {
                this.$router.replace({name: 'agencyActionTargets', params: {id: this.$route.params.actionId}});
            }

        },

        async beforeMount() {
            this.summary = await this.$store.dispatch('agency/summaryTarget', this.$route.params.actionId);
            this.originSummary.plan = {... this.summary.plan};
            this.originSummary.distribute = {... this.summary.distribute};
            this.summary.gifts.forEach(g => this.originSummary.gifts.push({...g}));

            this.formData = await this.$store.dispatch('agency/findSvTarget', this.$route.params.id);
            this.oldValue = {...this.formData};
            this.oldValue.planGifts = [];
            this.formData.planGifts.forEach(p => this.oldValue.planGifts.push({...p}));

            this.recalculateSummary();

            this.formData.action.promotions.forEach(p => {
                p.actionGifts.forEach(g => {
                    let planGift = this.formData.planGifts.find(pg => pg.gift.id == g.gift.id);
                    if (!planGift) {
                        this.formData.planGifts.push({
                            gift: g.gift,
                            assigned: 0
                        });
                    }
                })
            });

            this.formStruct = {
                rebuild: true,
                fields: [
                    {type: 'NUMBER', name: 'soldTarget', label: 'Cel sprzedażowy 1', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'secondTarget', label: 'Cel sprzedażowy 2', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'thirdTarget', label: 'Cel sprzedażowy 3', placeholder: 'Cel sprzedażowy',
                        tooltip: 'Wpisz cel sprzedażowy', info: '',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'contactTarget', label: 'Ilość nawiązaych kontaktów', placeholder: 'Ilość nawiązaych kontaktów',
                        tooltip: 'Wpisz ilość nawiązanych kontaktów do zrealizaowania', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false },
                    {type: 'NUMBER', name: 'promotionDays', label: 'Cel promo dni', placeholder: 'Cel promo dni',
                        tooltip: 'Wpisz cel promo dni', info: '',
                        required: true,  requiredMessage: 'Pole jest wymagane',
                        locale: 'pl-PL', maxFractionDigits: 0, grouping: false }

                ],
                cancelValue: 'Anuluj',
                submitValue: 'Zapisz'
            }


        }
    }

</script>